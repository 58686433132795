<form (ngSubmit)="submit()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <img src="assets/img/avatars/doc.png" class="avatar mr-5" />

    <!-- <h2 class="headline m-0 flex-auto">
      {{ "Code Sylvie : " + form.controls.numero_sylvie.value }}
    </h2> -->
    <h2 class="headline m-0 flex-auto">
      {{ defaults ? 'Correction sur le dossier' : 'Nouveau Dossier'}}
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>
  <mat-dialog-content class="flex flex-col">
    <div @fadeInUp class="card overflow-auto">
      <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true">
        <ng-template matStepperIcon="edit">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <mat-step [stepControl]="dossierFormGroup">
          <ng-template matStepLabel>Demande</ng-template>

          <form [formGroup]="dossierFormGroup">
            <!-- <h2 class="title m-0">Info du deposant</h2>

            <div class="px-6 py-4 flex flex-col">
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Nom du deposant</mat-label>
                  <input formControlName="nomDeposant" matInput />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Prenom du deposant</mat-label>
                  <input formControlName="prenomDeposant" matInput />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Telephone du deposant</mat-label>
                  <input formControlName="telephoneDeposant" matInput />
                </mat-form-field>
              </div>
            </div> -->

            <h2 class="title m-0">Identification de la demande</h2>
            <div class="subheading-1">
              Spécification sur la demande de service
            </div>

            <div class="px-6 py-4 flex flex-col">

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Code ifu</mat-label>
                  <input  formControlName="codeifu"
                    matInput placeholder="Saisir et verifier le code ifu&hellip;" />
                </mat-form-field>

                <div class="flex-aut0">
                  <button type="button" color="primary" mat-raised-button (click)="verifierCodeifu()">Verifier le Code IFU</button>
                </div>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4 mb-4">
                <div [ngClass]="{
                  'text-green-600 bg-green-600/10': etatNumeroDemandeur == 'ACTIF',
                  'text-red-600 bg-red-600/10': etatNumeroDemandeur != 'ACTIF' }" class="flex-auto mb-4 p-2">
                  <p >
                    NOM : {{ nomDemandeur! }}
                    <br>
                    ADRESSE : {{ adresseDemandeur! }}
                    <br>
                    RCCM : {{ rccmDemandeur! }}
                    <br>
                    DIRECTION : {{ directionDemandeur! }}
                    <br>
                    TELEPHONE : {{ telephoneDemandeur! }}
                    <!-- <br>
                    Email : {{ telephoneDemandeur }} -->
                    <br>
                    REGIME FISCALE : {{ regimeFiscaleDemandeur! }}
                    <br>
                    <!-- Etat Numero  : {{ etatNumeroDemandeur }}
                    <br>-->
                    ASSUJETI TVA  : {{ assujetiTvaDemandeur! }}
                    <br>
                    SIEGE : {{ siegeDemandeur! }}
                    <br>
                    <!-- enseigneCommercial : {{ enseigneCommercialDemandeur }}
                    <br> -->
                  </p>
                </div>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Nature operation</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.nature_operation"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_NATURE_OPERATION" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Code ifu</mat-label>
                  <input  formControlName="numeroIfu" matInput />
                </mat-form-field> -->

                <mat-form-field class="flex-auto">
                  <mat-label>Transitaire</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.transitaire"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of transitaires " [value]="param.id">
                      {{ param.nom }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <!-- <div class="flex flex-col sm:flex-row sm:gap-4">
                <p>Selectionner les documents demandés</p>
                <mat-checkbox class="checkbox">
                  RA
                </mat-checkbox>

                <mat-checkbox class="checkbox">
                  AI (DGSV)
                </mat-checkbox>

                <mat-checkbox class="checkbox">
                  Visa (ANRP)
                </mat-checkbox>

              </div> -->

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Reglement financier</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.reglement_financier"
                    placeholder="Selectionner&hellip;">
                    <mat-option [value]='1'>Oui</mat-option>
                    <mat-option [value]='0'>Non</mat-option>
                    <!-- <mat-option *ngFor="let param of parametreValeurs_REGLEMENT_FINANCIER" [value]="param.id">
                      {{param.libelle }}
                    </mat-option> -->
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Moyen de transport</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.mode_transport"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_MODE_TRANSPORT" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Mode d'expedition</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.mode_expedition"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_MODE_EXPEDITION  " [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>


              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Lieu d'embarquement</mat-label>
                  <input formControlName="lieu_embarquement" matInput />
                </mat-form-field>

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Lieu d'embarquement</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.lieu_embarquement" placeholder="Selectionner le pays">
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.libelle">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

                <mat-form-field class="flex-auto">
                  <mat-label>Lieu de debarquement</mat-label>
                  <input formControlName="lieu_debarquement" matInput />
                </mat-form-field>

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Lieu de débarquement</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.lieu_debarquement" placeholder="Selectionner le pays">
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.libelle">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Centre de retrait</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.centre_retrait"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_CENTRE_RETRAIT" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Bureau de dédouanement</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.bureau_dedoinement"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_BUREAU_DEDOUANEMENT" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Banque de domiciliation</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.banque_domiciliation"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of banque_domiciliations" [value]="param.id">
                      {{param.nomComplet }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Numero de compte</mat-label>
                  <input  formControlName="numero_compte" matInput />
                </mat-form-field>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Type d'assurance</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.type_assurance"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_TYPE_ASSURANCE  " [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Compagnie d'assurance</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.compagnie_assurance"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of compagnie_assurances " [value]="param.id">
                      {{param.nomComplet }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Type de police d'abonnement</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.type_police_abonnement"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_TYPE_POLICE_ABONNEMENT  " [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Numero de police d'abonnement</mat-label>
                  <input formControlName="numero_police_abonnement" matInput />
                </mat-form-field>

              </div>
            </div>

            <div class="actions flex items-center justify-end gap-2 mt-6">
              <button color="primary" mat-button matStepperPrevious type="button">
                Précédent
              </button>
              <button color="primary" mat-button [disabled]="dossierFormGroup.invalid"
                matStepperNext type="button">
                Suivant
              </button>
              <button (click)="saveDossier()" [disabled]="dossierFormGroup.invalid || !usagerIsValid"
                color="primary" mat-raised-button>
                Enregistrer
              </button>
            </div>
          </form>

        </mat-step>

        <mat-step [stepControl]="factureFormGroup">
          <form [formGroup]="factureFormGroup">
            <ng-template matStepLabel>Facture</ng-template>

            <h2 class="title m-0">Identification du vendeur</h2>
            <div class="px-6 py-4 flex flex-col">
              <div class="flex flex-col sm:flex-row sm:gap-3">
                <mat-form-field class="flex-auto">
                  <mat-label>Nom du vendeur</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.nom" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>E-mail du vendeur</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.email" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Adresse</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.adresse" matInput class="example-right-align" />
                </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Pays</mat-label>
                  <mat-select [formControl]="vendeurFormGroup.controls.pays" placeholder="Selectionner le pays">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Lieu d'embarquement</mat-label>
                  <mat-select [formControl]="dossierFormGroup.controls.lieu_embarquement" placeholder="Selectionner le pays">
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.libelle">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->

                <mat-form-field class="flex-auto">
                  <mat-label>Ville</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.ville" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Téléphone</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.telephone" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Fax</mat-label>
                  <input [formControl]="vendeurFormGroup.controls.fax" matInput class="example-right-align" />
                </mat-form-field>
              </div>
            </div>

            <h2 class="title m-0">Spécification sur la facture</h2>
            <div class="px-6 py-4 flex flex-col">
              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Type Facture</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.type_facture"
                    placeholder="Selectionner&hellip;">
                    <mat-option *ngFor="let param of parametreValeurs_TYPE_FACTURE" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>Numero de facture</mat-label>
                  <input [formControl]="factureFormGroup.controls.numero_facture" matInput class="example-right-align" />
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-5">

                <mat-form-field class="flex-auto">
                  <mat-label>Devise</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.devise"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of devises" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Taux de change</mat-label>
                  <input type="number" formControlName="taux_change" matInput class="example-right-align disabled"/>
                  <!-- <input type="number" formControlName="montant_total_devise" matInput class="example-right-align disabled"/> -->
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Montant total Devise</mat-label>
                  <input type="number" formControlName="montant_total_devise" matInput class="example-right-align" />
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>Montant total CFA</mat-label>
                  <input type="number" formControlName="montant_total" matInput class="example-right-align" />
                </mat-form-field>


                <mat-form-field class="flex-auto">
                  <mat-label>Valeur FOB CFA</mat-label>
                  <input type="number" formControlName="valeur_fob_cfa" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Mode de règlement</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.mode_reglement"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_MODE_REGLEMENT" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Incoterme Facture</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.incoterme_facture"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let incoterme of incotermeFactures" [value]="incoterme.id">
                      {{incoterme.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Incoterme Article</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.incoterme_produit"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let incoterme of incotermeProduits" [value]="incoterme.id">
                      {{incoterme.libelle }}
                    </mat-option>
                  </mat-select>

                  <!-- <input [formControl]="incotermFormGroup.controls.incoterme_produit" [matAutocomplete]="autoincoprod"
                    matInput placeholder="Selectionner&hellip;" />
                  <mat-autocomplete #autoincoprod="matAutocomplete"
                  (optionSelected)='onSelectedIncotermeArticle($event.option.value)'>
                    <mat-option *ngFor="let incoterme of filteredIncotermeProduit$ | async" [value]="incoterme.libelle">
                      {{ incoterme.libelle }}
                    </mat-option>
                  </mat-autocomplete> -->
                </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Remise sur la facture</mat-label>
                  <input type="number" formControlName="remise_sur_facture" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Poid net total</mat-label>
                  <input type="number" formControlName="poids_net_total" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Poid brut total</mat-label>
                  <input type="number" formControlName="poids_brut_total" matInput class="example-right-align" />
                </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Pays d'Origine</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.pays_origine"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Pays de destination</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.pays_destination"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Pays de provenance</mat-label>
                  <mat-select [formControl]="factureFormGroup.controls.pays_provenence"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-5">
                <mat-form-field class="flex-auto">
                  <mat-label>Valeur sortie usine</mat-label>
                  <input type="number" formControlName="valeur_sortie_usine" matInput class="example-right-align" id="valeur_sortie_usine" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Mise à FCA</mat-label>
                  <input type="number" formControlName="mise_a_fca" matInput class="example-right-align" id="mise_a_fca" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>FCA Déclaré</mat-label>
                  <input type="number" formControlName="fca_declare" matInput class="example-right-align" id="fca_declare" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Mise à FOB</mat-label>
                  <input type="number" formControlName="mise_a_fob" matInput class="example-right-align" id="mise_a_fob" />
                </mat-form-field>
              </div>
              <div class="flex flex-col sm:flex-row sm:gap-5">
                <mat-form-field class="flex-auto">
                  <mat-label>Montant FOB</mat-label>
                  <input type="number" formControlName="montant_fob" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Montant fret</mat-label>
                  <input type="number" formControlName="montant_fret" matInput class="example-right-align" id="montant_fret" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Montant assurance</mat-label>
                  <input type="number" formControlName="montant_assurance" matInput class="example-right-align" id="assurance" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Valeur chargé sur</mat-label>
                  <input type="number" formControlName="valeur_charge_sur" matInput class="example-right-align" id="valeur_charge_sur" />
                </mat-form-field>
                <mat-form-field class="flex-auto">
                  <mat-label>Frais déchargement</mat-label>
                  <input type="number" formControlName="frais_dechargement" matInput class="example-right-align" id="frais_dechargement" />
                </mat-form-field>
              </div>


              <div class="flex flex-col sm:flex-row sm:gap-4">
                <mat-form-field class="flex-auto">
                  <mat-label>Autre montant</mat-label>
                  <input type="number" formControlName="montant_autres" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Nature Autre Couts</mat-label>
                  <input formControlName="nature_autre_couts" matInput />
                </mat-form-field>
              </div>

            </div>

            <div class="actions flex items-center justify-end gap-2 mt-6">
              <button color="primary" mat-button matStepperPrevious type="button">
                Précédent
              </button>
              <button color="primary" mat-button [disabled]="factureFormGroup.invalid"
                matStepperNext type="button">
                Suivant
              </button>
              <button (click)="saveFacture()" [disabled]="factureFormGroup.invalid"
                color="primary" mat-raised-button>
                Enregistrer
              </button>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="produitFormGroup">
          <form [formGroup]="produitFormGroup">
            <ng-template matStepLabel>Produits</ng-template>
            <h2 class="title m-0">Identification des produits</h2>
            <!-- <div class="subheading-1">
              Spécification sur les produits de la facture
            </div> -->

            <div class="px-6 py-4 flex flex-col">
              <div class="flex flex-col sm:flex-row sm:gap-4">

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Code tarif du produit</mat-label>
                  <input [formControl]="codeProduitFormGroup.controls.code" [matAutocomplete]="auto" matInput
                    placeholder="Selectionner&hellip;" />
                  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)='onSelectedCodeProduit($event.option.value)'>
                    <mat-option *ngFor="let param of filteredCodeProsuit$ | async" [value]="param.code">
                      {{ param.code }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field> -->

                <mat-form-field class="flex-auto">
                  <mat-label>Rechercher le code tarif du produit</mat-label>
                  <input [formControl]="codeProduitFormGroup.controls.code" matInput
                    placeholder="Selectionner&hellip;" />
                </mat-form-field>

                <!-- <mat-form-field class="flex-auto">
                  <mat-label>Nom scientifique du produit</mat-label>
                  <input [formControl]="codeProduitFormGroup.controls.libelle" [matAutocomplete]="auto3" matInput
                    placeholder="Selectionner&hellip;" />
                  <mat-autocomplete #auto3="matAutocomplete"
                    (optionSelected)='onSelectedCodeProduitLibelle($event.option.value)'>
                    <ng-container *ngFor="let param of filteredCodeProsuitLibelle$ | async" >
                    <mat-option [value]="param.libelle">
                      {{ param.libelle }}
                    </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field> -->

                <div class="flex-auto">
                  <button mat-fab extended color="primary"
                    (click)="searcheCodeTarif()">
                    <mat-icon svgIcon="mat:search"></mat-icon>
                    Rechercher un produit
                  </button>
                  <!-- <button class="bg-gray-100 text-red-600" mat-raised-button
                    >Rechercher un produit</button> -->
                </div>

              </div>

              <div *ngIf="isValideProduit" class="flex flex-row mb-4 text-green-600 bg-green-600/10 p-4 text-base">
                <p>{{ codeProduitFormGroup.controls.libelle.value! }}</p>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Libellé du produit</mat-label>
                  <input type="text" formControlName="libelle" matInput />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Quantite</mat-label>
                  <input type="number" formControlName="quantite" matInput />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Unite de mesure</mat-label>
                  <mat-select [formControl]="produitFormGroup.controls.unite_mesure"
                    placeholder="Selectionner&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_UNITE_MESURE" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="flex flex-col sm:flex-row sm:gap-4">

                <mat-form-field class="flex-auto">
                  <mat-label>Poids brut</mat-label>
                  <input type="number" formControlName="poids_brut" matInput />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Prix unitaire</mat-label>
                  <input type="number" formControlName="prix_unitaire" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Valeur totale</mat-label>
                  <input type="number" formControlName="valeur_totale" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Valeur fob</mat-label>
                  <input type="number" formControlName="valeur_fob" matInput class="example-right-align" />
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Contre valeur</mat-label>
                  <input type="number" formControlName="contre_valeur" matInput class="example-right-align" />
                </mat-form-field>

              </div>

              <div class="flex flex-col sm:flex-row sm:gap-3">
                <mat-form-field class="flex-auto">
                  <mat-label>Pays d'Origine</mat-label>
                  <mat-select [formControl]="produitFormGroup.controls.pays_origine"
                    placeholder="Selectionner...&hellip;">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Pays de provenance</mat-label>
                  <mat-select [formControl]="produitFormGroup.controls.pays_provenence" placeholder="Selectionner...">
                    <!-- <mat-option value="">Selectionner...</mat-option> -->
                    <mat-option *ngFor="let param of parametreValeurs_PAYS" [value]="param.id">
                      {{param.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="flex-auto">
                  <mat-label>Poste d'Entre</mat-label>
                  <input type="text" formControlName="posteEntree" matInput class="example-right-align" />
                </mat-form-field>

              </div>

              <button (click)="addProduit()" [disabled]="produitFormGroup.invalid || codeProduitFormGroup.invalid" mat-raised-button color="primary">Ajouter</button>
            </div>

            <div class="invoice">
              <div class="title">
                <h2>Listes des produits</h2>
              </div>
              <table class="table-4col w-full mt-4">
                <thead>
                  <tr>
                    <td class="body-2 text-secondary">PRODUIT</td>
                    <td class="body-2 text-secondary">QUANTITY</td>
                    <td class="body-2 text-secondary">UNITE DE MESURE</td>
                    <td class="body-2 text-secondary">PRIX UNIT</td>
                    <td class="body-2 text-secondary text-right">TOTAL</td>
                    <td class="body-2 text-secondary text-right"></td>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let produit of produitsList">
                    <td class="py-6 border-b">{{ produit.libelle }}</td>
                    <td class="py-6 border-b">{{ produit.quantite }}</td>
                    <td class="py-6 border-b">{{ getParametreLabelle(produit.unite_mesure, enumParam.UNITE_MESURE) }}</td>
                    <td class="py-6 border-b">{{ produit.prix_unitaire }}</td>
                    <td class="py-6 border-b font-medium text-right">{{ produit.valeur_totale }}</td>
                    <td class="border-b font-medium text-right">
                      <div
                        class="bg-base rounded-tr rounded-br border-l-3 p-1 mt-3 flex flex-col sm:flex-row items-center gap-2">
                        <button color="primary" mat-icon-button type="button" (click)="onEditeProduit(produit)">
                          <mat-icon svgIcon="mat:edit"></mat-icon>
                        </button>

                        <button color="accent" mat-icon-button type="button" (click)="onDeleteProduit(produit.id)">
                          <mat-icon svgIcon="mat:delete"></mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>

            </div>

            <div class="actions flex items-center justify-end gap-2 mt-6">
              <button color="primary" mat-button matStepperPrevious type="button">
                Précédent
              </button>
              <button (click)="verification(produitsList)" [disabled]="produitsList.length == 0" color="primary"
                mat-raised-button matStepperNext>
                Suivant
              </button>
            </div>

          </form>
        </mat-step>

        <mat-step [stepControl]="confirmFormGroup">
          <form [formGroup]="confirmFormGroup">
            <ng-template matStepLabel>Soumission</ng-template>

            <div class="grid grid-cols-2">
              <div>
                <img class="h-40" src="assets/img/avatars/doc.png" />
              </div>

              <div class="text-right body-1 flex flex-col items-end">
                <!-- <h1 class="display-2 m-0">Ouedraogo Salif</h1> -->

                <table class="mt-6">
                  <tbody>
                    <tr>
                      <td class="text-secondary body-2">NUMERO SYLVIE</td>
                      <td class="text-left pl-4">{{ dossierFormGroup.controls.numero_sylvie.value }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary body-2">IFU DEMANDEUR</td>
                      <td class="text-left pl-4">{{ dossierFormGroup.controls.codeifu.value }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary body-2">DATE DEMANDE</td>
                      <td class="text-left pl-4">{{ dossierFormGroup.controls.date_arrivee_prevu.value | date}}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary body-2">NATURE DEMANDE :</td>
                      <td class="text-left pl-4">{{
                        getParametreLabelle(dossierFormGroup.controls.nature_operation.value!, enumParam.NATURE_OPERATION) }}</td>
                    </tr>
                    <tr>
                      <td class="text-secondary body-2">MODE DE TRANSPORT :</td>
                      <td class="text-left pl-4">{{ getParametreLabelle(dossierFormGroup.controls.mode_transport.value!, enumParam.MODE_TRANSPORT)
                        }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

            <div class="invoice">
              <div class="title">
                <h2>Listes des produits</h2>
              </div>
              <table class="table-4col w-full mt-4">
                <thead>
                  <tr>
                    <td class="body-2 text-secondary">PRODUIT</td>
                    <td class="body-2 text-secondary">QUANTITY</td>
                    <td class="body-2 text-secondary">UNITE DE MESURE</td>
                    <td class="body-2 text-secondary">PRIX UNIT</td>
                    <td class="body-2 text-secondary text-right">TOTAL</td>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let produit of produitsList">
                    <td class="py-6 border-b">{{ produit.libelle }}</td>
                    <td class="py-6 border-b">{{ produit.quantite }}</td>
                    <td class="py-6 border-b">{{ getParametreLabelle(produit.unite_mesure, enumParam.UNITE_MESURE) }}</td>
                    <td class="py-6 border-b">{{ produit.prix_unitaire }}</td>
                    <td class="py-6 border-b font-medium text-right">{{ produit.valeur_totale }}</td>
                  </tr>

                </tbody>
              </table>

            </div>


            <div class="subheading-1">
              Pour finaliser votre inscription, acceptez nos conditions d'utilisation et cliquez sur
              "Enregistrer"
            </div>

            <div class="mt-4 flex flex-col gap-2">
              <mat-checkbox class="checkbox" formControlName="terms">
                J'accepte les conditions d'utilisation.
              </mat-checkbox>
            </div>

            <div class="actions flex items-center justify-end gap-2">
              <button color="primary" mat-button matStepperPrevious type="button">
                Précédent
              </button>
              <button (click)="submit()" [disabled]="confirmFormGroup.invalid || !fobIsValide" color="primary" mat-raised-button
                matStepperNext>
                Enregistrer
              </button>
                <!--
              <button (click)="soumettreDossier()" [disabled]="confirmFormGroup.invalid" color="primary" mat-raised-button
                matStepperNext>
                Soumettre au traitement
              </button>

              <button (click)="soumettreDossier()" [disabled]="confirmFormGroup.invalid" color="primary" mat-raised-button
                matStepperNext>
                document à chercher
              </button>
                -->

            </div>
          </form>
        </mat-step>

      </mat-horizontal-stepper>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Fermer</button>
  </mat-dialog-actions>
</form>
