import { VexRoute } from '@vex/interfaces/vex-route.interface';
import { AbonneGuard } from '../../core/auth/guards/abonne.guard';
import { EnumDossierStatut } from '../../model/dossier.model';

export const abonneRoutes: VexRoute =
  {
    /**!SECTION ROUTING POUR LES UTILISATEUR DE TYPE ABONNE */
    path: 'abonne',
    canActivate: [AbonneGuard],
    children: [
      {
        path: 'gestion-utilisateur',
        loadComponent: () =>
          import(
            '../../pages/contacts/contacts-table/utilisateur-table.component'
          ).then((m) => m.UtilisateurTableComponent),
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true
        }
      },

      {
        path: 'gestion-structure',
        loadComponent: () =>
          import('../../pages/structure/structure-table.component').then(
            (m) => m.StructureTableComponent
          ),
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'gestion-transitaire',
        loadComponent: () =>
          import('../../pages/transitaire/transitaire-table.component').then(
            (m) => m.TransitaireTableComponent
          ),
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'gestion-devise',
        loadComponent: () =>
          import('../../pages/devise/devise-table.component').then(
            (m) => m.DeviseTableComponent
          ),
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'gestion-dossier',

        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.ALL
        }
      },
      {
        path: 'depot',
        loadComponent: () =>
          import('../../pages/depot-retrait/depot-retrait.component').then(
            (m) => m.DepotRetraitComponent
          ),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'affectation',
        loadComponent: () =>
          import('../../pages/affectation/affectation.component').then(
            (m) => m.AffectationComponent
          ),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'retrait',

        loadComponent: () =>
          import(
            '../../pages/retrait/retrait-dossier/retrait-dossier/retrait-dossier.component'
          ).then((m) => m.RetraitDossierComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'retrait-dossiers',
        loadComponent: () =>
          import(
            '../../pages/retrait/retrait-dossier/retrait-dossier/retrait-dossier.component'
          ).then((m) => m.RetraitDossierComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'pdf',
        loadComponent: () =>
          import('../pdf-viewer/pdf-viewer.component').then(
            (m) => m.PdfViewerComponent
          ),
        data: {
          toolbarShadowEnabled: false
        }
      },

      {
        path: 'infos-complementaire',

        loadComponent: () =>
          import(
            '../../pages/dossier-info-complementaire/info-complementaire-form/info-complementaire-form.component'
          ).then((m) => m.InfoComplementaireFormComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },

      // facturation non abonne
      {
        path: 'facturation',

        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-non-abonne/factures/facture.component'
          ).then((m) => m.FactureComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'facture-en-attente',


        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-non-abonne/facture-en-attente/facture-en-attente.component'
          ).then((m) => m.FactureEnAttenteComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'facture-paye',

        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-non-abonne/facture-paye/facture-paye.component'
          ).then((m) => m.FacturePayeComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },

      // facturation abonne
      {
        path: 'facturation-abonne',
        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-abonne/factures/facture-abonne.component'
          ).then((m) => m.FactureAbonneComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'facture-abonne-en-attente',

        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-abonne/facture-en-attente/facture-facture-abonne-en-attente.component'
          ).then((m) => m.FactureAbonneEnAttenteComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'facture-abonne-paye',


        loadComponent: () =>
          import(
            '../../pages/facturation/facturation-abonne/facture-paye/facture-abonne-paye.component'
          ).then((m) => m.FactureAbonnePayeComponent),
        data: {
          toolbarShadowEnabled: false
        }
      },

      // gestion des abonnes
      {
        path: 'gestion-abonne',
        loadComponent: () =>
          import('../../pages/abonne/gestion-abonne.component').then(
            (m) => m.GestionAbonneComponent
          ),
        data: {
          toolbarShadowEnabled: false
        }
      },
      {
        path: 'gestion-dossier-valide',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
          data: {
            toolbarShadowEnabled: false,
            dossierListStatut: EnumDossierStatut.VALIDE
          }
      },
      {
        path: 'gestion-dossier-enregistre',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
          data: {
            toolbarShadowEnabled: false,
            dossierListStatut: EnumDossierStatut.ENREGISTRE
          }
      },
      {
        path: 'gestion-dossier-rejete',

        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.REJET
        }
      },
      {
        path: 'gestion-dossier-annule',

        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.ANNULLE
        }
      },
      {
        path: 'gestion-dossier-en-attente',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.EN_ATTENTE
        }
      },
      {
        path: 'gestion-dossier-modification',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.MODIFICATION
        }
      },
      {
        path: 'gestion-dossier-en-cours',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.EN_COURS
        }
      },
      {
        path: 'gestion-dossier-en-traitement',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.EN_TRAITEMENT
        }
      },
      {
        path: 'gestion-dossier-termine',
        loadComponent: () =>
          import(
            '../../pages/dossiers/gestion-dossiers/gestion-dossiers.component'
          ).then((m) => m.GestionDossiersComponent),
        data: {
          toolbarShadowEnabled: false,
          dossierListStatut: EnumDossierStatut.TERMINE
        }
      },
      {
        path: 'parametre',
        loadComponent: () =>
          import(
            '../../pages/parametre/parametre-table/parametre-table.component'
          ).then((m) => m.ParametreTableComponent),
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true
        }
      }
    ]
  };
