import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    catchError,
    of,
    switchMap
} from 'rxjs';
import { API_ROOT } from "src/environments/environment";
import { ResponseModel } from "../model/response.model";
import { Usager } from "../model/usager.model";

@Injectable({
  providedIn: 'root'
})
export class UsagerService {
  getUsers() {
    throw new Error('Method not implemented.');
  }

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  getUsagers(): Observable<any[]> {
    return this._httpClient
      .get<ResponseModel<Usager[]>>(`${API_ROOT.usager_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const roles: Usager[] = response?.data || [];
          return of(roles);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  verifierUsager(ifu: string): Observable<any> {
    return this._httpClient
      .post<ResponseModel<any>>(`${API_ROOT.usager_check}`,
      {
        "IFU": ifu
      } , { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const usage = response?.data! || null;
          return of(usage);
        }),
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      );
  }

  /**
     * Get user
     */
  getUsager(codeifu: string): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Usager[]>>(`${API_ROOT.usager_by_ifu}/${codeifu}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const roles: Usager[] = response?.data || [];
          return of(roles);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

}
