import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger80ms } from '@vex/animations/stagger.animation';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

@Component({
  selector: 'vex-ifu-dialog',
  templateUrl: './ifu-dialog.component.html',
  styleUrls: ['./ifu-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    ReactiveFormsModule,
    NgFor,
    ScrollingModule
  ]

})
export class IfuDialogComponent {

  data : any;

  nomDemandeur: string = this.defaults?.raisonSociale! ?? '';
  adresseDemandeur: string = this.defaults?.adresse! ?? '';
  rccmDemandeur: string = this.defaults?.rccm! ?? '';
  directionDemandeur: string = this.defaults?.direction! ?? '';
  telephoneDemandeur: string = this.defaults?.telephone! ?? '';
  regimeFiscaleDemandeur: string = this.defaults?.regimeFiscale! ?? '';
  etatNumeroDemandeur: string = this.defaults?.etatNumero! ?? '';
  assujetiTvaDemandeur: string = this.defaults?.assujetiTva! ?? '';
  siegeDemandeur: string = this.defaults?.siege! ?? '';
  enseigneCommercialDemandeur: string = this.defaults?.enseigneCommercial! ?? '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any | undefined,
    private dialogRef: MatDialogRef<IfuDialogComponent>){
      console.log(defaults);
      this.data = this.defaults!;

      this.nomDemandeur = this.data.raisonSociale!;
      this.adresseDemandeur = this.data.adresse!;
      this.rccmDemandeur = this.data.rccm!;
      this.directionDemandeur = this.data.direction!;
      this.telephoneDemandeur = this.data.telephone!;
      this.siegeDemandeur = this.data.siege!;
      this.regimeFiscaleDemandeur = this.data.regimeFiscale!;
      this.etatNumeroDemandeur = this.data.etatNumero!;
      this.assujetiTvaDemandeur = this.data.assujetiTva!;
      this.enseigneCommercialDemandeur = this.data.enseigneCommercial!;
    }

  OnInit(){
    console.log("ddddddddddddddddddddddd")
  }

}
