
export class Devise {
  public id?: number;
  public code?: string;
  public libelle?: string;
  public tauxChange?: number;

  constructor(customer: Devise) {
    this.code = customer.code;
    this.libelle = customer.libelle;
    this.tauxChange = customer.tauxChange;

  }
}


