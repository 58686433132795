import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { Devise } from '../model/devise.model';

@Injectable({
  providedIn: 'root'
})
export class DeviseService {

  // Private
  private _devise: ReplaySubject<Devise> = new ReplaySubject<Devise>(1);
  private _devises: ReplaySubject<Devise[]> = new ReplaySubject();

  private _url = API_ROOT.devise_list;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for devise
   */
  get devise$(): Observable<Devise> {
    return this._devise.asObservable();
  }

  set devise(devise: Devise) {
    this._devise.next(devise);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
     * Get users
     */
  getDevises(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Devise[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const devises: Devise[] = response?.data || [];
          this._devises.next(devises);
          return of(devises);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }
   /**
     * Create devise
     */
   create(devise: any): Observable<any> {

    return this._devises.pipe(
      take(1),
      switchMap(devises => this._httpClient.post<ResponseModel<Devise>>(`${API_ROOT.devise_create}`, devise).pipe(
        switchMap((response) => {
          if (response.success) {
            const newDevise: Devise = new Devise(response.data!);
            // Update the devises with the new devise
            this._devises.next([newDevise, ...devises!]);
            this._devise.next(newDevise);

            // Return the new devise
            return of(newDevise);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

   /**
     * Create devise
     */
   update(devise: any): Observable<any> {

    return this._devises.pipe(
      take(1),
      switchMap(devises => this._httpClient.post<ResponseModel<Devise>>(`${API_ROOT.devise_update}`, devise).pipe(
        switchMap((response) => {
          if (response.success) {
            const newDevise: Devise = new Devise(response.data!);

            const index = devises?.findIndex(p => p.id == newDevise.id)!;

            devises[index] = newDevise;

            // Update the devises with the new devise
            this._devises.next([newDevise, ...devises!]);
            this._devise.next(newDevise);

            // Return the new devise
            return of(newDevise);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }
 
}
