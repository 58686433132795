import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError, map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { API_ROOT } from 'src/environments/environment';
import { ResponseModel } from '../model/response.model';
import { AuthService } from '../core/auth/auth.service';
import { Dossier, EnumAct, EnumDossierStatut, EnumFilsType } from '../model/dossier.model';
import { StructurePartenaire } from '../model/structurePartenaire.model';
import { TraitementDossierPoles } from '../model/traitementDossierPoles.model';
import { log } from 'console';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerComponent } from '../shared/pdf-viewer/pdf-viewer.component';
import { HistoriqueTraitementComponent } from '../layouts/components/historique-traitement/historique-traitement.component';

@Injectable({
  providedIn: 'root',
})
export class DossierService {
  private _dossier: ReplaySubject<Dossier> = new ReplaySubject<Dossier>(1);
  private _dossiers: ReplaySubject<Dossier[]> = new ReplaySubject();

  private _url = API_ROOT.get_dossier_by_structure;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for dossier management
   *
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  get dossier$(): Observable<Dossier> {
    return this._dossier.asObservable();
  }

  set dossier(value: Dossier) {
    // Store the value
    this._dossier.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Get dossiers
     */
  getDossiers(structure: StructurePartenaire, statut: string): Observable<any[]> {
    let body = {};
    if (structure.raisonSociale != "SOGESY" && statut == "EN_COURS" ) {
      statut='EN_ATTENTE_VALIDATION_POLE'
    }

    this._url = API_ROOT.get_dossier_by_structure_with_pole;

    body = {
      "structure_id": structure.id,
      "statut": statut,
      "statusPole": statut
    }

    if(statut =='EN_ATTENTE_D_ATTRIBUTION'){
      this._url = API_ROOT.dossiers_list_statut;

      body = {
        "statut": statut
      }

    }

    return this._httpClient
      .post<ResponseModel<Dossier[]>>(`${this._url}`, body, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {

          const dossiers1: any[] = response?.data || [];

          const dossiers: Dossier[]=[];

          for (let index = 0; index < dossiers1.length; index++) {
            const customer = dossiers1[index];

            dossiers.push(
              {
                id: customer?.dossier?.id! ,
                numero_sylvie: customer?.dossier?.numeroSylvie! ,
                numeroFacture: customer?.dossier?.numeroFacture! ,
                nature_operation: customer?.dossier?.natureOperation! ,
                nature_operation_libelle: customer?.dossier?.natureOperation?.libelle! ?? '--',
                codeifu: customer?.dossier?.usager.numeroIfu! ,
                transitaire: customer?.dossier?.transitaire! ,
                reglement_financier: customer?.dossier?.reglementFinancier! ,
                mode_transport: customer?.dossier?.modeTransport! ,
                mode_expedition: customer?.dossier?.modeExpedition! ,
                lieu_embarquement: customer?.dossier?.lieuEmbarquement! ,
                lieu_debarquement: customer?.dossier?.lieuDebarquement! ,
                centre_retrait: customer?.dossier?.centreRetrait! ,
                bureau_dedoinement: customer?.dossier?.bureauDedoinement! ,
                banque_domiciliation: customer?.dossier?.banqueDomiciliation! ,
                date_arrivee_prevu: customer?.dossier?.dateArriveePrevue! ,
                type_assurance: customer?.dossier?.typeAssurance! ,
                compagnie_assurance: customer?.dossier?.compagnieAssurance! ,
                type_police_abonnement: customer?.dossier?.typePoliceAbonnement! ,
                numero_police_abonnement: customer?.dossier?.numeroPoliceAbonnement! ,
                utilisateur: customer?.dossier?.utilisateur! ,
                statut: customer?.dossier?.statut! ,

                devise: customer?.dossier?.devise! ,
                montant_total: customer?.dossier?.montantTotal! ,
                valeur_fob_cfa: customer?.dossier?.valeurFobCfa! ,
                mode_reglement: customer?.dossier?.modeReglement! ,
                pays_origine: customer?.dossier?.paysOrigine! ,
                pays_destination: customer?.dossier?.paysDestination! ,
                pays_provenence: customer?.dossier?.paysProvenence! ,
                valeur_sortie_usine: customer?.dossier?.valeurSortieUsine! ,
                montant_fob: customer?.dossier?.montantFob! ,
                montant_fret: customer?.dossier?.montantFret! ,
                montant_assurance: customer?.dossier?.montantAssurance! ,
                montant_autres: customer?.dossier?.montantAutres! ,
                nature_autre_couts: customer?.dossier?.natureAutreCouts! ,
                remise_sur_facture: customer?.dossier?.remiseSurFacture! ,
                poids_net_total: customer?.dossier?.poidsNetTotal! ,
                poids_brut_total: customer?.dossier?.poidsBrutTotal! ,

                numero_compte: customer?.dossier?.numeroCompte! ,

                montant_total_devise: customer?.dossier?.montantTotalDevise! ,
                type_facture: customer?.dossier?.typeFacture! ,
                nomDeposant: customer?.dossier?.nomDeposant! ,
                prenomDeposant: customer?.dossier?.prenomDeposant! ,
                telephoneDeposant: customer?.dossier?.telephoneDeposant! ,
                dateDepot: customer?.dossier?.dateDepot! ,
                dateSoumission:customer?.dossier?.dateSoumission! ,
                traitementDossierPolesx:customer?.traitementDossierPoles!,
                raisonSocial:customer?.dossier?.usager?.raisonSociale!,
                mode_transport_libelle:customer?.dossier?.modeTransport?.libelle! ?? "--",
                statutPole:customer?.traitementDossierPoles?.responsePole!,
                numeroDomiciliation: customer?.numeroDomiciliation!

              }
            );

          }

          console.log('dossier +++',dossiers)

          // this.formatDossier(dossiers1);
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }


   formatDossier(dossierArr: any[]): any []{

    const refactorDoc:any[]=[];
      dossierArr.forEach(customer => {
        refactorDoc.push(   {
          id: customer.dossier.id,
          numero_sylvie: customer.dossier.numero_sylvie ,
          numeroFacture: customer.dossier.numeroFacture ,
          nature_operation: customer.dossier.nature_operation ,
          codeifu: customer.dossier.codeifu ,
          transitaire: customer.dossier.transitaire ,
          reglement_financier: customer.dossier.reglement_financier ,
          mode_transport: customer.dossier.mode_transport ,
          mode_expedition: customer.dossier.mode_expedition ,
          lieu_embarquement: customer.dossier.lieu_embarquement ,
          lieu_debarquement: customer.dossier.lieu_debarquement ,
          centre_retrait: customer.dossier.centre_retrait ,
          bureau_dedoinement: customer.dossier.bureau_dedoinement ,
          banque_domiciliation: customer.dossier.banque_domiciliation ,
          date_arrivee_prevu: customer.dossier.date_arrivee_prevu ,
          type_assurance: customer.dossier.type_assurance ,
          compagnie_assurance: customer.dossier.compagnie_assurance ,
          type_police_abonnement: customer.dossier.type_police_abonnement ,
          numero_police_abonnement: customer.dossier.numero_police_abonnement ,
          utilisateur: customer.dossier.utilisateur ,
          statut: customer.dossier.statut ,

          devise: customer.dossier.devise ,
          montant_total: customer.dossier.montant_total ,
          valeur_fob_cfa: customer.dossier.valeur_fob_cfa ,
          mode_reglement: customer.dossier.mode_reglement ,
          pays_origine: customer.dossier.pays_origine ,
          pays_destination: customer.dossier.pays_destination ,
          pays_provenence: customer.dossier.pays_provenence ,
          valeur_sortie_usine: customer.dossier.valeur_sortie_usine ,
          montant_fob: customer.dossier.montant_fob ,
          montant_fret: customer.dossier.montant_fret ,
          montant_assurance: customer.dossier.montant_assurance ,
          montant_autres: customer.dossier.montant_autres ,
          nature_autre_couts: customer.dossier.nature_autre_couts ,
          remise_sur_facture: customer.dossier.remise_sur_facture ,
          poids_net_total: customer.dossier.poids_net_total ,
          poids_brut_total: customer.dossier.poids_brut_total ,

          numero_compte: customer.dossier.numero_compte ,

          montant_total_devise: customer.dossier.montant_total_devise ,
          type_facture: customer.dossier.type_facture ,
          nomDeposant: customer.dossier.nomDeposant ,
          prenomDeposant: customer.dossier.prenomDeposant ,
          telephoneDeposant: customer.dossier.telephoneDeposant ,
          dateDepot: customer.dossier.dateDepot ,
          dateSoumission:customer.dossier.dateSoumission ,
          traitementDossierPolesx:customer.dossier.traitementDossierPoles,
          numeroDomiciliation: customer?.numeroDomiciliation!

     })
      });

      console.log("Le refactorDoc final",refactorDoc);

  return refactorDoc;
}

/**
 * Get dossiers en attente d'attribution
 */
getDossiersAttribution(statut: string): Observable<any> {

  this._url = API_ROOT.dossiers_list_statut;

  let body = {
    "statut": statut
  }

  return this._httpClient
    .post<ResponseModel<Dossier[]>>(`${this._url}`, body, { 'headers': this.headers })
    .pipe(
      switchMap((response) => {
        const dossiers: any[] = response?.data! || [];
        this._dossiers.next(dossiers);
        return of(dossiers);
      }),
      catchError((err) => {
        console.log(err);
        return of([]);
      })
    );
}

  getUserDossiers(status: EnumDossierStatut): Observable<any> {

    return this._httpClient
      .get<ResponseModel<Dossier[]>>(`${API_ROOT.get_dossier_agent}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          let dossiers: any[] = [];
          let dossiers1: any[] = [];
          if(status == EnumDossierStatut.ALL){
            dossiers1 = response?.data || [];
          }else{
            dossiers1 = response?.data?.filter(d => d.statut == status) || [];
          }

          // dossiers1.forEach(customer => {
          for (let index = 0; index < dossiers1.length; index++) {
            const customer = dossiers1[index];
            console.log("customer : ",customer);
            dossiers.push(
              {
                id: customer?.id! ,
                numero_sylvie: customer?.numeroSylvie! ,
                numeroFacture: customer?.numeroFacture! ,
                nature_operation: customer?.natureOperation! ,
                nature_operation_libelle: customer?.natureOperation?.libelle! ?? '--',
                codeifu: customer?.usager.numeroIfu! ,
                transitaire: customer?.transitaire! ,
                reglement_financier: customer?.reglementFinancier! ,
                mode_transport: customer?.modeTransport! ,
                mode_expedition: customer?.modeExpedition! ,
                lieu_embarquement: customer?.lieuEmbarquement! ,
                lieu_debarquement: customer?.lieuDebarquement! ,
                centre_retrait: customer?.centreRetrait! ,
                bureau_dedoinement: customer?.bureauDedoinement! ,
                banque_domiciliation: customer?.banqueDomiciliation! ,
                date_arrivee_prevu: customer?.dateArriveePrevue! ,
                type_assurance: customer?.typeAssurance! ,
                compagnie_assurance: customer?.compagnieAssurance! ,
                type_police_abonnement: customer?.typePoliceAbonnement! ,
                numero_police_abonnement: customer?.numeroPoliceAbonnement! ,
                utilisateur: customer?.utilisateur! ,
                statut: customer?.statut! ,

                devise: customer?.devise! ,
                montant_total: customer?.montant_total! ,
                valeur_fob_cfa: customer?.valeur_fob_cfa! ,
                mode_reglement: customer?.mode_reglement! ,
                pays_origine: customer?.pays_origine! ,
                pays_destination: customer?.pays_destination! ,
                pays_provenence: customer?.pays_provenence! ,
                valeur_sortie_usine: customer?.valeur_sortie_usine! ,
                montant_fob: customer?.montant_fob! ,
                montant_fret: customer?.montant_fret! ,
                montant_assurance: customer?.montant_assurance! ,
                montant_autres: customer?.montant_autres! ,
                nature_autre_couts: customer?.nature_autre_couts! ,
                remise_sur_facture: customer?.remise_sur_facture! ,
                poids_net_total: customer?.poids_net_total! ,
                poids_brut_total: customer?.poids_brut_total! ,

                numero_compte: customer?.numero_compte! ,

                montant_total_devise: customer?.montant_total_devise! ,
                type_facture: customer?.type_facture! ,
                nomDeposant: customer?.nomDeposant! ,
                prenomDeposant: customer?.prenomDeposant! ,
                telephoneDeposant: customer?.telephoneDeposant! ,
                dateDepot: customer?.dateDepot! ,
                dateSoumission:customer?.dateSoumission ,
                traitementDossierPolesx:customer?.traitementDossierPoles,
                raisonSocial:customer?.usager?.raisonSociale,
                mode_transport_libelle:customer?.modeTransport?.libelle! ?? "--",
                statutPole:customer?.traitementDossierPoles?.responsePole,
                numeroDomiciliation: customer?.numeroDomiciliation!

              }
            )

          }
          ;
          console.log('Dossier log' ,dossiers1);

          console.log('Dossier Agent' ,dossiers);
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getDossierList(): Observable<any[]> {

    return this._httpClient
      .get<ResponseModel<Dossier[]>>(`${API_ROOT.dossiers_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const dossiers: Dossier[] = response?.data || [];
          console.log(dossiers)
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
    * Get dossiers
    */
  getDossiersByStatut(statut: { 'statut': EnumDossierStatut }): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Dossier[]>>(`${API_ROOT.dossiers_list_statut}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const dossiers: Dossier[] = response?.data || [];
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
    * Get dossiers
    */
  getDossiersRaDetail(id: any): Observable<any> {
    return this._httpClient
      .post<ResponseModel<any[]>>(`${API_ROOT.raDossier_get_ra}`, {
        "id": id
      }, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const dossiers: Dossier[] = response?.data || [];
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
    * Get dossiers Acte
    */
  getDossiersActeDetail(id: any, acte: EnumAct): Observable<any> {

    return this._httpClient
      .post<ResponseModel<any[]>>(`${API_ROOT.read_acte}`, {
        "dossier_id": id, "code_acte": acte
      }, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const res: any = response?.data || {};
          return of(res);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
    * Get dossiers
    */
  getDossiersAiDetail(id: any): Observable<any> {
    return this._httpClient
      .post<ResponseModel<any[]>>(`${API_ROOT.acteAi_get_ra}`, {
        "id": id
      }, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const dossiers: Dossier[] = response?.data || [];
          this._dossiers.next(dossiers);
          return of(dossiers);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }


  /**
   * Create dossier
   */
  createDossier(dossier: any): Observable<any> {

    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.dossiers_create}`, dossier, { 'headers': this.headers })
        .pipe(
          switchMap((response) => {
            if (response.success) {
              const newDossier: Dossier = response.data['dossier']!;
              // Update the dossiers with the new dossier
              this._dossiers.next([newDossier, ...dossiers!]);
              this._dossier.next(newDossier);
              console.log(newDossier);
              // Return the new dossier
              return of(newDossier);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }

  /**
     * Create dossier complet
     */
  create(object : string, dossier: any, vendeur: any = null, produits: any[] = []): Observable<any> {
    let data = {};
    if(object == 'dossier'){
      data = {
        "dossier": dossier,
        "vendeur": vendeur,
        "produits": produits
      }
    }else if(object == 'facture'){
      data = {
        "dossier": dossier,
        "vendeur": vendeur,
        "produits": produits
      }
    }else{
      data = {
        "dossier": dossier,
        "vendeur": vendeur,
        "produits": produits,
      }
    }

    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.creation_dossier_complet}`, data, { 'headers': this.headers }).pipe(
          switchMap((response) => {
            if (response.success) {
              const newDossier = response.data['dossier']!;
              const doc = response.data;
              // Update the dossiers with the new dossier
              this._dossiers.next([newDossier, ...dossiers!]);
              this._dossier.next(newDossier);
              // Return the new dossier
              return of(doc);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }

  update(dossier: any, facture: any, vendeur: any, produits: any[]): Observable<any> {

    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.update_dossier_complet}`, {
        "dossier": dossier,
        "facture": facture,
        "vendeur": vendeur,
        "produits": produits,
      },
        { 'headers': this.headers }).pipe(
          switchMap((response) => {
            if (response.success) {
              const newDossier: Dossier = response.data['dossier']!;
              // Update the dossiers with the new dossier
              this._dossiers.next([newDossier, ...dossiers!]);
              this._dossier.next(newDossier);
              console.log(newDossier);
              // Return the new dossier
              return of(newDossier);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }

  soumettreDossier(id: number): Observable<any> {
    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.soumission_paiement_dossier}`, {
        "id": id
      },
        { 'headers': this.headers }).pipe(
          switchMap((response) => {
            if (response.success) {
              // Return the new dossier
              return of(response.data.dossier);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }

  // traitementDossier(doc: any, structure: string, banqueTraitement?: string, codeActe?:string): Observable<any> {
  traitementDossier(doc: any, structure: string, codeActe?:string): Observable<any> {

    let url = API_ROOT.save_acte;
    let vars = {};

    console.log("Structure : ", structure);
    // console.log("banqueTraitement : ", banqueTraitement);
    console.log("codeActe : ", codeActe);

    if (structure == 'ABNORM') {

      if(codeActe == 'RA'){
        vars = {
          reference_acte: doc.reference_acte,
          initial_verificateur: doc.initial_verificateur,
          iniital_agent: doc.iniital_agent,
          conformite_id: doc.conformite_id,
          reference_laboratoire: doc.reference_laboratoire,
          date_reference_laboratoire: doc.date_reference_laboratoire,
          // info generique
          date_du_jour: doc.date_du_jour,
          nom_signataire: doc.nom_signataire,
          signature_cachet_agent: doc.signature_cachet_agent,
          statut: doc.statut,
          motif: doc.motif,
          nature_produit:doc.nature_produit,
        }
      }else if(codeActe == 'CNC'){
        vars = {
          faiACnc: doc.faiACnc,
          numeroRefCnc: doc.numeroRefCnc,
          numeroFactureCnc: doc.numeroFactureCnc,
          dateFactureCnc: doc.dateFactureCnc,
          nomImportateurCnc: doc.nomImportateurCnc,
          adresseImportateurCnc: doc.adresseImportateurCnc,
          telephonImortateurCnc: doc.telephonImortateurCnc,
          natureProduitCnc: doc.natureProduitCnc,
          numeroInspectionCnc: doc.numeroInspectionCnc,
          numeroGrosCnc: doc.numeroGrosCnc,
          bureauDedouanementCnc: doc.bureauDedouanementCnc,
          typeProduitCnc: doc.typeProduitCnc,
          dateInspectionCnc: doc.dateInspectionCnc,
          dateGrosCnc: doc.dateGrosCnc,
          provinceBureauCnc: doc.provinceBureauCnc,
          conformiteCnc: doc.conformiteCnc,

          // info generique
          date_du_jour: doc.date_du_jour,
          nom_signataire: doc.nom_signataire,
          signature_cachet_agent: doc.signature_cachet_agent,
          statut: doc.statut,
          motif: doc.motif
        }
      }


    } else if (structure == 'DGSV') {

      vars = {
        // info AI
        date_acte: doc.date_acte,
        description_marchandise: doc.description_marchandise,
        telphone_poste_controle: doc.telphone_poste_controle,
        nom_inspection: doc.nom_inspection,
        telephone_inspection: doc.telephone_inspection,
        poste_de_controle_veterinaire: doc.poste_de_controle_veterinaire,
        poste_de_controle_veterinaire_autre: doc.poste_de_controle_veterinaire_autre,
        option_veterinaire: doc.option_veterinaire,
        signature_validite: doc.signature_validite,
        nom_veterinaire: doc.nom_veterinaire,
        ressource: doc.ressource,
        // info generique
        date_du_jour: doc.date_du_jour,
        nom_signataire: doc.nom_signataire,
        signature_cachet_agent: doc.signature_cachet_agent,
        statut: doc.statut,
        motif: doc.motif
      };
    } else if(structure == 'ANRP'){
      // url = API_ROOT.save_acte;
      // url = doc.dossier_id != 0 ? API_ROOT.acteAi_update : API_ROOT.acteAi_create;
      vars = {
        date_acte: doc.date_acte,
        numero_acte: doc.numero_acte,
        nb: doc.nb,
        validite: doc.validite,
        entree: doc.entree,

        date_du_jour: doc.date_du_jour,
        nom_signataire: doc.nom_signataire,
        signature_cachet_agent: doc.signature_cachet_agent,
        statut: doc.statut,
        motif: doc.motif
      };
    } else if(structure == 'SP/GUCI'){
      vars = {
        initial_agent: doc.initial_agent,
        annee: doc.annee,
        sigle: doc.sigle,
        numero_serie: doc.numero_serie,
        natureAsi: doc.natureAsi,
        numeroDomiciliation: doc.numeroDomiciliation,
        date_reception: doc.date_reception,
        date_emission: doc.date_emission,
        termes_vente: doc.termes_vente,
        valeur_sortie_usine: doc.valeur_sortie_usine,
        mise_fca: doc.mise_fca,
        fca_declaration: doc.fca_declaration,
        mise_fob: doc.mise_fob,
        fob_facture: doc.fob_facture,
        fret_facture: doc.fret_facture,
        assurance_facture: doc.assurance_facture,
        autre_charge_facture: doc.autre_charge_facture,
        remise_facture: doc.remise_facture,
        valeur_totale: doc.valeur_totale,
        numero_domiciliation_bancaire: doc.numero_domiciliation_bancaire,

        // info generique
        date_du_jour: doc.date_du_jour,
        signature_cachet_agent: doc.signature_cachet_agent,
        nom_signataire: doc.nom_signataire,
        signature_cachet_importateur: doc.signature_cachet_importateur,
        signature_cachet_banque_domiciliation: doc.signature_cachet_banque_domiciliation,
        statut: doc.statut,
        motif: doc.motif
      }
    } else if(structure == 'ANSSEAT'){
      vars = {
        numeroCCPQ: doc.numeroCCPQ,
        referanceCCPQ: doc.referanceCCPQ,
        commentaire: doc.commentaire,

        // info generique
        date_du_jour: doc.date_du_jour,
        signature_cachet_agent: doc.signature_cachet_agent,
        nom_signataire: doc.nom_signataire,
        statut: doc.statut,
        motif: doc.motif
      }
    } else if(structure == 'ASSURANCE'){
      vars = {
        numeroAssurance: doc.numeroAssurance,
        numeroPoliceAssurance: doc.numeroPoliceAssurance,
        description: doc.description,
        moyenTrensport: doc.moyenTrensport,
        dateEmbarquement: doc.dateEmbarquement,
        valeurAssuranceChiffre: doc.valeurAssuranceChiffre,
        valeurAssuranceLettre: doc.valeurAssuranceLettre,
        conditionAssurance: doc.conditionAssurance,
        adresseConstatation: doc.adresseConstatation,
        adresseAssureur: doc.adresseAssureur,
        voyage: doc.voyage,
        franchise: doc.franchise,
        garantie: doc.garantie,
        capitalAssure: doc.capitalAssure,
        taux: doc.taux,
        primeNette: doc.primeNette,
        surPrime: doc.surPrime,
        frais: doc.frais,
        taxe: doc.taxe,
        totalAPayer: doc.totalAPayer,

        // info generique
        date_du_jour: doc.date_du_jour,
        signature_cachet_agent: doc.signature_cachet_agent,
        nom_signataire: doc.nom_signataire,
        statut: doc.statut,
        motif: doc.motif
      }
    } else if(structure == 'BANQUE'){

      if(codeActe == 'AC'){
        vars = {
          numeroEnregistrement: doc.numeroEnregistrement,
          intermediereAgree: doc.intermediereAgree,
          agence: doc.agence,
          dateDemande: doc.dateDemande,
          resident: doc.resident,
          raisonSocialeDemandeur: doc.raisonSocialeDemandeur,
          nationaliteDemandeur: doc.nationaliteDemandeur,
          adresseDemandeur: doc.adresseDemandeur,
          telephoneDemandeur: doc.telephoneDemandeur,
          villeDemandeur: doc.villeDemandeur,
          banqueBeneficiaire: doc.banqueBeneficiaire,
          adresseBeneficiaire: doc.adresseBeneficiaire,
          paysBeneficiaire: doc.paysBeneficiaire,
          nomBeneficiaire: doc.nomBeneficiaire,
          paysBanqueBeneficiaire: doc.paysBanqueBeneficiaire,
          numeroDomiciliation: doc.numeroDomiciliation,
          faitA: doc.faitA,
          dateDomiciliation: doc.dateDomiciliation,
          dateExecutionIntermediaire: doc.dateExecutionIntermediaire,
          deviseDomiciliation: doc.deviseDomiciliation,
          paysDomiciliation: doc.paysDomiciliation,
          creditDomiciliation: doc.creditDomiciliation,
          natureReglementDemande: doc.natureReglementDemande,
          pjDemande: doc.pjDemande,
          dateReglement: doc.dateReglement,
          numeroCompteDebiter: doc.numeroCompteDebiter,
          devisIntermediaire: doc.devisIntermediaire,
          montantChiffreIntermediaire: doc.montantChiffreIntermediaire,
          montantLettreIntermediaire: doc.montantLettreIntermediaire,
          contreValeursIntermediaire: doc.contreValeursIntermediaire,
          creditAuCompte: doc.creditAuCompte,
          titulaireCompteEtrange: doc.titulaireCompteEtrange,

          // info generique
          date_du_jour: doc.date_du_jour,
          statut: doc.statut,
          motif: doc.motif
        }
      } else if(codeActe == 'DPI'){
        vars = {
          initial_agent: doc.initial_agent,
          annee: doc.annee,
          sigle: doc.sigle,
          numero_serie: doc.numero_serie,
          natureAsi: doc.natureAsi,
          date_reception: doc.date_reception,
          date_emission: doc.date_emission,
          termes_vente: doc.termes_vente,
          valeur_sortie_usine: doc.valeur_sortie_usine,
          mise_fca: doc.mise_fca,
          fca_declaration: doc.fca_declaration,
          mise_fob: doc.mise_fob,
          fob_facture: doc.fob_facture,
          fret_facture: doc.fret_facture,
          assurance_facture: doc.assurance_facture,
          autre_charge_facture: doc.autre_charge_facture,
          remise_facture: doc.remise_facture,
          valeur_totale: doc.valeur_totale,
          numero_domiciliation_bancaire: doc.numero_domiciliation_bancaire,

          // info generique
          date_du_jour: doc.date_du_jour,
          signature_cachet_agent: doc.signature_cachet_agent,
          nom_signataire: doc.nom_signataire,
          signature_cachet_importateur: doc.signature_cachet_importateur,
          signature_cachet_banque_domiciliation: doc.signature_cachet_banque_domiciliation,
          statut: doc.statut,
          motif: doc.motif
        }
      } else if(codeActe == 'AI') {
        vars = {
          raisonSocialeImportateur: doc.raisonSocialeImportateur,
          adresseImportateur: doc.adresseImportateur,
          origineMarchandise: doc.origineMarchandise,
          provenanceMarchandise: doc.provenanceMarchandise,
          elementValeurFob: doc.elementValeurFob,
          valeurAjustement: doc.valeurAjustement,
          valeurEnDouane: doc.valeurEnDouane,
          fraisAccessoires: doc.fraisAccessoires,
          valeurTrensport: doc.valeurTrensport,
          autreValeur: doc.autreValeur,
          montantFactureEn1: doc.montantFactureEn1,
          valeurFobDevise: doc.valeurFobDevise,
          factureFrancoDedouane: doc.factureFrancoDedouane,
          montantAssuranceFacture: doc.montantAssuranceFacture,
          valeurFactureCaf: doc.valeurFactureCaf,
          numeroTitreImportation: doc.numeroTitreImportation,
          bureaurDouane: doc.bureaurDouane,
          typeDeclaration: doc.typeDeclaration,
          nomSignataireDouane: doc.nomSignataireDouane,
          signatureDouane: doc.signatureDouane,
          dateEnregistrementDouane: doc.dateEnregistrementDouane,
          titulaireDossierDom: doc.titulaireDossierDom,
          numeroDossierDom: doc.numeroDossierDom,
          banqueDom: doc.banqueDom,
          adresseBanqueDom: doc.adresseBanqueDom,
          nomSignataireBanque: doc.nomSignataireBanque,

          // info generique
          date_du_jour: doc.date_du_jour,
          statut: doc.statut,
          motif: doc.motif
        }
      } else if(codeActe == 'TE') {
        vars = {
          codeExportationTeEc: doc.codeExportationTeEc,
          paysDestinationTeEc: doc.paysDestinationTeEc,
          nomDeclarantTeEc: doc.nomDeclarantTeEc,
          adresseDeclarantTeEc: doc.adresseDeclarantTeEc,
          nomRecepteurTeEc: doc.nomRecepteurTeEc,
          adresseRecepteurTeEc: doc.adresseRecepteurTeEc,
          deviseFactureTeEc: doc.deviseFactureTeEc,
          valeurFrancsCfaTeEc: doc.valeurFrancsCfaTeEc,
          valeurEnDeviseTeEc: doc.valeurEnDeviseTeEc,
          numeroFactureTeEc: doc.numeroFactureTeEc,
          baseContratTeEc: doc.baseContratTeEc,
          elementFacturationTeEc: doc.elementFacturationTeEc,
          valeurMarchandiseTeEc: doc.valeurMarchandiseTeEc,
          fraisExportateurBurkinaTeEc: doc.fraisExportateurBurkinaTeEc,
          fraisExportateurEtrangerTeEc: doc.fraisExportateurEtrangerTeEc,
          natureExportationTeEc: doc.natureExportationTeEc,
          numeroTitreExportationTeEc: doc.numeroTitreExportationTeEc,
          banqueDomiciliationTeEc: doc.banqueDomiciliationTeEc,
          adresseBanqueTeEc: doc.adresseBanqueTeEc,
          dateOuvertureComptTeEc: doc.dateOuvertureComptTeEc,
          signataireBanqueTeEc: doc.signataireBanqueTeEc,
          nomSignataireDMCTeEc: doc.nomSignataireDMCTeEc,
          signatureDMCEc: doc.signatureDMCEc,
          dateSignatureDMCTeEc: doc.dateSignatureDMCTeEc,
          numeroDouaneTeEc: doc.numeroDouaneTeEc,
          numeroDeclarationTeEc: doc.numeroDeclarationTeEc,
          nomSignataireDouaneTeEc: doc.nomSignataireDouaneTeEc,
          dateSignataireDouaneTeEc: doc.dateSignataireDouaneTeEc,
          dateEnregistrementDouaneTeEc: doc.dateEnregistrementDouaneTeEc,
          numeroDomiciliation: doc.numeroDomiciliation,
          numeroCompteBanqueTeEc: doc.numeroCompteBanqueTeEc,

          // info generique
          date_du_jour: doc.date_du_jour,
          signature_cachet_agent: doc.signature_cachet_agent,
          statut: doc.statut,
          motif: doc.motif
        }
      }else if(codeActe == 'EC') {
        vars = {
          codeExportationTeEc: doc.codeExportationTeEc,
          paysDestinationTeEc: doc.paysDestinationTeEc,
          nomDeclarantTeEc: doc.nomDeclarantTeEc,
          adresseDeclarantTeEc: doc.adresseDeclarantTeEc,
          nomRecepteurTeEc: doc.nomRecepteurTeEc,
          adresseRecepteurTeEc: doc.adresseRecepteurTeEc,
          deviseFactureTeEc: doc.deviseFactureTeEc,
          valeurFrancsCfaTeEc: doc.valeurFrancsCfaTeEc,
          valeurEnDeviseTeEc: doc.valeurEnDeviseTeEc,
          numeroFactureTeEc: doc.numeroFactureTeEc,
          baseContratTeEc: doc.baseContratTeEc,
          elementFacturationTeEc: doc.elementFacturationTeEc,
          valeurMarchandiseTeEc: doc.valeurMarchandiseTeEc,
          fraisExportateurBurkinaTeEc: doc.fraisExportateurBurkinaTeEc,
          fraisExportateurEtrangerTeEc: doc.fraisExportateurEtrangerTeEc,
          natureExportationTeEc: doc.natureExportationTeEc,
          numeroTitreExportationTeEc: doc.numeroTitreExportationTeEc,
          banqueDomiciliationTeEc: doc.banqueDomiciliationTeEc,
          adresseBanqueTeEc: doc.adresseBanqueTeEc,
          dateOuvertureComptTeEc: doc.dateOuvertureComptTeEc,
          signataireBanqueTeEc: doc.signataireBanqueTeEc,
          nomSignataireDMCTeEc: doc.nomSignataireDMCTeEc,
          signatureDMCEc: doc.signatureDMCEc,
          dateSignatureDMCTeEc: doc.dateSignatureDMCTeEc,
          // numeroDouaneTeEc: doc.numeroDouaneTeEc,
          // numeroDeclarationTeEc: doc.numeroDeclarationTeEc,
          // nomSignataireDouaneTeEc: doc.nomSignataireDouaneTeEc,
          // dateSignataireDouaneTeEc: doc.dateSignataireDouaneTeEc,
          // dateEnregistrementDouaneTeEc: doc.dateEnregistrementDouaneTeEc,
          dateApurerAvantEc: doc.dateApurerAvantEc,
          dateApurerLeEc: doc.dateApurerLeEc,
          dateExigibiliteEc: doc.dateExigibiliteEc,
          numeroDomiciliation: doc.numeroDomiciliation,
          numeroCompteBanqueTeEc: doc.numeroCompteBanqueTeEc,

          // info generique
          date_du_jour: doc.date_du_jour,
          signature_cachet_agent: doc.signature_cachet_agent,
          statut: doc.statut,
          motif: doc.motif
        }
      }

    }

    let body = {
      variables: vars,
      dossier_id: doc.dossier_id!,
      structure_id: doc.structure_id!,
      codeActe: codeActe!
    //  structure_id: doc.structure_id!
    }
console.log('BODY SUMISSION',body);

    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${url}`, body, { 'headers': this.headers }).pipe(
          switchMap((response) => {
            if (response.success) {
              // Return the new dossier

              return of(response.data.dossier);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }



  updateDossier(dossier: any): Observable<any> {
    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<Dossier>>(`${API_ROOT.dossiers_update}`, this.formateDossierToDto(dossier)).pipe(
        switchMap((response) => {
          // Find the index of the updated dossier
          const index = dossiers?.findIndex(item => item.id === dossier.id)!;
          // Update the dossier
          dossiers[index] = new Dossier(response.data!);
          // Update the dossiers
          this._dossiers.next(dossiers);
          // Update the dossier if it's selected
          this._dossier.next(response.data!);
          // Return the updated dossier
          return of(response.data);
        })
      ))
    );
  }

  formateDossierToDto(dos: any) {
    return {
      'id': dos?.id ?? 0,
      'numero_sylvie': dos.numero_sylvie!,
      'nature_operation': dos.nature_operation!.id!,
      'codeifu': dos.codeifu!,
      'transitaire': dos.transitaire!.id!,
      'reglement_financier': dos.reglement_financier!.id!,
      'mode_transport': dos.mode_transport!.id!,
      'mode_expedition': dos.mode_expedition!.id!,
      'lieu_embarquement': dos.lieu_embarquement!,
      'lieu_debarquement': dos.lieu_debarquement!,
      'centre_retrait': dos.centre_retrait!.id!,
      'bureau_dedoinement': dos.bureau_dedoinement!.id!,
      'banque_domiciliation': dos.banque_domiciliation!.id!,
      'date_arrivee_prevu': dos.date_arrivee_prevu!,
      'type_assurance': dos.type_assurance!.id!,
      'compagnie_assurance': dos.compagnie_assurance!.id!,
      'type_police_abonnement': dos.type_police_abonnement!.id!,
      'numero_police_abonnement': dos.numero_police_abonnement!,
      'statut': dos.statut,
      'utilisateur': dos.utilisateur!.id!
    }
  }

  getDossierDetail(id: number, structure: StructurePartenaire): Observable<any> {
    var url: String;
    var prms: any;
    if(structure.typeStructure?.libelle === 'POLE TRAITANT'){
      url = API_ROOT.get_dossier_pole;
      prms = { 'id': id, 'structure': structure.id };
    }
    else {
      prms = { 'id': id };
      url = API_ROOT.get_dossier;
    }

    return this._httpClient.post<ResponseModel<any>>(
      `${url}`, prms,
      { 'headers': this.headers }).pipe(
        switchMap((response) => {
          if (response.success) {
            // Return the new dossier
            console.log('dossier detail')
            return of(response.data);
          } else {
            return of(null);
          }
        })
      );
  }

  getDossierDetailPole(id: number, structure: number): Observable<any> {
    return this._httpClient.post<ResponseModel<any>>(
      `${API_ROOT.get_dossier_pole}`, {
      "id": id,
      "structure": structure
    },
      { 'headers': this.headers }).pipe(
        switchMap((response) => {
          if (response.success) {
            return of(response.data);
          } else {
            return of(null);
          }
        })
      );
  }


  getDossierRA(id: number): Observable<any> {
    return this._dossiers.pipe(
      take(1),
      switchMap(dossiers => this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.generation_ra}`, {
        "id": id
      },
        { 'headers': this.headers }).pipe(
          switchMap((response) => {
            if (response.success) {
              // Return the new dossier
              return of(response.data);
            } else {
              return of(null);
            }
          })
        )
      )
    );
  }

  getDossierActeInfoComplementaire(dossier_id: number, acte_id: number): Observable<any> {

    return this._httpClient.post<ResponseModel<any>>(
        `${API_ROOT.infoComplementaire_dossier_read}`, {
            "dossier": dossier_id,
            "acte": acte_id
          }).pipe(
            switchMap((response) => {
              if (response.success) {
                return of(response.data);
              } else {
                return of(null);
              }
            })
          );
  }



  downloadPj(id: string): Observable<any> {
    return this._httpClient
      .get<ResponseModel<any[]>>(`${API_ROOT.download}${id}`)
      .pipe(
        switchMap((response) => {
          return of(response);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  downloadActe(id: number, acte: EnumAct): Observable<any> {
    return this._httpClient.post<ResponseModel<any>>(
          `${API_ROOT.print_acte}`, {
          "dossier_id": id, "code_acte": acte
        },
        { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          return of(response);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  deletePj(id: number): Observable<any> {

    return this._httpClient
      .post<ResponseModel<any>>(`${API_ROOT.pieceJointeDossier_delete}`, { 'id': id })
      .pipe(
        switchMap((response) => {
          return of(response);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getAllTraitementByDossier(idDossier: number): Observable<any[]>{
    return this._httpClient
      .get<any>(`${API_ROOT.traitement_dossier_pole_by_dossier}?idDossier=${idDossier}`)
      .pipe(
        switchMap((response) => {
          console.log("Traitements du dossier",response);
          return of(response);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  dossierFileDownload(numeroSylvie: number): Observable<any>{
    return this._httpClient
      .get<any>(`${API_ROOT.impression_recheche_document}${numeroSylvie}`)
      .pipe(
        switchMap((response) => {
          return of(response);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  updateNumDomiciliation(data: any): Observable<any>{
    return this._httpClient
    .post<any>(`${API_ROOT.update_num_domiciliation}`, data)
    .pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((err) => {
        console.log(err);
        return of([]);
      })
    );
  }

  telechargerDosierActe(idDossier: number, acteCode: string): Observable<any>{
    return this._httpClient
    // .post<any>(`${API_ROOT.bydossierAndCodeActe}`, {
    // "dossierId": idDossier,
    //   "acteCode": acteCode
    // })
    .get<any>(`${API_ROOT.bydossierAndCodeActe}/codeActe/${acteCode}/dossierId/${idDossier}`)
    .pipe(
      switchMap((response) => {
        return of(response);
      }),
      catchError((err) => {
        console.log(err);
        return of([]);
      })
    );
  }

  getUrlDossierActeByIdUrl(acteCode: string, dossierId: number){
    return API_ROOT.bydossierAndCodeActe +'/codeActe/'+acteCode+'/dossierId/'+dossierId
  }

  afficherDossierActe(dialog: MatDialog, acteCode: string, dossierId: number){
    dialog
      .open(PdfViewerComponent, {
        data:API_ROOT.bydossierAndCodeActe +'/codeActe/'+acteCode+'/dossierId/'+dossierId,
        width: '80%',
        height:'800px',
        enterAnimationDuration: '400ms',
        exitAnimationDuration: '300ms',
        disableClose: true,
      })
  }

  getHistoriqueTraitementActe(dossierId: number, acteCode: string){
    return this._httpClient
      .post<ResponseModel<any>>(`${API_ROOT.historique}`,{
          'dossier_id': dossierId,
          'code_acte': acteCode
       })
      .pipe(
        switchMap((response) => {
          return of(response.data!);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  historiqueTraitementViewer(dialog: MatDialog, acteCode: string, dossierId: number){
    dialog
      .open(HistoriqueTraitementComponent, {
        data:{
          dossierId: dossierId,
          acteCode: acteCode
        },
        width: '80%',
        enterAnimationDuration: '400ms',
        exitAnimationDuration: '300ms',
        disableClose: true,
      })
  }

}
