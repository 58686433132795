import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { ResponseModel } from 'src/app/model/response.model';
import { API_ROOT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsFunctionSharedService implements OnDestroy {
  constructor(private http: HttpClient) {}


  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }


  /**
   *Filtres Globlale Data Table
   * @param dataTableSource
   *
   * @param valeur valeur est le crictectre  de recherche
   * @returns return un tableau de donnees filtrer
   * @exemple : dataSource=this.filtrerAllDataTableColumn(dataTableSource, valeur)
   */
  public filtrerAllDataTableColumn(dataTableSource: any, valeur: string) {
    valeur = valeur.trim().toLowerCase();
    dataTableSource.filterPredicate = (data: any, filter: string) => {
      const str = JSON.stringify(data).toLowerCase();
      return str.indexOf(filter) !== -1;
    };
    return valeur;
  }

  public filtrerParNumeroSylvie(dataTableSource: any, numero: string) {
    dataTableSource.filterPredicate = (data: any, filter: string) =>
      data.numeroSylvie === parseInt(filter, 10);
    return numero.toString();
  }

  public filtrerByCriteria(dataTableSource: any, criteria: number | string) {
    let dataSource = (dataTableSource.filterPredicate = (
      data: any,
      filter: string
    ) =>
      data.numeroSylvie === parseInt(filter, 10) ||
      data.ifu === parseInt(filter, 10));
    return dataSource;
  }

  getData(url: string, event?:PageEvent): Observable<any>{
    return this.http
      .get<ResponseModel<any[]>>(`${url}?page=${event?.pageIndex}&size=${event?.pageSize}`)
      .pipe(
        switchMap((response) => {
          const _data: any[] = response?.data || [];
          return of(_data);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );

  }




  telechargerFicher(filePath: string): void {
    const signataireCertificatDTO = { filePath: filePath };
    this.downloadFile(signataireCertificatDTO).subscribe(
      (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filePath;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Erreur de Chargement du', error);
      }
    );
  }

  downloadFile(body: any): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    return this.http.post(
      API_ROOT.impression_telecharger_ficher,
      body,
      { headers, responseType: 'blob' }
    );
  }
  telechargerDuplicata(filePath: string,texteFiligrane:string): void {
    const duplicata = {
      filePath: filePath,
      texteFiligrane: texteFiligrane
    };
    this.downloadDuplicataFile(duplicata).subscribe(
      (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filePath;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Erreur de Chargement du', error);
      }
    );
  }
  downloadDuplicataFile(body: any): Observable<Blob> {
    const headers = new HttpHeaders().set('Accept', 'application/octet-stream');
    return this.http.post(
      API_ROOT.impression_telecharger_Duplicata,
      body,
      { headers, responseType: 'blob' }
    );
  }

  /**
   *
   * @param chemin
   * @param body
   * @returns
   */




    /**
     * Service avec gestion des erreurs back-end.
     */
    /**
     *
     * @param url
     * @returns
     */
  public get(url: string): Observable<HttpResponse<any>> {
      return this.http.get(url, {observe: 'response'})
  }

  public post(url: string, body: Object): Observable<HttpResponse<Object>> {
      return this.http.post<Object>(url, body, {observe: 'response'})
  }

  public put(url: string, body: Object): Observable<HttpResponse<Object>> {
      return this.http.put(url, body, {observe: 'response'})
  }

  public patch(url: string, body: Object): Observable<HttpResponse<Object>> {
      return this.http.patch(url, body, {observe: 'response'})
  }

  public patchList(url: string, body: Object): Observable<HttpResponse<any>> {
      return this.http.patch(url, body, {observe: 'response'})
  }

  public delete(url: string): Observable<HttpResponse<Object>> {
      return this.http.delete(url, {observe: 'response'})
  }

  public getWithHttpParams<T>(endpoint: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(`${endpoint}`, { params });
  }

  public postWithHttpParams<T>(endpoint: string, params?: HttpParams): Observable<T> {
    return this.http.post<T>(`${endpoint}`,{ params });
  }
}
