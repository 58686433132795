<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    <mat-icon [svgIcon]="icon"></mat-icon> {{ titre }}
  </h2>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">
  <p class="p-4 text-center">
    {{ textContent }}
  </p>
  <h1 *ngIf="data != null" class="text-center headline">{{data!}}</h1>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="secondary" mat-raised-button mat-dialog-close>
    ok
  </button>
  <!-- <button mat-button mat-dialog-close type="button">Fermer</button> -->
</mat-dialog-actions>
