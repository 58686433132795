import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger80ms } from '@vex/animations/stagger.animation';
import { CodeProduit } from 'src/app/model/codeProduit.model';
import { CodeProduitService } from 'src/app/services/codeProduit.service';
import { GeneralImportModuleModule } from 'src/app/shared/general-import-module/general-import-module.module';

@Component({
  selector: 'vex-search-code-tarif-dialog',
  templateUrl: './search-code-tarif-dialog.component.html',
  styleUrls: ['./search-code-tarif-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
  standalone: true,
  imports: [
    GeneralImportModuleModule
  ]
})
export class SearchCodeTarifDialogComponent implements OnInit{

  produitsList: CodeProduit[] = [];

  codeProduitFormGroup = this.fb.group({
    code: [this.defaults ?? '', [Validators.required, Validators.minLength(4)]],
    libelle: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any | undefined,
    private dialogRef: MatDialogRef<SearchCodeTarifDialogComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private _codeProduitService: CodeProduitService,
    private _changeRef: ChangeDetectorRef){
      // this.onSearchByCodeAndLabelle();
  }

  ngOnInit() {
    this.onSearchByCode();
  }

  onSearchByCodeOrLabelle(){
    this.produitsList = [];
    const code = this.codeProduitFormGroup.controls.code.value!;
    const libelle = this.codeProduitFormGroup.controls.libelle.value!;

    if(libelle === '' || libelle.length >= 4){
      this._codeProduitService.getSearche(code, libelle).subscribe(
        produits => {
          this.produitsList = produits;
          this._changeRef.markForCheck();
        }
      );
    }else{
      this.snackbar.open(
        'Veuillez saisir plus de quatre (4) caractère pour le nom scientifique !',
        undefined,
        {
          duration: 5000
        }
      );
    }

  }

  onSearchByLabelle(){
    this.produitsList = [];
    const libelle = this.codeProduitFormGroup.controls.libelle.value!;

    if(libelle.length >= 4){
      this._codeProduitService.getSearcheByKey(libelle).subscribe(
        produits => {
          this.produitsList = produits;
          this._changeRef.markForCheck();
        }
      );
    }else{
      this.snackbar.open(
        'Veuillez saisir plus de quatre (4) caractère pour la recherche !',
        undefined,
        {
          duration: 5000
        }
      );
    }
  }

  onSearchByCode(){
    this.produitsList = [];
    const code = this.codeProduitFormGroup.controls.code.value!;

    if(code.length >= 4){
      this._codeProduitService.getSearcheByKey(code).subscribe(
        produits => {
          this.produitsList = produits;
          this._changeRef.markForCheck();
        }
      );
    }else{
      this.snackbar.open(
        'Veuillez saisir plus de quatre (4) caractère pour la recherche !',
        undefined,
        {
          duration: 5000
        }
      );
    }
  }

  onSelectProduit(produit: any){
    console.log('Produit secetionne : ', produit);
    this.snackbar.open(
      'Produit sélectionné',
      undefined,
      {
        duration: 5000
      }
    );
    this.dialogRef.close(produit);

  }

}
