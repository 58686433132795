import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { Intervenant } from '../model/intervenant.model';
import { Assureur } from '../model/assureur.model';

@Injectable({
  providedIn: 'root'
})
export class IntervenantService {

  // Private
  private _intervenant: ReplaySubject<Intervenant> = new ReplaySubject<Intervenant>(1);
  private _intervenants: ReplaySubject<Intervenant[] | null> = new ReplaySubject();
  private _banques: ReplaySubject<Intervenant[] | null> = new ReplaySubject();
  private _assurances: ReplaySubject<Intervenant[] | null> = new ReplaySubject();

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for intervenant
   */
  get intervenant$(): Observable<Intervenant> {
    return this._intervenant.asObservable();
  }

  set intervenant(intervenant: Intervenant) {
    this._intervenant.next(intervenant);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getBanques(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Intervenant[]>>(`${API_ROOT.banque_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const banques: Intervenant[] = response?.data || [];
          this._banques.next(banques);
          return of(banques);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getAssurances(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Intervenant[]>>(`${API_ROOT.assureur_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const assurances: Intervenant[] = response?.data || [];
          this._assurances.next(assurances);
          return of(assurances);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

}
