import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger80ms } from '@vex/animations/stagger.animation';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

@Component({
  selector: 'vex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    ReactiveFormsModule,
    NgFor,
    ScrollingModule
  ]

})
export class ConfirmDialogComponent {

  titre : string = '';
  textContent : string = '';
  icon: string = 'mat:warning';
  data? : any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any | undefined,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,){
      console.log(defaults);
      this.titre = this.defaults?.titre!;
      this.textContent = this.defaults?.textContent!;
      this.icon = 'mat:'+ defaults?.icon! ?? 'mat:warning';
      this.data = this.defaults?.data! ?? null;
    }

  OnInit(){

  }

}
