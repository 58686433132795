import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger80ms } from '@vex/animations/stagger.animation';

@Component({
  selector: 'vex-input-error-labelle',
  templateUrl: './input-error-labelle.component.html',
  styleUrls: ['./input-error-labelle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class InputErrorLabelleComponent implements OnInit {

  @Input() control? : FormControl ;
  @Input() invalid? : boolean ;
  @Input() dirty? : boolean ;
  @Input() touched? : boolean ;
  @Input() name? : string ;

  constructor(){
    console.log(this.invalid)
    console.log(this.dirty)
    console.log(this.touched)
  }

  ngOnInit(){

  }

}
