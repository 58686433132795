
  <div class="flex items-center" mat-dialog-title>
    <img src="assets/img/avatars/doc.png" class="avatar mr-5" />

    <h2 class="headline m-0 flex-auto">
       Fichier PDF
     </h2>

    <button *ngIf="isBlobFile" color="primary" class="me-3" mat-fab-button type="button" (click)="telecharger()">
      <mat-icon svgIcon="mat:local_printshop"></mat-icon> Telecharger
    </button>

    <a *ngIf="!isBlobFile" color="primary" mat-fab-button class="btn bg-primary me-3" [href]="urlfile" target="_blank" rel="Telecharger">
      <mat-icon svgIcon="mat:local_printshop"></mat-icon> Telecharger
    </a>

    <button class="text-secondary ml-2" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content class="flex flex-col shadow-lg shadow-cyan-500">

    <pdf-viewer [src]="final_reporte_src"
                [autoresize]="true"
                [show-borders]="true"
                [original-size]="true"
                style="width: inherit; height: 100vh; margin: 20px"
    ></pdf-viewer>

   <!--
      <ngx-extended-pdf-viewer [src]="final_reporte_src"  [textLayer]="true"
        [showHandToolButton]="true" [handTool]="false" [showFindButton]="true" zoom="100%"
        [showPresentationModeButton]="true" [showDownloadButton]="true">
      </ngx-extended-pdf-viewer>
    -->
  </mat-dialog-content>

<!--
<mat-dialog-actions align="end">
    <button mat-button matDialogClose>Fermer</button>
    <button mat-button color="primary"><a [href]="urlfile" target="_blank" rel="noopener noreferrer">Telecharger</a></button>
</mat-dialog-actions> -->
