import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgIf } from '@angular/common';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { stagger80ms } from '@vex/animations/stagger.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

import { MatRadioModule } from '@angular/material/radio';
import { UserEntity } from 'src/app/model/user.entity';
import { RoleEntity } from 'src/app/model/role.entity';
import { StructurePartenaire } from 'src/app/model/structurePartenaire.model';
import { Observable, ReplaySubject, map, startWith, switchMap } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';
import { StructurePartenaireService } from 'src/app/services/structure-partenaire.service';
import { UserService } from 'src/app/services/user.service';
import { Dossier } from 'src/app/model/dossier.model';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ParametreValeurService } from 'src/app/services/parametreValeur.service';
import { EnumParam, ParametreValeur } from 'src/app/model/parametreValeur.model';
import { Transitaire } from 'src/app/model/transitaire.model';
import { TransitaireService } from 'src/app/services/trensitaire.service';
import { ProduitFacture } from 'src/app/model/produitFacture.model';
import { DeviseService } from 'src/app/services/devise.service';
import { Devise } from 'src/app/model/devise.model';
import { CodeTarifProduit } from 'src/app/model/codeTarifProduit.model';
import { CodeTarifProduitService } from 'src/app/services/codeTarifProduit.service';
import { IntervenantService } from 'src/app/services/intervenant.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { Intervenant } from 'src/app/model/intervenant.model';
import { DossierService } from 'src/app/services/dossier.service';
import { FactureService } from 'src/app/services/facture.service';
import { ProduitService } from 'src/app/services/Produit.service';
import { Facture } from 'src/app/model/facture.model';
import { Router } from '@angular/router';
import { UsagerService } from 'src/app/services/usager.service';
import { Usager } from 'src/app/model/usager.model';
import { log } from 'console';
import { Vendeur } from 'src/app/model/vendeur.model';
import { IncotermeService } from 'src/app/services/incoterm.service';
import { Incoterme } from 'src/app/model/incotermee.model';
import { Pays } from 'src/app/model/pays.model';
import { CodeProduit } from 'src/app/model/codeProduit.model';
import { CodeProduitService } from 'src/app/services/codeProduit.service';
import { IfuDialogComponent } from 'src/app/layouts/components/ifu-dialog/ifu-dialog.component';
import { SearchCodeTarifDialogComponent } from 'src/app/layouts/components/search-code-tarif-dialog/search-code-tarif-dialog.component';
import { ConfirmDialogComponent } from 'src/app/layouts/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'vex-dossier-create-update',
  templateUrl: './dossier-create-update.component.html',
  styleUrls: ['./dossier-create-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgIf,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    MatStepperModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatRadioModule,
    ScrollingModule
  ]
})
export class DossierCreateUpdateComponent implements OnInit {

  subject$: ReplaySubject<UserEntity[]> = new ReplaySubject<UserEntity[]>(1);
  structuresubject$: ReplaySubject<StructurePartenaire[]> = new ReplaySubject<StructurePartenaire[]>(1);
  transitairesubject$: ReplaySubject<Transitaire[]> = new ReplaySubject<Transitaire[]>(1);
  devisesubject$: ReplaySubject<Devise[]> = new ReplaySubject<Devise[]>(1);
  data$: Observable<UserEntity[]> = this.subject$.asObservable();
  structures$: Observable<StructurePartenaire[]> = this.structuresubject$.asObservable();
  transitaires$: Observable<StructurePartenaire[]> = this.transitairesubject$.asObservable();
  devises$: Observable<Devise[]> = this.devisesubject$.asObservable();

  list_incotermes: [] = [];

  users: UserEntity[] = [];
  structures: StructurePartenaire[] = [];
  transitaires: Transitaire[] = [];
  devises: Devise[] = [];
  banque_domiciliations: Intervenant[] = [];
  compagnie_assurances: Intervenant[] = [];
  produitsList: any[] = [];
  usagers: Usager[] = [];
  usagerIsValid: boolean = false;
  incotermeProduits: Incoterme[] = [];
  incotermeFactures: Incoterme[] = [];

  parametreValeurs_NATURE_OPERATION: ParametreValeur[] = [];
  parametreValeurs_REGLEMENT_FINANCIER: ParametreValeur[] = [];
  parametreValeurs_MODE_TRANSPORT: ParametreValeur[] = [];
  parametreValeurs_MODE_EXPEDITION: ParametreValeur[] = [];
  parametreValeurs_CENTRE_RETRAIT: ParametreValeur[] = [];
  parametreValeurs_BUREAU_DEDOUANEMENT: ParametreValeur[] = [];
  parametreValeurs_TYPE_ASSURANCE: ParametreValeur[] = [];
  parametreValeurs_TYPE_POLICE_ABONNEMENT: ParametreValeur[] = [];
  parametreValeurs_MODE_REGLEMENT: ParametreValeur[] = [];
  parametreValeurs_PAYS: Pays[] = [];
  parametreValeurs_ADRESSE: ParametreValeur[] = [];
  parametreValeurs_TYPE_FACTURE: ParametreValeur[] = [];
  parametreValeurs_UNITE_MESURE: ParametreValeur[] = [];
  parametres: ParametreValeur[] = [];

  dossiers: Dossier[] = []
  enumParam = EnumParam;
  static id = 100;
  user?: UserEntity;

  isValideProduit: boolean = false;

  nomDemandeur: string = this.defaults?.usager?.raisonSociale! ?? '';
  adresseDemandeur: string = this.defaults?.usager?.adresse! ?? '';
  rccmDemandeur: string = this.defaults?.usager.rccm! ?? '';
  directionDemandeur: string = this.defaults?.usager.direction! ?? '';
  telephoneDemandeur: string = this.defaults?.usager.telephone! ?? '';
  regimeFiscaleDemandeur: string = this.defaults?.usager.regimeFiscale! ?? '';
  etatNumeroDemandeur: string = this.defaults?.usager.etatNumero! ?? '';
  assujetiTvaDemandeur: string = this.defaults?.usager.assujetiTva! ?? '';
  siegeDemandeur: string = this.defaults?.usager.siege! ?? '';
  enseigneCommercialDemandeur: string = this.defaults?.usager.enseigneCommercial! ?? '';

  /**
   * Horizontal Stepper
   */

  dossierFormGroup = this.fb.group({
    id: [0],
    numero_sylvie: [0],
    nature_operation: [0, [Validators.required, Validators.nullValidator]],
    codeifu: [this.defaults?.dossier!.usager.numeroIfu! ?? '', Validators.required],
    // codeifu: [this.defaults?.dossier!.usager.numeroIfu! ?? ''],
    transitaire: [0, Validators.required],
    reglement_financier: [0, Validators.required],
    mode_transport: [0, Validators.required],
    // moyenTransport: [''],
    mode_expedition: [0, Validators.required],
    lieu_embarquement: ["", Validators.required],
    lieu_debarquement: ["", Validators.required],
    centre_retrait: [0, Validators.required],
    bureau_dedoinement: [0, Validators.required],
    numero_compte: [''],
    banque_domiciliation: [0],
    date_arrivee_prevu: [new Date().toJSON()],
    type_assurance: [0],
    compagnie_assurance: [0],
    type_police_abonnement: [0],
    numero_police_abonnement: [""],

    nomDeposant: [''],
    prenomDeposant: [''],
    telephoneDeposant: [''],

    dateDepot: [new Date().toJSON()],
    dateSoumission: [new Date().toJSON()],

    statut: ['EN_ATTENTE'],
    utilisateur: [0]
  });

  factureFormGroup = this.fb.group({
    devise: [0, Validators.required],
    incoterme_facture: [0, Validators.required],
    incoterme_produit: [0, Validators.required],
    montant_total: [0, Validators.required],
    montant_total_devise: [0, Validators.required],
    taux_change: [0, Validators.required],
    valeur_fob_cfa: [0, Validators.required],
    mode_reglement: [0, Validators.required],
    pays_origine: [0],
    pays_destination: [0],
    pays_provenence: [0],
    valeur_sortie_usine: [0, Validators.required],
    mise_a_fca: [0],
    fca_declare: [0, Validators.required],
    montant_fob: [0, Validators.required],
    mise_a_fob: [0],
    valeur_charge_sur: [0, Validators.required],
    frais_dechargement: [0, Validators.required],
    montant_fret: [0, Validators.required],
    montant_assurance: [0],
    montant_autres: [0],
    nature_autre_couts: [''],
    remise_sur_facture: [0],
    poids_net_total: [0],
    poids_brut_total: [0],
    type_facture: [0, Validators.required],
    numero_facture:[''],
    contre_valeur: [0],

  });

  vendeurFormGroup = this.fb.group({
    id: [0],
    nom: ['', Validators.required],
    adresse: ['', Validators.required],
    telephone: ['', Validators.required],
    fax: [''],
    email: ['', Validators.email],
    ville: [''],
    pays: [0, Validators.required],
    dossier: [0]
  });

  produitFormGroup = this.fb.group({
    id: [0],
    libelle: ['', Validators.required],
    quantite: [0, Validators.required],
    unite_mesure: [0, Validators.required],
    poids_brut: [0],
    prix_unitaire: [0],
    valeur_totale: [0, Validators.required],
    valeur_fob: [0],
    pays_origine: [0, Validators.required],
    pays_provenence: [0],
    code_produit: [0],
    contre_valeur: [''],
    posteEntree: [''],

    espece: [""],
    race: [""],
    sexe: [""],
    age: [""],
    robe: [""],
    facture: [0]
  });

  codeTarifFormGroup = this.fb.group({
    code: [''],
    libelle: [''],
  });

  incotermFormGroup = this.fb.group({
    incoterme_facture: [''],
    incoterme_produit: [''],
  });



  codeProduitFormGroup = this.fb.group({
    code: ['', Validators.required],
    libelle: ['', Validators.required],
  });

  confirmFormGroup = this.fb.group({
    terms: [null, Validators.requiredTrue],
    dossier: [this.dossierFormGroup.value],
    facture: [this.factureFormGroup.value],
    produitList: [this.produitsList]
  });

  form = this.fb.group({
    numero_sylvie: [this.generatenumero_sylvie()],
    nature_operation: [0],
    numeroIfu: [""],
    transitaire: [0],
    reglement_financier: [0],
    mode_transport: [0],
    mode_expedition: [0],
    lieu_embarquement: [""],
    lieu_debarquement: [""],
    centre_retrait: [0],
    bureau_dedoinement: [0],
    banque_domiciliation: [0],
    date_arrivee_prevu: [new Date().toJSON()],
    type_assurance: [0],
    compagnie_assurance: [0],
    type_police_abonnement: [0],
    numero_police_abonnement: [""],
    utilisateur: [0]
  });

  mode: 'create' | 'update' = 'create';

  filteredCodeIFU$ = this.dossierFormGroup.controls.codeifu.valueChanges.pipe(
    startWith(''),
    map((state) => (state ? this.filterCodeIFU(state) : this.usagers.slice()))
  );

  filteredIncotermeFacture$ = this.incotermFormGroup.controls.incoterme_facture.valueChanges.pipe(
    startWith(''),
    map((state) => (state ? this.filterIncotermeFacture(state) : this.incotermeFactures.slice()))
  );

  filteredIncotermeProduit$ = this.incotermFormGroup.controls.incoterme_produit.valueChanges.pipe(
    startWith(''),
    map((state) => (state ? this.filterIncotermeProduit(state) : this.incotermeProduits.slice()))
  );
  fobIsValide: boolean = false;

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public defaults: any | undefined,
    private dialogRef: MatDialogRef<DossierCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private _paramService: ParametreValeurService,
    private _transitaireService: TransitaireService,
    private _userService: UserService,
    private _structureService: StructurePartenaireService,
    private _deviseService: DeviseService,
    private _codeTarifProduitService: CodeTarifProduitService,
    private _codeProduitService: CodeProduitService,
    private _intervenantService: IntervenantService,
    private _dossierService: DossierService,
    private _changeRef: ChangeDetectorRef,
    private _usagerService: UsagerService,
    private _incotermeService: IncotermeService,
    private router: Router) {

    this._dossierService.getDossierList().subscribe(
      list => {
        this.dossiers = list;
      }
    );

    this._incotermeService.getIncotermeFactures().subscribe(
      list => {
        this.incotermeFactures = list;
      }
    );

    this._usagerService.getUsagers().subscribe(
      list => {
        this.usagers = list;
      }
    );

    _userService.get().subscribe(
      user => {
        this.user = user;
        // console.log(user)
        this.dossierFormGroup.controls.utilisateur.setValue(user.id!);
        // console.log(this.dossierFormGroup.controls.utilisateur.value)
      }
    );

    this.getParams();

    this.getData().subscribe((users) => {
      console.log(users)
      this.subject$.next(users);
    });

    this.getStructures().subscribe((structures) => {
      console.log(structures)
      this.structuresubject$.next(structures);
    });

    this.getTransiatires().subscribe((transitaire) => {
      console.log(transitaire)
      this.transitairesubject$.next(transitaire);
    });

    this.getDevises().subscribe((devise) => {
      this.devisesubject$.next(devise);
    });

    this.data$.subscribe((users) => {
      this.users = users;
    });

    this.structures$.subscribe((structures) => {
      this.structures = structures;
    });

    this.devises$.subscribe((devises) => {
      this.devises = devises;
    });

    this.transitaires$.subscribe((transitaire) => {
      this.transitaires = transitaire;
    });

    this.codeProduitFormGroup.controls.code.disable();

    _changeRef.markForCheck();
  }

  generatenumero_sylvie() {
    return this.dossiers.length;
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    return this._userService.getUsers();
  }

  getStructures() {
    return this._structureService.getStructures();
  }

  getTransiatires() {
    return this._transitaireService.getTransitaires();
  }

  getDevises() {
    return this._deviseService.getDevises();
  }

  getCodeTarifProduits() {
    return this._codeTarifProduitService.getCodeTarifProduits();
  }

  getCodeProduits() {
    return this._codeProduitService.getCodeProduits();
  }

  getParams() {

    this._intervenantService.getBanques().subscribe(
      parmas => {
        this.banque_domiciliations = parmas!;
      }
    );

    this._intervenantService.getAssurances().subscribe(
      parmas => {
        this.compagnie_assurances = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.UNITE_MESURE).subscribe(
      parmas => {
        this.parametreValeurs_UNITE_MESURE = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.TYPE_FACTURE).subscribe(
      parmas => {
        this.parametreValeurs_TYPE_FACTURE = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.NATURE_OPERATION).subscribe(
      parmas => {
        this.parametreValeurs_NATURE_OPERATION = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.REGLEMENT_FINANCIER).subscribe(
      parmas => {
        this.parametreValeurs_REGLEMENT_FINANCIER = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.MODE_REGLEMENT).subscribe(
      parmas => {
        this.parametreValeurs_MODE_REGLEMENT = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.MODE_TRANSPORT).subscribe(
      parmas => {
        this.parametreValeurs_MODE_TRANSPORT = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.MODE_EXPEDITION).subscribe(
      parmas => {
        this.parametreValeurs_MODE_EXPEDITION = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.BUREAU_DEDOUANEMENT).subscribe(
      parmas => {
        this.parametreValeurs_BUREAU_DEDOUANEMENT = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.CENTRE_RETRAIT).subscribe(
      parmas => {
        this.parametreValeurs_CENTRE_RETRAIT = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.TYPE_ASSURANCE).subscribe(
      parmas => {
        this.parametreValeurs_TYPE_ASSURANCE = parmas!;
      }
    );

    this._paramService.getParametreValeurs(EnumParam.TYPE_POLICE_ABONNEMENT).subscribe(
      parmas => {
        this.parametreValeurs_TYPE_POLICE_ABONNEMENT = parmas!;
      }
    );

    this._paramService.getParametrePays().subscribe(
      parmas => {
        this.parametreValeurs_PAYS = parmas!;

        this.parametreValeurs_PAYS.sort((a, b) => {
          const nameA = a.libelle!.toLocaleLowerCase(); // ignore upper and lowercase
          const nameB = b.libelle!.toLocaleLowerCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

      }
    )

  }

  ngOnInit() {

    console.log('default')
    console.log(this.defaults);

    this.disableProduitInput();

    if (this.defaults != undefined) {
      this.initDossierUpdate()
      console.log('default Init ')
      console.log(this.dossierFormGroup.value);
    }

    console.log(this.dossierFormGroup.value);
    console.log(this.factureFormGroup.value);

    this.factureFormGroup.controls.devise.valueChanges.subscribe(
      val => {
        let devs = this.devises.find(d => d.id == val);
        let montant_total_devise = this.factureFormGroup.controls.montant_total_devise.value!;
        this.factureFormGroup.controls.montant_total.setValue(montant_total_devise * devs?.tauxChange!);
        // this.factureFormGroup.controls.valeur_fob_cfa.setValue(montant_total * devs?.tauxChange!);
        this.factureFormGroup.controls.taux_change.setValue(devs?.tauxChange!);
        this.factureFormGroup.controls.valeur_fob_cfa.setValue(devs?.tauxChange! * this.factureFormGroup.controls.montant_fob.value!)
      }
    );

    this.factureFormGroup.controls.montant_fob.valueChanges.subscribe(
      val => {
        this.factureFormGroup.controls.valeur_fob_cfa.setValue(val! * this.factureFormGroup.controls.taux_change.value!)
      }
    )

    this.factureFormGroup.controls.montant_total_devise.valueChanges.subscribe(
      val => {
        console.log(val);
        let devs = this.devises.find(d => d.id == this.factureFormGroup.controls.devise.value!);
        this.factureFormGroup.controls.montant_total.setValue( val! * devs?.tauxChange!);
      }
    );

    this.factureFormGroup.controls.incoterme_facture.valueChanges.subscribe(
      incoFact => {
        this._incotermeService.getIncotermeProduits(incoFact!).subscribe(
          list => {
            this.incotermeProduits = list ?? [];
            this.list_incotermes = list;
          }
        );
      }
    );

    this.factureFormGroup.controls.incoterme_produit.valueChanges.subscribe(
      incotermProduit => {
        var curr_incoterme = this.list_incotermes.find(e => e['id'] == incotermProduit);
        var fields = (curr_incoterme!['fields'] as String).replace(/\s/g, "");
        //INITIALIZE
        "valeur_sortie_usine,mise_a_fca,fca_declare,mise_a_fob,montant_fret,assurance,valeur_charge_sur,frais_dechargement".split(',').forEach((elm) => {
          this.factureFormGroup.get(elm)?.enable();
        });

        fields.split(',').forEach((element) => {
          if(element!=''){
            this.factureFormGroup.get(element)?.setValue(0);
            this.factureFormGroup.get(element)?.disable();
          }
        });

      }
    );

  }

  getIncoProdById(id: number){
    return this.incotermeProduits.find(pro => pro.id == id)!.libelle!;
  }

  getIncoFactById(id: number){
    return this.incotermeFactures.find(fact => fact.id == id)!.libelle!;
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  saveDossier(){

    const facture = {
      'devise': this.factureFormGroup.controls.devise.value,
      'montant_total': this.factureFormGroup.controls.montant_total.value,
      'montant_total_devise': this.factureFormGroup.controls.montant_total_devise.value,
      'valeur_fob_cfa': this.factureFormGroup.controls.valeur_fob_cfa.value,
      'mode_reglement': this.factureFormGroup.controls.mode_reglement.value,
      'pays_origine': this.factureFormGroup.controls.pays_origine.value,
      'pays_destination': this.factureFormGroup.controls.pays_destination.value,
      'pays_provenence': this.factureFormGroup.controls.pays_provenence.value,
      'valeur_sortie_usine': this.factureFormGroup.controls.valeur_sortie_usine.value,
      'mise_a_fca': this.factureFormGroup.controls.mise_a_fca.value,
      'fca_declare': this.factureFormGroup.controls.fca_declare.value,
      'montant_fob': this.factureFormGroup.controls.montant_fob.value,
      'mise_a_fob': this.factureFormGroup.controls.mise_a_fob.value,
      'valeur_charge_sur': this.factureFormGroup.controls.valeur_charge_sur.value,
      'frais_dechargement': this.factureFormGroup.controls.frais_dechargement.value,
      'montant_fret': this.factureFormGroup.controls.montant_fret.value,
      'montant_assurance': this.factureFormGroup.controls.montant_assurance.value,
      'montant_autres': this.factureFormGroup.controls.montant_autres.value,
      'nature_autre_couts': this.factureFormGroup.controls.nature_autre_couts.value,
      'remise_sur_facture': this.factureFormGroup.controls.remise_sur_facture.value,
      'poids_net_total': this.factureFormGroup.controls.poids_net_total.value,
      'poids_brut_total': this.factureFormGroup.controls.poids_brut_total.value,
      'type_facture': this.factureFormGroup.controls.type_facture.value,
      'incoterme_produit': this.factureFormGroup.controls.incoterme_produit.value,
      'incoterme_facture': this.factureFormGroup.controls.incoterme_facture.value,
      'numero_facture': this.factureFormGroup.controls.numero_facture.value,
    };

    let dossier = {...this.dossierFormGroup.value, ...facture};
    this._dossierService.create('dossier', dossier).subscribe(
      doc => {
        console.log("success");
        console.log(doc);
        const dossier = doc?.dossier!;
        const vendeur = doc?.vendeur!;
        this.dossierFormGroup.controls.id.setValue(dossier.id!);
        this.vendeurFormGroup.controls.id.setValue(vendeur?.id! ?? 0);

        this.snackbar.open(
          'Votre dossier a ete enregistre avec succes !',
          undefined,
          {
            duration: 5000
          }
        );

      }
    )

  }

  saveFacture(){
    const facture = {
      'devise': this.factureFormGroup.controls.devise.value,
      'montant_total': this.factureFormGroup.controls.montant_total.value,
      'montant_total_devise': this.factureFormGroup.controls.montant_total_devise.value,
      'valeur_fob_cfa': this.factureFormGroup.controls.valeur_fob_cfa.value,
      'mode_reglement': this.factureFormGroup.controls.mode_reglement.value,
      'pays_origine': this.factureFormGroup.controls.pays_origine.value,
      'pays_destination': this.factureFormGroup.controls.pays_destination.value,
      'pays_provenence': this.factureFormGroup.controls.pays_provenence.value,
      'valeur_sortie_usine': this.factureFormGroup.controls.valeur_sortie_usine.value,
      'mise_a_fca': this.factureFormGroup.controls.mise_a_fca.value,
      'fca_declare': this.factureFormGroup.controls.fca_declare.value,
      'montant_fob': this.factureFormGroup.controls.montant_fob.value,
      'mise_a_fob': this.factureFormGroup.controls.mise_a_fob.value,
      'valeur_charge_sur': this.factureFormGroup.controls.valeur_charge_sur.value,
      'frais_dechargement': this.factureFormGroup.controls.frais_dechargement.value,
      'montant_fret': this.factureFormGroup.controls.montant_fret.value,
      'montant_assurance': this.factureFormGroup.controls.montant_assurance.value,
      'montant_autres': this.factureFormGroup.controls.montant_autres.value,
      'nature_autre_couts': this.factureFormGroup.controls.nature_autre_couts.value,
      'remise_sur_facture': this.factureFormGroup.controls.remise_sur_facture.value,
      'poids_net_total': this.factureFormGroup.controls.poids_net_total.value,
      'poids_brut_total': this.factureFormGroup.controls.poids_brut_total.value,
      'type_facture': this.factureFormGroup.controls.type_facture.value,
      'incoterme_produit': this.factureFormGroup.controls.incoterme_produit.value,
      'incoterme_facture': this.factureFormGroup.controls.incoterme_facture.value,
      'numero_facture': this.factureFormGroup.controls.numero_facture.value,
    };

    var valeur_sortie_usine = this.factureFormGroup.controls.valeur_sortie_usine.value ?? 0,
      mise_a_fob = this.factureFormGroup.controls.mise_a_fob.value ?? 0,
      valeur_charge_sur = this.factureFormGroup.controls.valeur_charge_sur.value ?? 0,
      mise_a_fca = this.factureFormGroup.controls.mise_a_fca.value ?? 0,
      fca_declare = this.factureFormGroup.controls.fca_declare.value ?? 0,
      valeur_total = this.factureFormGroup.controls.montant_total_devise.value ?? 0,
      fob = this.factureFormGroup.controls.montant_fob.value ?? 0,
      montant_fret = this.factureFormGroup.controls.montant_fret.value ?? 0,
      assurance = this.factureFormGroup.controls.montant_assurance.value ?? 0,
      frais_dechargement = this.factureFormGroup.controls.frais_dechargement.value ?? 0,
      montant_autres = this.factureFormGroup.controls.montant_autres.value ?? 0;

    var continuer: Boolean = true;

    var _fob = valeur_sortie_usine + mise_a_fob + valeur_charge_sur + mise_a_fca;
    if(valeur_sortie_usine == 0 && mise_a_fob == 0) _fob = fob;
    if((valeur_sortie_usine > 0 || fca_declare > 0 || mise_a_fob > 0) && fob != _fob){
      // alert("ATTENTION: Le montant FOB est égale à : " + _fob + " Vérifier les valeurs");
      this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              // data: ,
              icon: 'warning',
              titre: 'ATTENTION: Vérifier les valeurs',
              textContent: "Le montant FOB est égale à : " + _fob + " - Vérifier les valeurs"
            }
          })
            .afterClosed()
            .subscribe((value) => { });
      continuer = false;
    }

    var _fca_declare = valeur_sortie_usine + mise_a_fca;
    if(mise_a_fca > 0 && fca_declare != _fca_declare){
      // alert("ATTENTION: FCA Déclaré = Valeur sortie d'usine + Mise à FCA : " + _fca_declare);
      this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              // data: ,
              icon: 'warning',
              titre: 'ATTENTION: Vérifier les valeurs',
              textContent: "FCA Déclaré = Valeur sortie d'usine + Mise à FCA : " + _fca_declare
            }
          })
            .afterClosed()
            .subscribe((value) => { });
      continuer = false;
    }

    var _valeur_total = _fob + montant_fret + assurance + frais_dechargement + montant_autres;
    if(valeur_total != _valeur_total){
      // alert("ATTENTION: Le Montant Total est égale à : " + _valeur_total + " Vérifier les valeurs --"+valeur_total);
      this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          // data: ,
          icon: 'warning',
          titre: 'ATTENTION: Vérifier les valeurs',
          textContent: "Le Montant Total (devise) est égale à : " + _valeur_total + " Vérifier les valeurs"
        }
      })
        .afterClosed()
        .subscribe((value) => { });
      continuer = false;
    }


    let dossier = {...this.dossierFormGroup.value, ...facture};

    this.vendeurFormGroup.controls.dossier.setValue(dossier.id! ?? 0);
    if(continuer){
      const vendeur = this.vendeurFormGroup.value;
      this._dossierService.create('facture', dossier, vendeur).subscribe(
        doc => {
          const dossier = doc?.dossier!;
          const vendeur = doc?.vendeur!;

          this.dossierFormGroup.controls.id.setValue(dossier.id!);
          this.vendeurFormGroup.controls.id.setValue(vendeur?.id! ?? 0);

          this.snackbar.open(
            'Votre dossier a ete enregistre avec succes !',
            undefined,
            {
              duration: 5000
            }
          );

        }
      );

    }

  }

  submit() {
    let dossier = {...this.dossierFormGroup.value, ...this.factureFormGroup.value};
    // this.vendeurFormGroup.controls.dossier.setValue(dossier.id!);

    const vendeur = this.vendeurFormGroup.value;

    const produits = this.produitsList;

    this._dossierService.create('complet',dossier, vendeur, produits).subscribe(
      doc => {
        console.log("success");
        const dossier = doc?.dossier!;
        const vendeur = doc?.vendeur!;

        this.dossierFormGroup.controls.id.setValue(dossier?.id!);
        this.vendeurFormGroup.controls.id.setValue(vendeur?.id! ?? 0);

        this.snackbar.open(
          'Votre dossier a ete enregistre avec succes !',
          undefined,
          {
            duration: 5000
          }
        );
        this.dialogRef.close(dossier!);

      }
    )

  }

  soumettreDossier(){
    this.submit();
    const docId = this.defaults?.dossier?.id!;
    const codeActe = this.defaults!.traitementDossierPolesx!.acteCode!;

    this._dossierService.soumettreDossier(docId).subscribe(
      dossier => {
        this.snackbar.open(
          'Le dossier a ete soumis au traitement avec succes',
          undefined,
          {
            duration: 5000
          }
        );
        // this.router.navigate(['admin/gestion-dossier']);
        this.dialogRef.close(dossier);
      }
    )

  }

  verifierCodeifu(){
    let resp = null;
    console.log("code ifu");
    console.log(this.dossierFormGroup.controls.codeifu.value);

    this._usagerService.verifierUsager(this.dossierFormGroup.controls.codeifu.value!).subscribe(
      usg => {
        // console.log(usg);
        if(usg != null){

          this.usagerIsValid = true;

          this.nomDemandeur = usg.raisonSociale!;
          this.adresseDemandeur = usg.adresse!;
          this.rccmDemandeur = usg.rccm!;
          this.directionDemandeur = usg.direction!;
          this.telephoneDemandeur = usg.telephone!;
          this.siegeDemandeur = usg.siege!;
          this.regimeFiscaleDemandeur = usg.regimeFiscale!;
          this.etatNumeroDemandeur = usg.etatNumero!;
          this.assujetiTvaDemandeur = usg.assujetiTva!;
          this.enseigneCommercialDemandeur = usg.enseigneCommercial!;

          this.dialog.open(IfuDialogComponent, {
            data: usg!
          });

          resp = true;

          this._changeRef.markForCheck();
        }else{

          this.usagerIsValid = false;

          this.snackbar.open(
            'Veillez saisir un code IFU valide !',
            undefined,
            {
              duration: 9000
            }
          );

          resp = false;
          this._changeRef.markForCheck();
        }
      }
    )

    return resp;
  }

  showFormValue(value: any) {
    console.log(value);
  }

  addProduit() {
    this.produitsList.push(this.produitFormGroup.value);
    this.produitFormGroup.reset();
    this.codeProduitFormGroup.reset();
    this.codeProduitFormGroup.controls.code.setValue('');

    this.isValideProduit = false;
    this.disableProduitInput();

    this._changeRef.markForCheck();
  }

  onDeleteProduit(id: number) {
    const index = this.produitsList.findIndex(obj => obj.id == id);
    if (index > -1) {
      this.produitsList.splice(index, 1);
    }

  }

  updateProduit() {
    this.produitsList.push(this.produitFormGroup.value);
    this.produitFormGroup.reset();
    this.codeProduitFormGroup.reset();
  }

  onEditeProduit(produit: any) {

    this._codeProduitService.getCodeTarifProduit(produit.code_produit!)
      .subscribe(
        produitsCodeTarif => {

          this.isValideProduit = true;
          this.disableProduitInput();

          this.codeProduitFormGroup.controls.libelle.setValue(produitsCodeTarif.parent + ' : ' + produitsCodeTarif.libelle);
          this.codeProduitFormGroup.controls.code.setValue(produitsCodeTarif.code);

          this.produitFormGroup.controls.id.setValue(produit.id);
          this.produitFormGroup.controls.code_produit.setValue(produit.code_produit);
          this.produitFormGroup.controls.libelle.setValue(produit.libelle);
          this.produitFormGroup.controls.pays_origine.setValue(produit.pays_origine);
          this.produitFormGroup.controls.pays_provenence.setValue(produit.pays_provenence);
          this.produitFormGroup.controls.poids_brut.setValue(produit.poids_brut);
          this.produitFormGroup.controls.prix_unitaire.setValue(produit.prix_unitaire);
          this.produitFormGroup.controls.quantite.setValue(produit.quantite);
          this.produitFormGroup.controls.unite_mesure.setValue(produit.unite_mesure);
          this.produitFormGroup.controls.valeur_fob.setValue(produit.valeur_fob);
          this.produitFormGroup.controls.valeur_totale.setValue(produit.valeur_totale);

          this._changeRef.markForCheck();

        }
      );

    // this.produitFormGroup.controls.sexe.setValue(produit.sexe);
    // this.produitFormGroup.controls.race.setValue(produit.race);
    // this.produitFormGroup.controls.espece.setValue(produit.espece);
    // this.produitFormGroup.controls.robe.setValue(produit.robe);
    // this.produitFormGroup.controls.age.setValue(produit.age);

    this.onDeleteProduit(produit.id)
  }

  getParametreLabelle(id: number, param: EnumParam) {

    let labelle = '';
    switch (param) {
      case EnumParam.NATURE_OPERATION:
        labelle = this.parametreValeurs_NATURE_OPERATION.filter(u => u.id == id)[0]?.libelle!;
        break;

      case EnumParam.UNITE_MESURE:
        labelle = this.parametreValeurs_UNITE_MESURE.filter(u => u.id == id)[0]?.libelle!;
        break;

      case EnumParam.MODE_TRANSPORT:
        labelle = this.parametreValeurs_MODE_TRANSPORT.filter(u => u.id == id)[0]?.libelle!;
        break;

      default:
        break;
    }
    return labelle;
  }

  initDossierUpdate() {
    const dossier = this.defaults?.dossier!;
    const vendeur = this.defaults?.vendeur!;

    this.dossierFormGroup.controls.id.setValue(dossier.id!);
    this.dossierFormGroup.controls.numero_sylvie.setValue(dossier.numeroSylvie!);
    this.dossierFormGroup.controls.nomDeposant.setValue(dossier.nomDeposant!);
    this.dossierFormGroup.controls.prenomDeposant.setValue(dossier.prenomDeposant!);
    this.dossierFormGroup.controls.telephoneDeposant.setValue(dossier.telephoneDeposant!);
    this.dossierFormGroup.controls.numero_compte.setValue(dossier.numeroCompte!);
    this.dossierFormGroup.controls.nature_operation.setValue(dossier.natureOperation?.id!);
    this.dossierFormGroup.controls.transitaire.setValue(dossier.transitaire?.id!);
    this.dossierFormGroup.controls.reglement_financier.setValue(dossier.reglementFinancier!);
    this.dossierFormGroup.controls.mode_transport.setValue(dossier.modeTransport?.id!);
    this.dossierFormGroup.controls.mode_expedition.setValue(dossier.modeExpedition?.id!);
    this.dossierFormGroup.controls.lieu_embarquement.setValue(dossier.lieuEmbarquement!);
    this.dossierFormGroup.controls.lieu_debarquement.setValue(dossier.lieuDebarquement!);
    this.dossierFormGroup.controls.centre_retrait.setValue(dossier.centreRetrait?.id!);
    this.dossierFormGroup.controls.bureau_dedoinement.setValue(dossier.bureauDedouanement?.id!);
    this.dossierFormGroup.controls.banque_domiciliation.setValue(dossier.banque?.id!);
    // this.dossierFormGroup.controls.date_arrivee_prevu.setValue(dossier.date_arrivee_prevu!.toJSON());

    this.dossierFormGroup.controls.type_assurance.setValue(dossier.typeAssurance?.id!);
    this.dossierFormGroup.controls.compagnie_assurance.setValue(dossier.assureur?.id!);
    this.dossierFormGroup.controls.type_police_abonnement.setValue(dossier.typePoliceAbonnement?.id!);
    this.dossierFormGroup.controls.numero_police_abonnement.setValue(dossier.numeroPoliceAssurance!);
    this.dossierFormGroup.controls.utilisateur.setValue(dossier.utilisateur?.id!);
    // this.dossierFormGroup.controls.statut.setValue(dossier.statut!);

    this.factureFormGroup.controls.type_facture.setValue(dossier.typeFacture?.id!);
    this.factureFormGroup.controls.devise.setValue(dossier.devise?.id!);
    this.factureFormGroup.controls.montant_total.setValue(dossier.montantTotal!);
    this.factureFormGroup.controls.montant_total_devise.setValue(dossier.montantTotalDevise!);
    this.factureFormGroup.controls.valeur_fob_cfa.setValue(dossier.valeurFobCfa!);
    this.factureFormGroup.controls.mode_reglement.setValue(dossier.modeReglement?.id!);
    this.factureFormGroup.controls.pays_origine.setValue(dossier.paysOrigine?.id!);
    this.factureFormGroup.controls.pays_destination.setValue(dossier.paysDestination?.id!);
    this.factureFormGroup.controls.pays_provenence.setValue(dossier.paysProvenance?.id!);
    this.factureFormGroup.controls.valeur_sortie_usine.setValue(dossier.valeurSortieUsine!);
    this.factureFormGroup.controls.mise_a_fca.setValue(dossier.mise_a_fca!);
    this.factureFormGroup.controls.fca_declare.setValue(dossier.fca_declare!);

    this.factureFormGroup.controls.montant_fob.setValue(dossier.valeurFob!);
    this.factureFormGroup.controls.mise_a_fob.setValue(dossier.miseAFob!);
    this.factureFormGroup.controls.valeur_charge_sur.setValue(dossier.valeurChargeSur!);
    this.factureFormGroup.controls.frais_dechargement.setValue(dossier.fraisDechargement!);
    this.factureFormGroup.controls.montant_fret.setValue(dossier.montantFret!);
    this.factureFormGroup.controls.montant_assurance.setValue(dossier.montantAssurance!);
    this.factureFormGroup.controls.montant_autres.setValue(dossier.montantAutresCouts!);
    this.factureFormGroup.controls.nature_autre_couts.setValue(dossier.natureAutresCouts!);
    this.factureFormGroup.controls.remise_sur_facture.setValue(dossier.remiseSurFacture!);
    this.factureFormGroup.controls.poids_net_total.setValue(dossier.poidsNetTotal!);
    this.factureFormGroup.controls.poids_brut_total.setValue(dossier.poidsBrutTotal!);
    this.factureFormGroup.controls.contre_valeur.setValue(dossier.contre_valeur!);
    this.factureFormGroup.controls.poids_brut_total.setValue(dossier.poidsBrutTotal!);
    this.factureFormGroup.controls.numero_facture.setValue(dossier.numero_facture!);

    this.factureFormGroup.controls.incoterme_facture.setValue(dossier.incotermeFacture?.id!);
    this._incotermeService.getIncotermeProduits(dossier.incotermeFacture?.id!).subscribe(
      list => {
        this.incotermeProduits = list ?? [];
        this.list_incotermes = list;
        this.factureFormGroup.controls.incoterme_produit.setValue(dossier.incotermeProduit?.id!);
        this._changeRef.markForCheck();
      }
    );

    this.incotermFormGroup.controls.incoterme_facture.setValue(dossier.incotermeFacture?.libelle!);
    this.incotermFormGroup.controls.incoterme_produit.setValue(dossier.incotermeProduit?.libelle!);


    // init vendeur
    this.vendeurFormGroup.controls.nom.setValue(vendeur?.nom!);
    this.vendeurFormGroup.controls.email.setValue(vendeur?.email);
    this.vendeurFormGroup.controls.adresse.setValue(vendeur?.adresse);
    this.vendeurFormGroup.controls.telephone.setValue(vendeur?.telephone);
    this.vendeurFormGroup.controls.fax.setValue(vendeur?.fax);
    this.vendeurFormGroup.controls.pays.setValue(vendeur?.pays?.id!);
    this.vendeurFormGroup.controls.ville.setValue(vendeur?.ville);
    this.vendeurFormGroup.controls.id.setValue(vendeur?.id);

    console.log(this.vendeurFormGroup.value)

    this.dossierFormGroup.controls.codeifu.setValue(dossier.usager.numeroIfu!);
    this.searchUsager(dossier.usager.numeroIfu!)
    this._changeRef.markForCheck();

    this.verifierCodeifu();


    for (let i = 0; i < this.defaults.produits!.length; i++) {
      const p = this.defaults.produits![i];

      this.produitsList.push({
        "id": p.id,
        "libelle": p.libelle,
        "quantite": p.quantite,
        "unite_mesure": p.uniteMesure.id!,
        "poids_brut": p.poidsBrut,
        "poids_net": p.poidsNet,
        "prix_unitaire": p.prixUnitaire,
        "valeur_totale": p.valeurTotale,
        "valeur_fob": p.valeurFob,
        "pays_origine": p.paysOrigine.id!,
        "pays_provenence": p.paysProvenance.id!,
        "code_produit": p.codeProduit.id!,
        "posteEntree": p.posteEntree!,
        "contreValeur": p.contreValeur!,
        "dossier": this.dossierFormGroup.controls.id.value!
      })

    }
      console.log(this.dossierFormGroup.value)
  }


  searchUsager(numeroIfu: string) {
    // const numeroIfu = this.dossierFormGroup.controls.numeroIfu.value;
    console.log(numeroIfu);
    if (numeroIfu != '') {

      const usager = this.usagers.find(usg => usg.numeroIfu == numeroIfu);

      this.nomDemandeur = usager?.raisonSociale || '';
      this.adresseDemandeur = usager?.adresse || '';
    }

  }

  onSelectedIncotermeArticle(libelle: string) {
    const incoterme = this.incotermeFactures.find(inco => inco.libelle == libelle)!;
    this.factureFormGroup.controls.incoterme_facture.setValue(incoterme.id!);
  }

  onSelectedIncotermeProduit(libelle: string) {
    const incoterme = this.incotermeProduits.find(inco => inco.libelle == libelle)!;
    this.factureFormGroup.controls.incoterme_produit.setValue(incoterme.id!);
  }

  filterIncotermeProduit(value: string) {
    return this.incotermeProduits.filter(
      (state) => state.libelle!.toLowerCase().indexOf(value.toLowerCase()) === 0
    );
  }

  filterIncotermeFacture(value: string) {
    return this.incotermeFactures.filter(
      (state) => state.libelle!.toLowerCase().indexOf(value.toLowerCase()) === 0
    );
  }

  filterCodeIFU(value: string) {
    return this.usagers.filter(
      (state) => state.numeroIfu!.toLowerCase().indexOf(value.toLowerCase()) === 0
    );
  }

  searcheCodeTarif(){
    const code = this.codeProduitFormGroup.controls.code.value;

    this.dialog
      .open(SearchCodeTarifDialogComponent,{
        data: code,
        width: '800px',
        maxWidth: 900,
        disableClose: true
      })
      .afterClosed()
      .subscribe((produit) => {

        if(produit){
          this.codeProduitFormGroup.controls.code.setValue(produit.code);
          this.codeProduitFormGroup.controls.libelle.setValue(produit.parent +' : '+produit.libelle);
          this.produitFormGroup.controls.code_produit.setValue(produit.id!);

          this.produitFormGroup.controls.unite_mesure.setValue(produit?.uniteMesure?.id! );

          this.isValideProduit = true;
          this.disableProduitInput();
        }

      });
  }

  disableProduitInput(){
    if(!this.isValideProduit){
      this.produitFormGroup.controls.libelle.disable();
      this.produitFormGroup.controls.quantite.disable();
      this.produitFormGroup.controls.unite_mesure.disable();
      this.produitFormGroup.controls.poids_brut.disable();
      this.produitFormGroup.controls.prix_unitaire.disable();
      this.produitFormGroup.controls.valeur_totale.disable();
      this.produitFormGroup.controls.valeur_fob.disable();
      this.produitFormGroup.controls.contre_valeur.disable();
      this.produitFormGroup.controls.pays_origine.disable();
      this.produitFormGroup.controls.pays_provenence.disable();
      this.produitFormGroup.controls.posteEntree.disable();
    }else{
      this.produitFormGroup.controls.libelle.enable();
      this.produitFormGroup.controls.quantite.enable();
      this.produitFormGroup.controls.unite_mesure.enable();
      this.produitFormGroup.controls.poids_brut.enable();
      this.produitFormGroup.controls.prix_unitaire.enable();
      this.produitFormGroup.controls.valeur_totale.enable();
      this.produitFormGroup.controls.valeur_fob.enable();
      this.produitFormGroup.controls.contre_valeur.enable();
      this.produitFormGroup.controls.pays_origine.enable();
      this.produitFormGroup.controls.pays_provenence.enable();
      this.produitFormGroup.controls.posteEntree.enable();
    }
  }

  verification(listProduit: any []){
    let valeurFobTot = this.factureFormGroup.controls.montant_fob.value;
    let valeurfobsomme = 0;

    for (let index = 0; index < listProduit.length; index++) {
      const produit = listProduit[index];
      valeurfobsomme += produit.valeur_fob;
    }

    if(valeurfobsomme != valeurFobTot){
      this.fobIsValide = false;
      this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              // data: ,
              icon: 'warning',
              titre: 'ATTENTION: Vérifier les valeurs',
              textContent: "La valeur FOB total des produits ne correspond pas au montant fob de la facture"+ valeurFobTot +", Veillez vérifier les valeurs !"
            }
          })
            .afterClosed()
            .subscribe((value) => { });
    }else{

      this.fobIsValide = true;
    }
  }

}
