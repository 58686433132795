<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    Historiques
  </h2>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">

  <div class="invoice">
    <div class="title">
      <h2>HISTORIQUE DE TRAITEMENT</h2>
    </div>

    <table class="table-4col">
      <tr>
        <th>Traitement</th>
        <th>Traité par</th>
        <th>Nom traitant</th>
        <th>Date traitement</th>
      </tr>

      <tr *ngFor="let historique of historiquesList!">
        <td>{{ historique.statut! }}</td>
        <td>{{ historique.utilisateur?.email! }}</td>
        <td>{{ getNom(historique.utilisateur!) | uppercase}}</td>
        <td>{{ historique.dateCreate | date:'dd-MM-yyyy' }}</td>
      </tr>

      <tr *ngIf="historiquesList.length == 0">
        <th colspan="4" class="text-center">
          Aucun traitement
        </th>
      </tr>
    </table>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="secondary" mat-raised-button mat-dialog-close>
    ok
  </button>
</mat-dialog-actions>



