export const environment = {
  production: false,
  projectName: 'SYLVIE V2',
  projectSigle: 'SYLVIE V2',
  version: '0.1.1',
};

// All the backend endpoints
const host = 'http://195.35.48.198:9090';
// const host = 'http://localhost:8080';

export const API_ROOT = {
  login: `${host}/api/connexion`,
  logout: `${host}/api/deconnexion`,
  utilisateur_create: `${host}/api/inscription`,
  users: `${host}/api/utilisateur_list`,
  utilisateur_update: `${host}/api/utilisateur_update`,
  utilisateur_list_structure: `${host}/api/utilisateur_list_structure`,
  utilisateur_list_attribution: `${host}/api/utilisateur_attribution`,

  roles: `${host}/api/role_list`,
  pays_list: `${host}/api/pays_list`,

  structures: `${host}/api/structure_list`,
  structure_read: `${host}/api/structure_read`,
  structure_update: `${host}/api/structure_update`,
  structure_delete: `${host}/api/structure_delete`,
  structure_create: `${host}/api/structure_create`,
  typeStructure_list: `${host}/api/typeStructure_list`,

  banque_list: `${host}/api/banque_list`,
  assureur_list: `${host}/api/assureur_list`,

  transitaires_list: `${host}/api/transitaire_list`,
  transitaire_read: `${host}/api/transitaire_read`,
  transitaire_update: `${host}/api/transitaire_update`,
  transitaire_delete: `${host}/api/transitaire_delete`,
  transitaire_create: `${host}/api/transitaire_create`,

  typeClient_list: `${host}/api/typeClient_list`,
  typeClient_read: `${host}/api/typeClient_read`,
  typeClient_update: `${host}/api/typeClient_update`,
  typeClient_delete: `${host}/api/typeClient_delete`,
  typeClient_create: `${host}/api/typeClient_create`,

  dossiers_list: `${host}/api/dossier_list`,
  get_dossier: `${host}/api/get_dossier`,
  get_dossier_pole: `${host}/api/get_dossier_pole`,
  dossier_list_statut: `${host}/api/dossier_list_statut`,
  get_dossier_agent: `${host}/api/get_dossier_agent`,
  get_dossier_by_structure: `${host}/api/get_dossier_by_structure`,
  get_dossier_by_structure_with_pole: `${host}/api/get_dossier_by_structure_with_pole`,
  dossiers_read: `${host}/api/dossier_read`,
  dossiers_update: `${host}/api/dossier_update`,
  dossiers_delete: `${host}/api/dossier_delete`,
  dossiers_create: `${host}/api/dossier_create`,
  dossiers_list_statut: `${host}/api/dossier_list_statut`,
  creation_dossier_complet: `${host}/api/creation_dossier_complet`,
  fileDownload: `${host}/api/fileDownload`,
  update_dossier_complet: `${host}/api/update_dossier_complet`,
  soumission_paiement_dossier: `${host}/api/soumission_paiement_dossier`,
  traitement_dossier_pole: `${host}/api/traitement_dossier_pole/traitement_dossier_pole/dossiers`,
  traitement_dossier_pole_by_dossier: `${host}/api/traitement_dossier_pole/all/traitement_dossier_pole/by_dossier`,
  update_num_domiciliation: `${host}/api/update_num_domiciliation`,
  bydossierAndCodeActe: `${host}/api/impression/copie`,
  historique: `${host}/api/historique`,


  reception_validation_abnorm: `${host}/api/reception_validation_abnorm`,
  raDossier_create: `${host}/api/raDossier_create`,
  raDossier_update: `${host}/api/raDossier_update`,
  raDossier_get_ra: `${host}/api/raDossier_get_ra`,
  acteAi_get_ra: `${host}/api/acteAi_get_ra`,
  imprimer_ra: `${host}/api/imprimer_ra`,
  generation_ra: `${host}/api/generation_ra`,
  notification_modification: `${host}/api/notification_modification`,
  signature_ra: `${host}/api/signature_ra`,
  print_acte: `${host}/api/print_acte`,

  factures_dossiers_list: `${host}/api/factureDossier_list`,
  factures_dossiers_read: `${host}/api/factureDossier_read`,
  factures_dossiers_update: `${host}/api/factureDossier_update`,
  factures_dossiers_delete: `${host}/api/factureDossier_delete`,
  factures_dossiers_create: `${host}/api/factureDossier_create`,

  produits_factures_list: `${host}/api/produitFacture_list`,
  produits_factures_read: `${host}/api/produitFacture_read`,
  produits_factures_update: `${host}/api/produitFacture_update`,
  produits_factures_delete: `${host}/api/produitFacture_delete`,
  produits_factures_create: `${host}/api/produitFacture_create`,

  vendeur_list: `${host}/api/vendeur_list`,
  vendeur_read: `${host}/api/vendeur_read`,
  vendeur_update: `${host}/api/vendeur_update`,
  vendeur_delete: `${host}/api/vendeur_delete`,
  vendeur_create: `${host}/api/vendeur_create`,

  param_list: `${host}/api/param_list`,
  param_list_param: `${host}/api/param_list_param`,
  param_list_pole: `${host}/api/prmPole_list`,
  param_read: `${host}/api/param_read`,
  param_update: `${host}/api/param_update`,
  param_delete: `${host}/api/param_delete`,
  param_create: `${host}/api/param_create`,

  codeProduit_list: `${host}/api/codeProduit_list`,
  codeProduit_find_by_code_or_libelle: `${host}/api/codeProduit_find_by_code_or_libelle`,
  codeProduit_search: `${host}/api/codeProduit_search`,
  codeProduit_read: `${host}/api/codeProduit_read`,
  codeProduit_update: `${host}/api/codeProduit_update`,
  codeProduit_delete: `${host}/api/codeProduit_delete`,
  codeProduit_create: `${host}/api/codeProduit_create`,

  code_tarif_produit_list: `${host}/api/codeTarifProduit_list`,
  code_tarif_produit_read: `${host}/api/codeTarifProduit_read`,
  code_tarif_produit_update: `${host}/api/codeTarifProduit_update`,
  code_tarif_produit_delete: `${host}/api/codeTarifProduit_delete`,
  code_tarif_produit_create: `${host}/api/codeTarifProduit_create`,

  code_tarif_document_list: `${host}/api/codeTarifDocument_list`,
  code_tarif_document_read: `${host}/api/codeTarifDocument_read`,
  code_tarif_document_update: `${host}/api/codeTarifDocument_update`,
  code_tarif_document_delete: `${host}/api/codeTarifDocument_delete`,
  code_tarif_document_create: `${host}/api/codeTarifDocument_create`,

  devise_list: `${host}/api/devise_list`,
  devise_read: `${host}/api/devise_read`,
  devise_update: `${host}/api/devise_update`,
  devise_delete: `${host}/api/devise_delete`,
  devise_create: `${host}/api/devise_create`,

  intervenant_list: `${host}/api/intervenant_list`,
  intervenant_read: `${host}/api/intervenant_read`,
  intervenant_update: `${host}/api/intervenant_update`,
  intervenant_delete: `${host}/api/intervenant_delete`,
  intervenant_create: `${host}/api/intervenant_create`,

  usager_by_ifu: `${host}/api/usager_by_ifu`,
  usager_list: `${host}/api/usager_list`,
  usager_check: `${host}/api/usager_check`,

  incoterme_facture: `${host}/api/incoterme_facture`,
  incoterme_children: `${host}/api/incoterme_children`,

  acteAi_create: `${host}/api/acteAi_create`,
  acteAi_update: `${host}/api/acteAi_update`,

  acte_list:`${host}/api/acte_list`,
  acte_read:`${host}/api/acte_read`,
  acte_create:`${host}/api/acte_create`,
  acte_update:`${host}/api/acte_update`,
  acte_delete:`${host}/api/acte_delete`,

  abonne_list: `${host}/api/abonne_list`,
  abonne_list_page: `${host}/api/abonne_list_page`,
  abonne_create:`${host}/api/abonne_create`,
  abonne_update: `${host}/api/abonne_update`,
  abonne_read: `${host}/api/abonne_read`,
  abonne_delete: `${host}/api/abonne_delete`,
  abonne_statut_change: `${host}/api/abonne_statut_change`,
  abonne_by_codeifu: `${host}/api/abonne_by_codeifu`,

  pieceJointeDossier_list: `${host}/api/pieceJointeDossier_list`,
  pieceJointeDossier_read: `${host}/api/pieceJointeDossier_read`,
  pieceJointeDossier_update: `${host}/api/pieceJointeDossier_update`,
  pieceJointeDossier_create: `${host}/api/pieceJointeDossier_create`,
  pieceJointeDossier_delete: `${host}/api/pieceJointeDossier_delete`,

  download:`${host}/api/download?id=`,

  save_acte: `${host}/api/save_acte`,
  updaeVariablesCompleteTask: `${host}/api/updaeVariablesCompleteTask`,
  updateTaskVariables: `${host}/api/updateTaskVariables`,
  updateProcessVariables: `${host}/api/updateProcessVariables`,
  read_acte: `${host}/api/read_acte_code`,
  createProcess: `${host}/api/createProcess`,
  createProcessWithVars: `${host}/api/createProcessWithVars`,
  completeTask: `${host}/api/completeTask`,
  getcurrentTaskByProcessId: `${host}/api/getcurrentTaskByProcessId`,
  getTaskInfos: `${host}/api/getTaskInfos`,
  getProcessVariables: `${host}/api/getProcessVariables`,
  getProcessDiagram: `${host}/api/getProcessDiagram`,

  depot_list: `${host}/api/depot_list`,
  depot_dossier: `${host}/api/depot_dossier`,

  typePiece_list: `${host}/api/typePiece_list`,
  typePiece_read: `${host}/api/typePiece_read`,
  typePiece_create: `${host}/api/typePiece_create`,
  typePiece_update: `${host}/api/typePiece_update`,
  typePiece_delete: `${host}/api/typePiece_delete`,

  attributionSaisie_create: `${host}/api/attributionSaisie_create`,
  attributionSaisie_list: `${host}/api/attributionSaisie_list`,
  attributionSaisie_read: `${host}/api/attributionSaisie_read`,
  attributionSaisie_delete: `${host}/api/attributionSaisie_delete`,

  facture_list: `${host}/api/facture_list`,
  facture_list_abonne: `${host}/api/facture_list_abonne`,
  facture_update: `${host}/api/facture_update`,
  facture_read: `${host}/api/facture_update`,
  facture_delete: `${host}/api/facture_delete`,

  facture_non_abonne: `${host}/api/facture_non_abonne`,
  facture_non_abonne_statut : `${host}/api/facture_non_abonne_statut`,
  facture_abonne: `${host}/api/facture_abonne`,
  facture_abonne_statut: `${host}/api/facture_abonne_statut`,
  facture_abonnement: `${host}/api/facture_abonnement`,
  facture_abonnement_statut : `${host}/api/facture_abonnement_statut`,
  facture_read_dossier : `${host}/api/facture_read_dossier`,

  paiementFacture_create: `${host}/api/paiementFacture_create`,

  printFacture: `${host}/api/printFacture`,
  printRecu: `${host}/api/printRecu`,

  infoComplementaire_dossier_detail:`${host}/api/dossier_detail_info`,
  infoComplementaire_dossier_save:`${host}/api/saveInfoComplementaire`,
  infoComplementaire_dossier_read:`${host}/api/readInfoComplementaire`,
  prestation_list:`${host}/api/prestation_list`,
  prestation_create:`${host}/api/prestation_create`,
  statistiqueDashboard:`${host}/api/stats_info`,

  signatureElectronique_crate_signataire:`${host}/api/signataire/create`,
  signatureElectronique_signer_document_acte:`${host}/api/files_sign/documment/acte`,
  signatureElectronique_print_document_acte:`${host}/api/files_sign/download/`,
  signatureElectronique_list_signataire:`${host}/api/signataire/all/signataire`,
  signatureElectronique_dossier_act_info:`${host}/api/files_sign/dossier_act_info`,
  signatureElectronique_print_certificat:`${host}/api/certificat_signataire/download`,

  facture_mensuelle_abonne_liste_mois:`${host}/api/facture_mensuelle/generation_all_factures`,
  facture_mensuelle_abonne_liste:`${host}/api/facture_mensuelle/liste_all_factures`,
  facture_mensuelle_abonne_liste_by_mois_aboId:`${host}/api/facture_mensuelle/liste_factures`,
  facture_mensuelle_abonne_liste_by_date_intervalle:`${host}/api/facture_mensuelle/all_abo_factures_by_date`,
  facture_mensuelle_abonne_detail_abo_factures_by_date:`${host}/api/facture_mensuelle/detail_abo_factures_by_date`,
  facture_mensuelle_abonne_distinct_liste:`${host}/api/facture_mensuelle/liste_abonne`,
  facture_all_factures_abonnement_non_paye:`${host}/api/facture_mensuelle/all_factures_abonnement_non_paye`,
  facture_mensuelle_generation_all_factures_annee:`${host}/api/facture_mensuelle/generation_all_factures_annee`,

  impression_recheche_document:`${host}/api/impression/document_dossier/numeroSylvie/`,
  impression_telecharger_ficher:`${host}/api/impression/telecharger/fichier`,
  impression_telecharger_Duplicata:`${host}/api/pdfFiligrane/ajouterFiligranePdf`,
  impression_CountTotalPrint_ficher:`${host}/api/impression/count_nb/impression/fichier`,

  retrait_dossier_list_dossier_retirer:`${host}/api/retrait_dossier/list_retrait`,
  retrait_dossier_create_dossier_retirer:`${host}/api/retrait_dossier/create`,
  paiementMobileMoov:`${host}/api/paiement/moovmoney`,
  paiementMobileOrange:`${host}/api/paiement/orangemoney`,
  historisationTransation:`${host}/api/historisation/critetia`,
  historisationTransationByNatureReglement:`${host}/api/historisation/allTransactionByNatureReglement`,


  natureOperationCreate: `${host}/api/natureOperation_create`,
  reglementFinancierCreate: `${host}/api/modeReglement_create`,
  modeTransportCreate: `${host}/api/modeTransport_create`,
  modeExpeditionCreate: `${host}/api/modeExpedition_create`,
  centreRetraitCreate:`${host}/api/centreRetrait_create`,
  bureauDedouanementCreate:`${host}/api/bureauDedouanement_create`,
  typeAssuranceCreate:`${host}/api/typeAssurance_create`,
  typePoliceAbonnementCreate:`${host}/api/typePoliceAbonnement_create`,
  modeReglementCreate:`${host}/api/modeReglement_create`,
  paysCreate:`${host}/api/pays_create`,
  adresseCreate:``,
  uniteMesureCreate:`${host}/api/uniteMesure_create`,
  optionVeterinaireCreate:`${host}/api/optionVeterinaire_create`,
  typeDocumentCreate:``,
  typeFactureCreate:``,
  typeConformiteCreate:`${host}/api/typeConformite_create`,


  reglementFinancierUpdate: `${host}/api/modeReglement_update`,
  natureOperationUpdate: `${host}/api/natureOperation_update`,
  modeTransportUpdate: `${host}/api/modeTransport_update`,
  modeExpeditionUpdate: `${host}/api/modeExpedition_update`,
  centreRetraitUpdate:`${host}/api/centreRetrait_update`,
  bureauDedouanementUpdate:`${host}/api/bureauDedouanement_update`,
  typeAssuranceUpdate:`${host}/api/typeAssurance_update`,
  typePoliceAbonnementUpdate:`${host}/api/typePoliceAbonnement_update`,
  modeReglementUpdate:`${host}/api/modeReglement_update`,
  paysUpdate:`${host}/api/pays_update`,
  adresseUpdate:``,
  uniteMesureUpdate:`${host}/api/uniteMesure_update`,
  optionVeterinaireUpdate:`${host}/api/optionVeterinaire_update`,
  typeDocumentUpdate:``,
  typeFactureUpdate:``,
  typeConformiteUpdate:`${host}/api/typeConformite_update`,


};
