import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { CodeTarifProduit } from '../model/codeTarifProduit.model';

@Injectable({
  providedIn: 'root'
})
export class CodeTarifProduitService {

  // Private
  private _codeTarifProduit: ReplaySubject<CodeTarifProduit> = new ReplaySubject<CodeTarifProduit>(1);
  private _codeTarifProduits: ReplaySubject<CodeTarifProduit[] | null> = new ReplaySubject();

  private _url = API_ROOT.code_tarif_produit_list;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for codeTarifProduit
   */
  get codeTarifProduit$(): Observable<CodeTarifProduit> {
    return this._codeTarifProduit.asObservable();
  }

  set codeTarifProduit(codeTarifProduit: CodeTarifProduit) {
    this._codeTarifProduit.next(codeTarifProduit);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
     * Get users
     */
  getCodeTarifProduits(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<CodeTarifProduit[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const codeTarifProduits: CodeTarifProduit[] = response?.data || [];
          this._codeTarifProduits.next(codeTarifProduits);
          return of(codeTarifProduits);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

}
