import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { AbonneGuard } from './core/auth/guards/abonne.guard';
import { AdministrateurGuard } from './core/auth/guards/administrateur.guard';
import { AgentFacturationGuard } from './core/auth/guards/agent-facturation.guard';
import { AgentOpeartionGuard } from './core/auth/guards/agent-opeartion.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { DepotRetraitGuard } from './core/auth/guards/depot-retrait.guard';
import { SignateurGuard } from './core/auth/guards/signateur.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AgentGuard } from './core/auth/guards/agent.guard';
import { EnumDossierStatut } from './model/dossier.model';
import { adminRoutes } from './shared/ModuleRoutes/admin.routes';
import { abonneRoutes } from './shared/ModuleRoutes/abonne.routes';
import { agentRoutes } from './shared/ModuleRoutes/agent.routes';
import { agentOperationRoutes } from './shared/ModuleRoutes/agentOperation.routes';
import { agentFacturationRoutes } from './shared/ModuleRoutes/agent-facturation.routes';
import { depotRetraitRoutes } from './shared/ModuleRoutes/depot-retrait.routes';
import { signataireRoutes } from './shared/ModuleRoutes/signateur.routes';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then((m) => m.LoginComponent)
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./pages/auth/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent
      )
  },
  {
    path: 'error-404',
    loadComponent: () =>
      import('./pages/errors/error-404/error-404.component').then(
        (m) => m.Error404Component
      )
  },
  {
    path: 'error-500',
    loadComponent: () =>
      import('./pages/errors/error-500/error-500.component').then(
        (m) => m.Error500Component
      )
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      adminRoutes,
      abonneRoutes,
      signataireRoutes,
      agentRoutes,
      agentOperationRoutes,
      agentFacturationRoutes,
      depotRetraitRoutes,
      {
        path: 'profil',
        loadChildren: () => import('./pages/social/social.routes')
      },
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }
    ]
  }
];
