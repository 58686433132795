import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { EnumRole } from "src/app/model/role.entity";
import { AuthService } from "../auth.service";



@Injectable({
  providedIn: 'root'
})
export class SignateurGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const userRole:any = this.authService.role.libelle;

    if (  userRole === EnumRole.signateur ) {


      return true;
    } else {

      
     this.router.navigate(['/unauthorized']);
      return false; // Bloquer l'accès à la route
    }
  }
}
