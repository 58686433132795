import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { Incoterme } from '../model/incotermee.model';

@Injectable({
  providedIn: 'root'
})
export class IncotermeService {

  // Private
  private _incotermeFactures: ReplaySubject<Incoterme[] | null> = new ReplaySubject();
  private _incotermeProduits: ReplaySubject<Incoterme[] | null> = new ReplaySubject();

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Get Incoterme factures
     */
  getIncotermeFactures(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Incoterme[]>>(`${API_ROOT.incoterme_facture}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const incotermes: Incoterme[] = response?.data || [];
          this._incotermeFactures.next(incotermes);
          return of(incotermes);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
     * Get Incoterme factures
     */
  getIncotermeProduits(idIncotermeFacture: number): Observable<any> {
    return this._httpClient
      .post<ResponseModel<Incoterme[]>>(`${API_ROOT.incoterme_children}`, { id: idIncotermeFacture})
      .pipe(
        switchMap((response) => {
          const incotermes: Incoterme[] = response?.data || [];
          this._incotermeProduits.next(incotermes);
          return of(incotermes);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }
}
