import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { EnumRole, RoleEntity } from 'src/app/model/role.entity';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  menuAdmin: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Adminitration',
      children: [
        {
          type: 'link',
          label: 'Gestion des utilisateurs',
          route: '/admin/gestion-utilisateur',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Gestion des structures',
          route: '/admin/gestion-structure',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Gestion des abonnes',
          route: '/admin/gestion-abonne',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Gestion des transitaires',
          route: '/admin/gestion-transitaire',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Gestion des devises',
          route: '/admin/gestion-devise',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Gestion des Signataires',
          route: '/admin/gestion-signataire',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion depôts-retraits',
      children: [
        {
          type: 'link',
          label: 'Depôts',
          route: '/admin/depot',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Affectations',
          route: '/admin/affectation',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: "Dossier en attente retrait",
          route: '/admin/retrait',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: ' Dossiers  Rétiré',
          route: '/admin/list-des-retrait',
          icon: 'mat:assignment'
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Facturation non abonnes',
      children: [
        {
          type: 'link',
          label: 'Liste des factures',
          route: '/admin/facturation',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures en attente',
          route: '/admin/facture-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures payées',
          route: '/admin/facture-paye',
          icon: 'mat:assignment'
        }
        ,
        {
          type: 'link',
          label: 'Consultation Transaction  ',
          route: '/admin/historique-transaction',
          icon: 'mat:assignment'
        },

      ]
    },
    {
      type: 'subheading',
      label: 'Facturation abonnes',
      children: [
        {
          type: 'link',
          label: 'Liste des factures',
          route: '/admin/facturation-abonne',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures en attente',
          route: '/admin/facture-abonne-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures payées',
          route: '/admin/facture-abonne-paye',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures mensuelle',
          route: '/admin/facture-mensuelle',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des dossiers',
      children: [
        {
          type: 'link',
          label: 'Liste des dossiers',
          route: '/admin/gestion-dossier',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à traiter',
          route: '/admin/gestion-dossier-en-cours',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en attente',
          route: '/admin/gestion-dossier-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à modifier',
          route: '/admin/gestion-dossier-modification',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers annulés',
          route: '/admin/gestion-dossier-annule',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers rejétés',
          route: '/admin/gestion-dossier-rejete',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en validation',
          route: '/admin/gestion-dossier-enregistre',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en signatures',
          route: '/admin/gestion-dossier-valide',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers validés',
          route: '/admin/gestion-dossier-termine',
          icon: 'mat:assignment'
        }
      ]
    }
    ,
    {
      type: 'subheading',
      label: 'Gestion des Impressions',
      children: [
        {
          type: 'link',
          label: 'Impressions',
          route: '/admin/gestion-impression',
          icon: 'mat:print'
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des notifications',
      children: [
        {
          type: 'link',
          label: 'Notifications',
          route: '/apps/mail',
          icon: 'mat:mail'
        }
      ]
    }
  ]

  menuAgent: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '/',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des dossiers',
      children: [
        {
          type: 'link',
          label: 'Liste des dossiers',
          route: '/agent/gestion-dossier',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à traiter',
          route: '/agent/gestion-dossier-en-cours',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à modifier',
          route: '/agent/gestion-dossier-modification',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers annulés',
          route: '/agent/gestion-dossier-annule',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers rejétés',
          route: '/agent/gestion-dossier-rejete',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en validation',
          route: '/agent/gestion-dossier-enregistre',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en signatures',
          route: '/agent/gestion-dossier-valide',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers validés',
          route: '/agent/gestion-dossier-termine',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des notifications',
      children: [
        {
          type: 'link',
          label: 'Notifications',
          route: '/apps/mail',
          icon: 'mat:mail'
        }
      ]
    }
  ]

  menuAgentOpeartion: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '/',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Mes Dossiers',
      children: [
        {
          type: 'link',
          label: 'En attente',
          route: '/agent_operation/gestion-dossier-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'En cours',
          route: '/agent_operation/gestion-dossier-en-cours',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Modifier',
          route: '/agent_operation/gestion-dossier-modification',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Annulés',
          route: '/agent_operation/gestion-dossier-annule',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Rejétés',
          route: '/agent_operation/gestion-dossier-rejete',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'En validation',
          route: '/agent_operation/gestion-dossier-enregistre',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'En signatures',
          route: '/agent_operation/gestion-dossier-valide',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Traités',
          route: '/agent_operation/gestion-dossier-termine',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des notifications',
      children: [
        {
          type: 'link',
          label: 'Notifications',
          route: '/apps/mail',
          icon: 'mat:mail'
        }
      ]
    }
  ]

  menuAgentSignateur: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '/',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des dossiers',
      children: [
        {
          type: 'link',
          label: 'Liste des dossiers',
          route: '/signataire/gestion-dossier',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à traiter',
          route: '/signataire/gestion-dossier-en-cours',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à modifier',
          route: '/signataire/gestion-dossier-modification',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers annulés',
          route: '/signataire/gestion-dossier-annule',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers rejétés',
          route: '/signataire/gestion-dossier-rejete',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en validation',
          route: '/signataire/gestion-dossier-enregistre',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en signatures',
          route: '/signataire/gestion-dossier-valide',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers validés',
          route: '/signataire/gestion-dossier-termine',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des notifications',
      children: [
        {
          type: 'link',
          label: 'Notifications',
          route: '/apps/mail',
          icon: 'mat:mail'
        }
      ]
    }
  ]

  menuAgentFacturation: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '/',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Facturation non abonnes',
      children: [
        {
          type: 'link',
          label: 'Liste des factures',
          route: '/agent_facturation/facturation',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures en cours',
          route: '/agent_facturation/facture-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures payées',
          route: '/agent_facturation/facture-paye',
          icon: 'mat:assignment'
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Facturation abonnees',
      children: [
        {
          type: 'link',
          label: 'Liste des factures',
          route: '/agent_facturation/facturation-abonne',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures en cours',
          route: '/agent_facturation/facture-abonne-en-attente',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures payées',
          route: '/agent_facturation/facture-abonne-paye',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Factures mensuelle',
          route: '/agent_facturation/facture-mensuelle',
          icon: 'mat:assignment'
        },
      ]
    }
  ]

  menuAgentDeposRetrait: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Tableaux de bord',
      children: [
        {
          type: 'link',
          label: 'Tableau',
          route: '/',
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        }
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion depôts-retraits',
      children: [
        {
          type: 'link',
          label: 'Depôts',
          route: '/agent_depot_retrait/depot',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Affectations',
          route: '/agent_depot_retrait/affectation',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Retraits',
          route: '/agent_depot_retrait/retrait',
          icon: 'mat:assignment'
        }
      ]
    },
  ]

  menuAbonne: NavigationItem[] = [
    {
      type: 'subheading',
      label: 'Gestion des dossiers',
      children: [
        {
          type: 'link',
          label: 'Liste des dossiers',
          route: '/abonne/gestion-dossier',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à traiter',
          route: '/abonne/gestion-dossier-en-cours',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers à modifier',
          route: '/abonne/gestion-dossier-modification',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers annulés',
          route: '/abonne/gestion-dossier-annule',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers rejétés',
          route: '/abonne/gestion-dossier-rejete',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en validation',
          route: '/abonne/gestion-dossier-enregistre',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers en signatures',
          route: '/abonne/gestion-dossier-valide',
          icon: 'mat:assignment'
        },
        {
          type: 'link',
          label: 'Dossiers validés',
          route: '/abonne/gestion-dossier-termine',
          icon: 'mat:assignment'
        },
      ]
    },
    {
      type: 'subheading',
      label: 'Gestion des notifications',
      children: [
        {
          type: 'link',
          label: 'Notifications',
          route: '/apps/mail',
          icon: 'mat:mail'
        }
      ]
    }
  ]

  constructor(
    private readonly layoutService: VexLayoutService,
    private _authService: AuthService
  ) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    // this._items.next(this.menuAdmin);
    const userRole: RoleEntity = this._authService.role;
    console.log(userRole);

    switch (userRole?.libelle ? userRole?.libelle : '') {
      case EnumRole.administarateur:
     let newMenu=[];
      this.menuAdmin.forEach(element => {
          if (element) {

          }
      });
        this._items.next(this.menuAdmin);
        break;

      case EnumRole.abonne:
        this._items.next(this.menuAbonne);
        break;

      case EnumRole.agent:
        this._items.next(this.menuAgent);
        break;

      case EnumRole.signateur:
        this._items.next(this.menuAgentSignateur);
        break;

      case EnumRole.facturation:
        this._items.next(this.menuAgentFacturation);
        break;

      case EnumRole.depot_retrait:
        this._items.next(this.menuAgentDeposRetrait);
        break;

      case EnumRole.agent_operation:
        this._items.next(this.menuAgentOpeartion);
        break;

      default: this._items.next(this.menuAdmin);
        break;
    }
  }
}
