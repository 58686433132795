import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgIf } from '@angular/common';
import { StructurePartenaire, TypeStructure } from 'src/app/model/structurePartenaire.model';
import { StructurePartenaireService } from '../../../services/structure-partenaire.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'vex-structure-create-update',
  templateUrl: './structure-create-update.component.html',
  styleUrls: ['./structure-create-update.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    CommonModule,
    NgIf,
    MatSnackBarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule

  ]
})
export class StructureCreateUpdateComponent implements OnInit {

  typeStructureList: TypeStructure[] = [];

  static id = 100;
  form = this.fb.group({
    id: [StructureCreateUpdateComponent.id++],
    raisonSociale: [this.defaults?.raisonSociale || ''],
    email: [this.defaults?.email || ''],
    telephone: this.defaults?.telephone || '',
    typeStructure: this.defaults?.typeStructure?.libelle || '',
  });

  mode: 'create' | 'update' = 'create';

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: StructurePartenaire | undefined,
    private dialogRef: MatDialogRef<StructureCreateUpdateComponent>,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private _StructureService: StructurePartenaireService,
  ) {

    _StructureService.getTypeStructures().subscribe(
      res => {
        this.typeStructureList = res;
      }
    )
  }



  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as StructurePartenaire;
    }

    this.form.controls.id.setValue(this.defaults?.id!);
    this.form.controls.email.setValue(this.defaults?.email!);
    this.form.controls.raisonSociale.setValue(this.defaults?.raisonSociale!);
    this.form.controls.telephone.setValue(this.defaults?.telephone!);
    this.form.controls.typeStructure.setValue(this.defaults?.typeStructure?.libelle!);
  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const form = this.form.getRawValue();
    this. _StructureService.create(form).subscribe(
      responce => {
        const newStructure: StructurePartenaire = new StructurePartenaire(responce);
        console.log(newStructure);
        this.snackbar.open(
          "Eregistrement effectue avec succes",
          'OK',
          {
            verticalPosition: 'top',
            duration: 10000
          },

        );
        this.ref.detectChanges();
      }
    )
    this.dialogRef.close();
  }
  updateCustomer() {
    const form = this.form.getRawValue();
    this. _StructureService.update(form).subscribe(
      responce => {
        const newStructure: StructurePartenaire = new StructurePartenaire(responce);
        console.log(newStructure);
        this.snackbar.open(
          "Modification effectue avec succes",
          'OK',
          {
            verticalPosition: 'top',
            duration: 10000
          },
        );
        this.ref.detectChanges();
      }
    )
    this.dialogRef.close();
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
