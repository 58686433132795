
export class RoleEntity {
  public id?: number;
  public libelle?: EnumRole;
  public description?: string;

  constructor(customer: RoleEntity) {
    this.id = customer.id;
    this.libelle = customer.libelle;
    this.description = customer.description;
  }
}

export enum EnumRole {
  administarateur = 'ADMINISTRATEUR',
  abonne = 'ABONNE',
  agent = 'AGENT',
  signateur = 'SIGNATAIRE',
  depot_retrait = 'AGENT DEPOT RETRAIT',
  facturation = 'AGENT FACTURATION',
  agent_operation = 'AGENT OPERATION'
}
