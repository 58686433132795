import { ParametreValeur } from './parametreValeur.model';
import { Transitaire } from './transitaire.model';
import { Intervenant } from './intervenant.model';
import { UserEntity } from './user.entity';
import { Facture } from './facture.model';
import { ProduitFacture } from './produitFacture.model';
import { Devise } from './devise.model';
import { TraitementDossierPoles } from './traitementDossierPoles.model';

export class Dossier {
  public id?: number;
  public numero_sylvie?: number;
  public nature_operation?: ParametreValeur;
  public codeifu?: string;
  public transitaire?: Transitaire;
  public reglement_financier?: ParametreValeur;
  public mode_transport?: ParametreValeur;
  public mode_expedition?: ParametreValeur;
  public lieu_embarquement?: string;
  public lieu_debarquement?: string;
  public centre_retrait?: ParametreValeur;
  public bureau_dedoinement?: ParametreValeur;
  public banque_domiciliation?: Intervenant;
  public date_arrivee_prevu?: Date;
  public type_assurance?: ParametreValeur;
  public compagnie_assurance?: Intervenant;
  public type_police_abonnement?: ParametreValeur;
  public numero_police_abonnement?: string;
  public utilisateur?: UserEntity;
  public statut?: EnumDossierStatut;
  public statutPole?:EnumDossierStatut;
  public devise?: Devise;
  public montant_total?: number;
  public valeur_fob_cfa?: number;
  public mode_reglement?: ParametreValeur;
  public pays_origine?: ParametreValeur;
  public pays_destination?: ParametreValeur;
  public pays_provenence?: ParametreValeur;
  public valeur_sortie_usine?: number;
  public montant_fob?: number;
  public montant_fret?: number;
  public montant_assurance?: number;
  public montant_autres?: number;
  public nature_autre_couts?: string;
  public remise_sur_facture?: number;
  public poids_net_total?: number;
  public poids_brut_total?: number;
  public numero_compte? : string;
  public montant_total_devise? : string;
  public type_facture? : ParametreValeur;
  public numeroFacture? : string;
  public nomDeposant? : string;
  public prenomDeposant? : string;
  public telephoneDeposant? : string;
  public raisonSocial? : string;
  public nature_operation_libelle:string;
  public dateDepot? : Date;
  public dateSoumission? : Date;
  public traitementDossierPolesx?:TraitementDossierPoles;
  public mode_transport_libelle ?:string;
  public numeroDomiciliation? :string;

  constructor(customer?: any) {
    this.id = customer.dossier.id ?? null;
    this.numero_sylvie = customer.dossier.numero_sylvie ?? null;
    this.numeroFacture = customer.dossier.numeroFacture ?? null;
    this.nature_operation = customer.dossier.nature_operation ?? null;

    this.codeifu = customer.dossier.usager.numeroIfu ?? null;
    this.transitaire = customer.dossier.transitaire ?? null;
    this.reglement_financier = customer.dossier.reglement_financier ?? null;
    this.mode_transport = customer.dossier.mode_transport ?? null;
    this.mode_expedition = customer.dossier.mode_expedition ?? null;
    this.lieu_embarquement = customer.dossier.lieu_embarquement ?? null;
    this.lieu_debarquement = customer.dossier.lieu_debarquement ?? null;
    this.centre_retrait = customer.dossier.centre_retrait ?? null;
    this.bureau_dedoinement = customer.dossier.bureau_dedoinement ?? null;
    this.banque_domiciliation = customer.dossier.banque_domiciliation ?? null;
    this.date_arrivee_prevu = customer.dossier.date_arrivee_prevu ?? null;
    this.type_assurance = customer.dossier.type_assurance ?? null;
    this.compagnie_assurance = customer.dossier.compagnie_assurance ?? null;
    this.type_police_abonnement = customer.dossier.type_police_abonnement ?? null;
    this.numero_police_abonnement = customer.dossier.numero_police_abonnement ?? null;
    this.utilisateur = customer.dossier.utilisateur ?? null;
    this.statut = customer.dossier.statut ?? null;

    this.devise = customer.dossier.devise ?? null;
    this.montant_total = customer.dossier.montantTotal ?? null;
    this.valeur_fob_cfa = customer.dossier.valeurFobCfa ?? null;
    this.mode_reglement = customer.dossier.modeReglement ?? null;
    this.pays_origine = customer.dossier.paysOrigine ?? null;
    this.pays_destination = customer.dossier.paysDestination ?? null;
    this.pays_provenence = customer.dossier.paysProvenence ?? null;
    this.valeur_sortie_usine = customer.dossier.valeurSortieUsine ?? null;
    this.montant_fob = customer.dossier.montantFob ?? null;
    this.montant_fret = customer.dossier.montantFret ?? null;
    this.montant_assurance = customer.dossier.montantAssurance ?? null;
    this.montant_autres = customer.dossier.montantAutres ?? null;
    this.nature_autre_couts = customer.dossier.natureAutreCouts ?? null;
    this.remise_sur_facture = customer.dossier.remiseSurFacture ?? null;
    this.poids_net_total = customer.dossier.poidsNetTotal ?? null;
    this.poids_brut_total = customer.dossier.poidsBrutTotal ?? null;

    this.numero_compte = customer.dossier.numeroCompte ?? null;

    this.montant_total_devise = customer.dossier.montantTotalDevise ?? null;
    this.type_facture = customer.dossier.typeFacture ?? null;
    this.nomDeposant = customer.dossier.nomDeposant ?? null;
    this.prenomDeposant = customer.dossier.prenomDeposant ?? null;
    this.telephoneDeposant = customer.dossier.telephoneDeposant ?? null;
    this.dateDepot = customer.dossier.dateDepot ?? null;
    this.dateSoumission = customer.dossier.dateSoumission ?? null;
    this.traitementDossierPolesx = customer?.traitementDossierPoles ?? null;
    this.raisonSocial = customer.dossier.utilisateur.structure.raisonSociale
  //  this.traitement=traitement;

  this.nature_operation_libelle=customer?.dossier?.natureOperation.libelle ;
  this.mode_transport_libelle = customer?.dossier?.modeTransport.libelle;
  this.statutPole=customer?.traitementDossierPoles?.responsePole,
  this.numeroDomiciliation = customer.numeroDomiciliation
  }
}

export enum EnumDossierStatut {
  ALL = 'ALL',
  EN_ATTENTE = 'EN_ATTENTE',
  EN_COURS = 'EN_COURS',
  TERMINE = 'TERMINE',
  EN_TRAITEMENT = 'EN_TRAITEMENT',
  REJET = 'REJET',
  ANNULLE = 'ANNULLE',
  MODIFICATION = 'MODIFICATION',
  EN_ATTENTE_D_ATTRIBUTION = 'EN_ATTENTE_D_ATTRIBUTION',
  EN_ATTENTE_VALIDATION_POLE = 'EN_ATTENTE_VALIDATION_POLE',
  ENREGISTRE = 'ENREGISTRE',
  VALIDE = 'VALIDE'
}

export enum EnumAct {
  RA = 'RA',
  AI_DGSV = 'AI DGSV',
  AI = 'AI',
  VI = 'VI',
  DPI = 'DPI',
  DPI_sante = 'DPI_sante',
  CAF = 'CAF',
  CCPQ = 'CCPQ',
  AC = 'AC',
  AC_single = 'AC_single',
  CNC = 'CNC',
  CECO = 'CECO',
  EC = 'EC',
  TE = 'TE',
  TE_single = 'TE_single'
}

export enum EnumFilsType{
  FACTURE = 'FACTURE',
  ACTE = 'ACTE'
}

export class DetailDossier {
  dossier: any;
  facture: any;
  produits: any;
  devis: any;
  usager: any;
  etapes: any;
  vendeur: any;
}

