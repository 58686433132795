import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import { EnumRole } from 'src/app/model/role.entity';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdministrateurGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const userRole:any = this.authService.role.libelle;

    if (userRole === EnumRole.administarateur ) {

      return true;
    } else {

     this.router.navigate(['/unauthorized']);
      return false; // Bloquer l'accès à la route
    }
  }
}



/*
export enum EnumRole {
  administarateur = 'ADMINISTRATEUR',
  abonne = 'ABONNE',
  agent = 'AGENT',
  signateur = 'SIGNATAIRE',
  depot_retrait = 'AGENT DEPOT RETRAIT',
  }
*/
