import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, concatMap, of } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { SharedServiceService } from './shared-service.service';
import { API_ROOT } from 'src/environments/environment';
@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  showPortfolio: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    public sharedService: SharedServiceService,
    private _authService: AuthService,
    private router: Router
  ) {}

  public isIntrcepte: boolean = false;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    //Intercepeter et ajouter le Header a toutes les requette
    let token = this._authService.accessToken;

    // console.log(req.url);
    let newReq: HttpRequest<any> = req.clone();
    if(req.url != API_ROOT.pieceJointeDossier_create || req.url != API_ROOT.signatureElectronique_crate_signataire || req.url != API_ROOT.signatureElectronique_signer_document_acte || req.url != API_ROOT.signatureElectronique_print_document_acte || req.url != "https://196.28.245.227/tlcfzc_gw/api/gateway/3pp/transaction/process"){
     // console.log('intersect request')
      newReq = req.clone({
        headers: req.headers
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
      });


    }

    if(
      req.url == API_ROOT.signatureElectronique_crate_signataire
      || req.url == API_ROOT.signatureElectronique_signer_document_acte
      || req.url == API_ROOT.pieceJointeDossier_create  || req.url == API_ROOT.signatureElectronique_print_document_acte
      ){
       newReq = req.clone({
         headers: req.headers
       //    .set('Accept', 'multipart/form-data')
          // .set('Content-Type', 'multipart/form-data; ')
       });

             console.log('intersect request',newReq)

  }






    this.isTokenValid(token);
     if (req.method === 'POST' || req.method === 'PUT') {
      this.showSpinner(1000);
    }
    if (req.method === 'GET') {
      this.showSpinner(2000);
    }

    return next.handle(newReq).pipe(
      concatMap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Cacher le spinner après la réception de la réponse
          this.hideSpinner();
        }
        return of(event);
      })
    );
  }

  showSpinner(time: number): void {
    this.sharedService.showSpinner();
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, time);
  }

  hideSpinner(): void {
    this.spinner.hide();
    this.sharedService.hideSpinner();
  }

  isTokenValid(token: string): boolean {
    if (token) {
      try {
        // Décoder le token
        const decodedToken = jwtDecode(token);

        // Vérifier la date d'expiration
        const valueToken: any = decodedToken.exp;
        const expirationDate = valueToken * 1000; // Convertir en millisecondes
        const isTokenExpired = Date.now() > expirationDate;

        if (isTokenExpired) {
          // Déconnecter l'utilisateur si le token est expiré
          this.logout();
          return false;
        }

        return true;
      } catch (error) {
        console.error('Erreur lors du décodage du token :', error);
        // Déconnecter l'utilisateur en cas d'erreur de décodage du token
        this.logout();
        return false;
      }
    }

    // Déconnecter l'utilisateur s'il n'y a pas de token
    this.logout();
    return false;
  }

  // Méthode pour déconnecter l'utilisateur
  logout() {
    this._authService.signOut();
    this.router.navigate(['/login']);
  }
}
