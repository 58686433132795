<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <!-- <img *ngIf="user?.imageSrc" [src]="user?.imageSrc" class="avatar ltr:mr-5 rtl:ml-5" /> -->
    <h2 class="headline m-0 flex-auto">
      {{ form.controls.nom.value || "Ajouter un utilisateur" }}
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">

    <mat-form-field class="flex-auto">
      <mat-label>Type Utilisateur</mat-label>
      <mat-select [formControl]="form.controls.role" placeholder="Selectionner&hellip;">
        <mat-option value="">Selectionner...</mat-option>
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{role.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mt-6">
      <mat-label>Nom</mat-label>
      <input cdkFocusInitial formControlName="nom" matInput />

      <mat-icon matIconPrefix svgIcon="mat:person"></mat-icon>
    </mat-form-field>

    <mat-form-field class="mt-6">
      <mat-label>Prenom(s)</mat-label>
      <input cdkFocusInitial formControlName="prenom" matInput />

      <mat-icon matIconPrefix svgIcon="mat:person"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-Mail</mat-label>
      <input formControlName="email" matInput />

      <mat-icon matIconPrefix svgIcon="mat:email"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mot de passe</mat-label>
      <input formControlName="mdp" matInput />
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Structure</mat-label>
      <mat-select [formControl]="form.controls.structure" placeholder="Selectionner&hellip;">
        <mat-option value="">Selectionner...</mat-option>
        <mat-option *ngFor="let struct of structures" [value]="struct.id">
          {{struct.raisonSociale }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Fermer</button>
    <button color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
      <ng-container *ngIf="isEdit">Modifier</ng-container>
      <ng-container *ngIf="!isEdit">Ajouter</ng-container>
    </button>
  </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimer</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exporter</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
