
 <!--
   <ngx-spinner   *ngIf="sharedService.isSharedSpinner$ | async" bdColor="rgba(7,1,1,0.95)" size="small" type="pacman">
    <p style="color: white">Chargement..Sylvie.</p>
  </ngx-spinner>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
 
 -->
 

 <ngx-spinner
 bdColor="rgba(51,51,51,0.8)"
 size="medium"
 color="#fff"
 type="ball-scale-multiple"
>
<div class="justify-center">

 <!--

 <div style=" color: white" class="la-ball-clip-rotate-multiple la-3x">
    <div></div>
    <div></div>
  </div>
  
  --> 
  <div class="la-ball-newton-cradle la-2x">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>


 
  
</div>

</ngx-spinner>