import { Injectable } from '@angular/core';
import { log } from 'console';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  private isSharedSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSharedSpinner$: Observable<boolean> = this.isSharedSpinnerSubject.asObservable();


  constructor() { }

  public showSpinner(): void {
    this.isSharedSpinnerSubject.next(true);
 
  }

  public hideSpinner(): void {
    this.isSharedSpinnerSubject.next(false);

  }

}
