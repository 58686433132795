export class StructurePartenaire {
  public id?: number;
  public raisonSociale?: string;
  public typeStructure?: TypeStructure;
  public email?: string;
  public telephone?: string;

  constructor(customer: StructurePartenaire) {
    this.id = customer.id;
    this.raisonSociale = customer.raisonSociale;
    this.typeStructure = customer.typeStructure;
    this.email = customer.email;
    this.telephone = customer.telephone;
  }
}

export class TypeStructure {
  id?: number;
  libelle?: string;

  constructor(customer: TypeStructure) {
    this.id = customer.id;
    this.libelle = customer.libelle;
  }
}

