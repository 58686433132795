import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { RoleEntity } from '../model/role.entity';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  // Private
  private _role: ReplaySubject<RoleEntity> = new ReplaySubject<RoleEntity>(1);
  private _roles: ReplaySubject<RoleEntity[] | null> = new ReplaySubject();

  private _url = API_ROOT.roles;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for role
   */
  get role$(): Observable<RoleEntity> {
    return this._role.asObservable();
  }

  set role(role: RoleEntity) {
    this._role.next(role);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  get(): Observable<RoleEntity> {
    return of(JSON.parse(localStorage.getItem('role')!));
  }

  /**
     * Get users
     */
  getRoles(): Observable<any[]> {
    return this._httpClient
      .get<ResponseModel<RoleEntity[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const roles: RoleEntity[] = response?.data || [];
          this._roles.next(roles);
          return of(roles);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

}
