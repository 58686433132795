import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { Observable, Subject, isEmpty, map, takeUntil } from 'rxjs';
import { GeneralImportModuleModule } from 'src/app/shared/general-import-module/general-import-module.module';
import { UtilsFunctionSharedService } from '../service/utils-function-shared.service';

@Component({
  selector: 'vex-pdf-viewer',
  standalone: true,

  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  animations: [fadeInUp400ms, stagger40ms],
  imports: [GeneralImportModuleModule]
})
export class PdfViewerComponent implements OnInit {

  pdfSrc: string = '';
  isBlobFile: boolean = false;

  constructor(
    private http: HttpClient,
    private sharedService: UtilsFunctionSharedService,
    @Inject(MAT_DIALOG_DATA) public urlfile: any
  ) {

    if(urlfile.fileBlob?.type != undefined){
      this.isBlobFile = true;
      const fileReader = new FileReader();
        fileReader.onload = () => {
          this.final_reporte_src = new Uint8Array(
            fileReader.result as ArrayBuffer
          );
        };
        fileReader.readAsArrayBuffer(urlfile.fileBlob!);
    }else{
      this.isBlobFile = false;
      this.pdfSrc = urlfile;
      this.downloadFile(urlfile);
    }

  }



  ngOnInit(): void {

  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  final_reporte_src!: any;
  printModal = false;
  reportprintModal = true;

  //  Fonction de Lecture des fichiers PDF
  public downloadFile(url: string): any {
    console.log(url);
    this.getFile(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this.final_reporte_src = new Uint8Array(
              fileReader.result as ArrayBuffer
            );
          };
          fileReader.readAsArrayBuffer(res);
        }
      });
  }

  public getFile(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((result: any) => {
        console.log('File result ', result);

        return result;
      })
    );
  }

  telecharger(){
    this.sharedService.telechargerFicher(this.urlfile.filepath!);
  }

}
