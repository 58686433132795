import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'customDatePipe'
})
export class CustomDatePipePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const datePipe = new DatePipe("en-US");
    value = <string>datePipe.transform(value, 'dd-MMM-yyyy');
    return value;
  }

}
