import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SpinnersInterceptorsComponent } from "./config/spinners-interceptors/spinners-interceptors.component";

@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet, SpinnersInterceptorsComponent],
})
export class AppComponent { }
