import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { API_ROOT } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { UserEntity } from 'src/app/model/user.entity';
import { ResponseModel } from 'src/app/model/response.model';
import { AuthDataModel } from 'src/app/model/auth-data.model';
import { RoleEntity } from 'src/app/model/role.entity';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private _loginUrl = API_ROOT.login;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get user(): UserEntity {
    return JSON.parse(localStorage.getItem('user')!) ?? null;
  }

  set user(user: UserEntity) {
    localStorage.setItem('user', JSON.stringify(user));
    this.role = user.role!;
  }

  set role(role: RoleEntity) {
    localStorage.setItem('role', JSON.stringify(role));
  }

  get role(): RoleEntity {
    return JSON.parse(localStorage.getItem('role')!) ?? null;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { username: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('Utilisateur deja connecte');
    }

    return this._httpClient.post<AuthDataModel>(`${this._loginUrl}`, credentials, { 'headers': this.headers }).pipe(
      switchMap((response) => {
        console.log(response)
        if (response.success) {// Store the access token in the local storage
          this.accessToken = response.token || '';

          const us = cloneDeep(response.utilisateur);
          this.user = us!;
          // Set the authenticated flag to true
          this._authenticated = true;
        }

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): void {
    // TODO: Logout from API first and if successfull then proceed
    // Remove the access token and user from the local storage and observable
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.clear();
    // Set the authenticated flag to false
    this._authenticated = false;
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    console.log('check mthod')
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it
    return of(true);
    // return this.signInUsingToken();
  }
}
