import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { GeneralImportModuleModule } from 'src/app/shared/general-import-module/general-import-module.module';
import { UtilsFunctionSharedService } from 'src/app/shared/service/utils-function-shared.service';
import { SharedServiceService } from '../shared-service.service';

@Component({
  selector: 'vex-spinners-interceptors',
  standalone: true,
  imports: [GeneralImportModuleModule],
  templateUrl: './spinners-interceptors.component.html',
  styleUrls: ['./spinners-interceptors.component.scss']
})
export class SpinnersInterceptorsComponent implements OnInit ,HttpInterceptor {
  showPortfolio: boolean=false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private utilsFonction: UtilsFunctionSharedService,
    private spinner: NgxSpinnerService,
    public sharedService: SharedServiceService

  ) {
    
  }
  showSpinner(): void {
    this.sharedService.showSpinner();
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.showPortfolio=true;
      this.spinner.hide();
    }, 5000);
  }

  hideSpinner(): void {
    this.sharedService.showSpinner();
    this.spinner.show();
 
    this.showPortfolio=false;

    this.sharedService.hideSpinner();
  }
  ngOnInit(): void {
   
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Afficher un message en console pour chaque requête HTTP
    console.log('Requête vvvinterceptée:', req);
    // Passez la requête au gestionnaire suivant sans la modifier
    return next.handle(req);
  }
 
 /* intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Afficher le spinner
    this.spinner.show();

    // Intercepter la requête et masquer le spinner après réception de la réponse ou en cas d'erreur
    return next.handle(req).pipe(
      finalize(() => {
        // Masquer le spinner
        this.spinner.hide();
      })
    );
  }*/




}
