import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { stagger80ms } from '@vex/animations/stagger.animation';
import { take } from 'rxjs';
import { Dossier } from 'src/app/model/dossier.model';
import { FileUpload } from 'src/app/model/fileUpload.model';
import { EnumParam, ParametreValeur } from 'src/app/model/parametreValeur.model';
import { UserEntity } from 'src/app/model/user.entity';
import { DossierService } from 'src/app/services/dossier.service';
import { ParametreValeurService } from 'src/app/services/parametreValeur.service';
import { PieceJointService } from 'src/app/services/pieceJoint.service';
import { UserService } from 'src/app/services/user.service';
import { GeneralImportModuleModule } from 'src/app/shared/general-import-module/general-import-module.module';

@Component({
  selector: 'vex-historique-traitement',
  templateUrl: './historique-traitement.component.html',
  styleUrls: ['./historique-traitement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
  standalone: true,
  imports: [
    GeneralImportModuleModule
  ]
})
export class HistoriqueTraitementComponent implements OnInit {

  @Input() body?: any | undefined;

  historiquesList: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any | undefined,
    private _dossierservice: DossierService,
    private _changRef: ChangeDetectorRef){
      this.body = defaults!;
      _changRef.markForCheck();
    }

  ngOnInit(){
    this._dossierservice.getHistoriqueTraitementActe(this.body!.dossierId!, this.body!.acteCode!).subscribe(
      hst => {
        console.log("Historique : ", hst)
        this.historiquesList = hst;
        this._changRef.markForCheck();
      }
    )

    this._changRef.detectChanges();
  }

  getNom(traitant: any){
    return traitant!.nom! +' '+ traitant!.prenom!
  }
}
