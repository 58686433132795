
export class Transitaire {
  public id?: number;
  public numeroAgrement?: string;
  public nom?: string;
  public adresse?: string;
  public telephone?: string;

  constructor(customer: Transitaire) {
    this.id = customer.id;
    this.numeroAgrement = customer.numeroAgrement;
    this.nom = customer.nom;
    this.adresse = customer.adresse;
    this.telephone =  customer.telephone;

  }
}




