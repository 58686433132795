import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, Observable, of, ReplaySubject, switchMap, take,} from 'rxjs';
import {API_ROOT} from 'src/environments/environment';
import {ResponseModel} from '../model/response.model';
import {AuthService} from '../core/auth/auth.service';
import {EnumParam, ParametreValeur} from '../model/parametreValeur.model';
import {Pays} from '../model/pays.model';
import {Assureur} from '../model/assureur.model';
import {TypePiece} from '../model/typePiece.model';

@Injectable({
  providedIn: 'root',
})
export class ParametreValeurService {
  public _parametreValeur: ReplaySubject<ParametreValeur> = new ReplaySubject<ParametreValeur>(1);
  public _parametreValeurs: ReplaySubject<ParametreValeur[]> = new ReplaySubject();
  public _parametreValeurs_NATURE_OPERATION: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_REGLEMENT_FINANCIER: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_MODE_TRANSPORT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_MODE_EXPEDITION: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_CENTRE_RETRAIT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_BUREAU_DEDOUANEMENT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_ASSURANCE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_POLICE_ABONNEMENT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_MODE_REGLEMENT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_PAYS: ReplaySubject<Pays[] | null> = new ReplaySubject();
  public _parametreValeurs_ADRESSE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_INTERVENANT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_UNITE_MESURE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_OPTION_VETERINAIRE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_DOCUMENT: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_FACTURE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();
  public _parametreValeurs_TYPE_CONFORMITE: ReplaySubject<ParametreValeur[] | null> = new ReplaySubject();

  private _url = API_ROOT.param_list_param;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for parametreValeur management
   *
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  get parametreValeur$(): Observable<ParametreValeur> {
    return this._parametreValeur.asObservable();
  }

  set parametreValeur(value: ParametreValeur) {
    // Store the value
    this._parametreValeur.next(value);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Get parametreValeurs
     */
  getParametreValeurs(param: EnumParam): Observable<any> {
    return this._httpClient
      .post<ResponseModel<ParametreValeur[]>>(`${this._url}`,{'param':param}, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const parametreValeurs: ParametreValeur[] = response?.data || [];
          this._parametreValeurs.next(parametreValeurs);
          return of(parametreValeurs);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
     * Get parametreValeurs
     */
  getParametreValeurs2(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<ParametreValeur[]>>(`${this._url}`)
      .pipe(
        switchMap((response) => {
          const parametreValeurs: ParametreValeur[] = response?.data || [];
          this._parametreValeurs.next(parametreValeurs);
          // this._parametreValeurs_TYPE_INTERVENANT.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_INTERVENANT));
          this._parametreValeurs_ADRESSE.next(parametreValeurs.filter(p => p.params == EnumParam.ADRESSE));
          this._parametreValeurs_PAYS.next(parametreValeurs.filter(p => p.params == EnumParam.PAYS));
          this._parametreValeurs_MODE_REGLEMENT.next(parametreValeurs.filter(p => p.params == EnumParam.MODE_REGLEMENT));
          this._parametreValeurs_TYPE_POLICE_ABONNEMENT.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_POLICE_ABONNEMENT));
          this._parametreValeurs_TYPE_ASSURANCE.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_ASSURANCE));
          this._parametreValeurs_BUREAU_DEDOUANEMENT.next(parametreValeurs.filter(p => p.params == EnumParam.BUREAU_DEDOUANEMENT));
          this._parametreValeurs_CENTRE_RETRAIT.next(parametreValeurs.filter(p => p.params == EnumParam.CENTRE_RETRAIT));
          this._parametreValeurs_MODE_EXPEDITION.next(parametreValeurs.filter(p => p.params == EnumParam.MODE_EXPEDITION));
          this._parametreValeurs_MODE_TRANSPORT.next(parametreValeurs.filter(p => p.params == EnumParam.MODE_TRANSPORT));
          this._parametreValeurs_REGLEMENT_FINANCIER.next(parametreValeurs.filter(p => p.params == EnumParam.REGLEMENT_FINANCIER));
          this._parametreValeurs_NATURE_OPERATION.next(parametreValeurs.filter(p => p.params == EnumParam.NATURE_OPERATION));
          this._parametreValeurs_UNITE_MESURE.next(parametreValeurs.filter(p => p.params == EnumParam.UNITE_MESURE));
          this._parametreValeurs_OPTION_VETERINAIRE.next(parametreValeurs.filter(p => p.params == EnumParam.OPTION_VETERINAIRE));
          this._parametreValeurs_TYPE_DOCUMENT.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_DOCUMENT));
          this._parametreValeurs_TYPE_FACTURE.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_FACTURE));
          this._parametreValeurs_TYPE_CONFORMITE.next(parametreValeurs.filter(p => p.params == EnumParam.TYPE_CONFORMITE));

          return of(parametreValeurs);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }


  getParametrePole(param: EnumParam, structure: number): Observable<any> {
    return this._httpClient
      .post<ResponseModel<ParametreValeur[]>>(`${API_ROOT.param_list_pole}`,{'param':param, 'structure': structure}, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const parametreValeurs: ParametreValeur[] = response?.data || [];
          this._parametreValeurs.next(parametreValeurs);

          return of(parametreValeurs);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }


  getParametrePays(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Pays[]>>(`${API_ROOT.pays_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const pays: Pays[] = response?.data || [];
          return of(pays);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getParametreAssureur(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Assureur[]>>(`${API_ROOT.assureur_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const pays: Assureur[] = response?.data || [];
          return of(pays);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getParametreTypePieces(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<TypePiece[]>>(`${API_ROOT.typePiece_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const typePiece: TypePiece[] = response?.data || [];
          return of(typePiece);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getParametreTypeClient(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<any[]>>(`${API_ROOT.typeClient_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const typeClient = response?.data || [];
          return of(typeClient);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getParamValeurCreateEndpoint(param : EnumParam) :string{
    switch (param) {
      case EnumParam.NATURE_OPERATION:
        return `${API_ROOT.natureOperationCreate}`;
      case EnumParam.REGLEMENT_FINANCIER:
        return `${API_ROOT.reglementFinancierCreate}`;
      case EnumParam.MODE_TRANSPORT:
        return `${API_ROOT.modeTransportCreate}`;
      case EnumParam.MODE_EXPEDITION:
        return `${API_ROOT.modeExpeditionCreate}`;
      case EnumParam.CENTRE_RETRAIT:
        return `${API_ROOT.centreRetraitCreate}`;
      case EnumParam.BUREAU_DEDOUANEMENT:
        return `${API_ROOT.bureauDedouanementCreate}`;
      case EnumParam.TYPE_ASSURANCE:
        return `${API_ROOT.typeAssuranceCreate}`;
      case EnumParam.TYPE_POLICE_ABONNEMENT:
        return `${API_ROOT.typePoliceAbonnementCreate}`;
      case EnumParam.MODE_REGLEMENT:
        return `${API_ROOT.modeReglementCreate}`;
      case EnumParam.PAYS:
        return `${API_ROOT.paysCreate}`;
      case EnumParam.ADRESSE:
        return `${API_ROOT.adresseCreate}`;
      case EnumParam.UNITE_MESURE:
        return `${API_ROOT.uniteMesureCreate}`;
      case EnumParam.OPTION_VETERINAIRE:
        return `${API_ROOT.optionVeterinaireCreate}`;
      case EnumParam.TYPE_DOCUMENT:
        return `${API_ROOT.typeDocumentCreate}`;
      case EnumParam.TYPE_FACTURE:
        return `${API_ROOT.typeFactureCreate}`;
      case EnumParam.TYPE_CONFORMITE:
        return `${API_ROOT.typeConformiteCreate}`;
      default:
        throw new Error("Endpoint not found");
    }
  }

  getParamValeurUpdateEndpoint(param : EnumParam) :string{
    switch (param) {
      case EnumParam.NATURE_OPERATION:
        return `${API_ROOT.natureOperationUpdate}`;
      case EnumParam.REGLEMENT_FINANCIER:
        return `${API_ROOT.reglementFinancierUpdate}`;
      case EnumParam.MODE_TRANSPORT:
        return `${API_ROOT.modeTransportUpdate}`;
      case EnumParam.MODE_EXPEDITION:
        return `${API_ROOT.modeExpeditionUpdate}`;
      case EnumParam.CENTRE_RETRAIT:
        return `${API_ROOT.centreRetraitUpdate}`;
      case EnumParam.BUREAU_DEDOUANEMENT:
        return `${API_ROOT.bureauDedouanementUpdate}`;
      case EnumParam.TYPE_ASSURANCE:
        return `${API_ROOT.typeAssuranceUpdate}`;
      case EnumParam.TYPE_POLICE_ABONNEMENT:
        return `${API_ROOT.typePoliceAbonnementUpdate}`;
      case EnumParam.MODE_REGLEMENT:
        return `${API_ROOT.modeReglementUpdate}`;
      case EnumParam.PAYS:
        return `${API_ROOT.paysUpdate}`;
      case EnumParam.ADRESSE:
        return `${API_ROOT.adresseUpdate}`;
      case EnumParam.UNITE_MESURE:
        return `${API_ROOT.uniteMesureUpdate}`;
      case EnumParam.OPTION_VETERINAIRE:
        return `${API_ROOT.optionVeterinaireUpdate}`;
      case EnumParam.TYPE_DOCUMENT:
        return `${API_ROOT.typeDocumentUpdate}`;
      case EnumParam.TYPE_FACTURE:
        return `${API_ROOT.typeFactureUpdate}`;
      case EnumParam.TYPE_CONFORMITE:
        return `${API_ROOT.typeConformiteUpdate}`;
      default:
        throw new Error("Endpoint not found");
    }
  }


  /**
     * Create parametreValeur
     */
  create(parametreValeur: any , paramType:EnumParam): Observable<any> {
    const endpoint = this.getParamValeurCreateEndpoint(paramType);
    return this._parametreValeurs.pipe(
      take(1),
      switchMap(parametreValeurs => this._httpClient.post<ResponseModel<ParametreValeur>>(`${endpoint}`, parametreValeur).pipe(
        switchMap((response) => {
          if (response.success) {
            const newParametreValeur: ParametreValeur = new ParametreValeur(response.data!);
            // Update the parametreValeurs with the new parametreValeur
            this._parametreValeurs.next([newParametreValeur, ...parametreValeurs!]);
            this._parametreValeur.next(newParametreValeur);

            // Return the new parametreValeur
            return of(newParametreValeur);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

  /**
    * Create parametreValeur
    */
  update(parametreValeur: any, paramType:EnumParam): Observable<any> {
    const endpoint = this.getParamValeurUpdateEndpoint(paramType);

    return this._parametreValeurs.pipe(
      take(1),
      switchMap(parametreValeurs => this._httpClient.post<ResponseModel<ParametreValeur>>(`${endpoint}`, parametreValeur).pipe(
        switchMap((response) => {
          if (response.success) {
            const newParametreValeur: ParametreValeur = new ParametreValeur(response.data!);

            const index = parametreValeurs?.findIndex(p => p.id == newParametreValeur.id)!;

            parametreValeurs[index] = newParametreValeur;

            // Update the parametreValeurs with the new parametreValeur
            this._parametreValeurs.next([newParametreValeur, ...parametreValeurs!]);
            this._parametreValeur.next(newParametreValeur);

            // Return the new parametreValeur
            return of(newParametreValeur);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

}
