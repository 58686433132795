<form [formGroup]="codeProduitFormGroup">
  <div class="flex items-center" mat-dialog-title>
    <img src="assets/img/avatars/doc.png" class="avatar mr-5" />

    <!-- <h2 class="headline m-0 flex-auto">
      {{ "Code Sylvie : " + form.controls.numero_sylvie.value }}
    </h2> -->
    <h2 class="headline m-0 flex-auto">
      Rechercher un produit
    </h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">
  <div @fadeInUp class="overflow-auto">
      <ng-template matStepLabel>Produits</ng-template>
      <h2 class="title m-0">Identification des produits</h2>
      <div class="subheading-1 text-center mx-4">
        Veuillez saisir minimume quatre (4) caractère du code tarif ou du nom scientique
      </div>

      <div class="px-6 py-4 flex flex-col">
        <div class="flex flex-col sm:flex-row sm:gap-4">
          <mat-form-field class="flex-auto">
            <mat-label>Code tarif du produit</mat-label>
            <input [formControl]="codeProduitFormGroup.controls.code" matInput
              placeholder="Code produit" />
              <mat-icon matSuffix svgIcon="mat:search" class="cursor-pointer" (click)="onSearchByCode()"></mat-icon>
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>Nom scientifiaue du produit</mat-label>
            <input [formControl]="codeProduitFormGroup.controls.libelle" matInput
              placeholder="Nom scientifiaue" />
              <mat-icon matSuffix svgIcon="mat:search" class="cursor-pointer" (click)="onSearchByLabelle()"></mat-icon>
          </mat-form-field>
        </div>

        <button [disabled]="codeProduitFormGroup.invalid" (click)="onSearchByCodeOrLabelle()"
          mat-raised-button color="primary">Rechercher</button>
      </div>

      <div class="invoice">
        <div class="title">
          <h2>Listes des produits</h2>
        </div>
        <table class="table-4col w-full mt-4">
          <thead>
            <tr>
              <td class="body-2 text-secondary">CODE TARIF</td>
              <td class="body-2 text-secondary">PARENT</td>
              <td class="body-2 text-secondary">NOM SCIENTIFIAUE</td>
              <td class="body-2 text-secondary text-right">OPTIONS</td>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let produit of produitsList">
              <td class="py-6 border-b">{{ produit.code }}</td>
              <td class="py-6 border-b">{{ produit.parent }}</td>
              <td class="py-6 border-b">{{ produit.libelle }}</td>
              <td class="border-b font-medium text-right">
                <button  color="primary" mat-fab extended type="button" (click)="onSelectProduit(produit)">
                  <mat-icon matSuffix svgIcon="mat:select_all"></mat-icon> Selectionner
                  </button>
              </td>
            </tr>

          </tbody>
        </table>

      </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="primary" mat-button type="button">
    Précédent
  </button>
  <button mat-button mat-dialog-close type="button">Fermer</button>
</mat-dialog-actions>
</form>
