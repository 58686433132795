import { RoleEntity } from './role.entity';
import { StructurePartenaire } from './structurePartenaire.model';

export class UserEntity {
  public id?: number;
  public nom?: string;
  public prenom?: string;
  public email?: string;
  public mdp?: string;
  public role?: RoleEntity;
  public structure?: StructurePartenaire;
  // public actif?: boolean = true;
  // public enabled?: boolean = true;
  // public password?: string;
  // public username?: string;

  constructor(customer: UserEntity) {
    this.id = customer.id;
    this.nom = customer.nom;
    this.prenom = customer.prenom;
    this.email = customer.email;
    this.mdp = customer.mdp;
    this.structure = customer.structure;
    this.role = customer.role;

  }

  // get name() {
  //   let name = '';

  //   if (this.nom && this.prenom) {
  //     name = this.nom + ' ' + this.prenom;
  //   } else if (this.nom) {
  //     name = this.nom;
  //   } else if (this.prenom) {
  //     name = this.prenom;
  //   }

  //   return name;
  // }

}

