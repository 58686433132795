import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { Transitaire } from '../model/transitaire.model';

@Injectable({
  providedIn: 'root'
})
export class TransitaireService {

  public _transitaire: ReplaySubject<Transitaire> = new ReplaySubject<Transitaire>(1);
  public _transitaires: ReplaySubject<Transitaire[]> = new ReplaySubject();

  private _url = API_ROOT.transitaires_list;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for transitaire
   */
  get transitaire$(): Observable<Transitaire> {
    return this._transitaire.asObservable();
  }

  set transitaire(transitaire: Transitaire) {
    this._transitaire.next(transitaire);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  get(): Observable<Transitaire> {
    return of(JSON.parse(localStorage.getItem('transitaire')!));
  }

  /**
     * Get users
     */
  getTransitaires(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<Transitaire[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const transitaires: Transitaire[] = response?.data || [];
          this._transitaires.next(transitaires);
          return of(transitaires);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

   /**
     * Create transitaire
     */
   create(transitaire: any): Observable<any> {

    return this._transitaires.pipe(
      take(1),
      switchMap(transitaires => this._httpClient.post<ResponseModel<Transitaire>>(`${API_ROOT.transitaire_create}`, transitaire).pipe(
        switchMap((response) => {
          if (response.success) {
            const newTransitaire: Transitaire = new Transitaire(response.data!);
            // Update the transitaires with the new transitaire
            this._transitaires.next([newTransitaire, ...transitaires!]);
            this._transitaire.next(newTransitaire);

            // Return the new transitaire
            return of(newTransitaire);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

   /**
     * Create transitaire
     */
   update(transitaire: any): Observable<any> {

    return this._transitaires.pipe(
      take(1),
      switchMap(transitaires => this._httpClient.post<ResponseModel<Transitaire>>(`${API_ROOT.transitaire_update}`, transitaire).pipe(
        switchMap((response) => {
          if (response.success) {
            const newTransitaire: Transitaire = new Transitaire(response.data!);

            const index = transitaires?.findIndex(p => p.id == newTransitaire.id)!;

            transitaires[index] = newTransitaire;

            // Update the transitaires with the new transitaire
            this._transitaires.next([newTransitaire, ...transitaires!]);
            this._transitaire.next(newTransitaire);

            // Return the new transitaire
            return of(newTransitaire);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

}
