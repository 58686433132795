
export class ParametreValeur {
  public id?: number;
  public libelle?: string;
  public params?: string;

  constructor(customer: ParametreValeur) {
    this.id = customer.id;
    this.libelle = customer.libelle;
    this.params = customer.params;

  }
}

export enum EnumParam {
  NATURE_OPERATION = "NATURE_OPERATION",
  REGLEMENT_FINANCIER = "MODE_REGLEMENT",
  MODE_TRANSPORT = "MODE_TRANSPORT",
  MODE_EXPEDITION = "MODE_EXPEDITION",
  CENTRE_RETRAIT = "CENTRE_RETRAIT",
  BUREAU_DEDOUANEMENT = "BUREAU_DEDOUANEMENT",
  TYPE_ASSURANCE = "TYPE_ASSURANCE",
  TYPE_POLICE_ABONNEMENT = "TYPE_POLICE_ABONNEMENT",
  MODE_REGLEMENT = "MODE_REGLEMENT",
  PAYS = "PAYS",
  ADRESSE = "ADRESSE",
  UNITE_MESURE = "UNITE_MESURE",
  OPTION_VETERINAIRE = "OPTION_VETERINAIRE",
  TYPE_DOCUMENT = "DOSSIER_DOCUMENT_TYPE",
  TYPE_FACTURE = "TYPE_FACTURE",
  // TYPE_INTERVENANT = "TYPE_INTERVENANT",
  TYPE_CONFORMITE = "TYPE_CONFORMITE"
}
