<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <h2
      class="headline m-0 flex-auto">
      Nouvlle structure
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Raison sociale</mat-label>
        <input cdkFocusInitial formControlName="raisonSociale" matInput />
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput />
      </mat-form-field>
    </div>
    <mat-form-field class="flex-auto">
      <mat-label>Téléphone</mat-label>
      <input formControlName="telephone" matInput />
    </mat-form-field>
    <mat-form-field class="flex-auto">
      <mat-label>Type Structure</mat-label>
      <mat-select [formControl]="form.controls.typeStructure"
        placeholder="Selectionner&hellip;">
        <mat-option value="">Selectionner...</mat-option>
        <mat-option *ngFor="let typ of typeStructureList" [value]="typ.id">
          {{typ.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button
      *ngIf="isCreateMode()"
      color="primary"
      mat-flat-button
      type="submit">
      Enregistrer
    </button>
    <button
      *ngIf="isUpdateMode()"
      color="primary"
      mat-flat-button
      type="submit">
      Modifier
    </button>
  </mat-dialog-actions>
</form>
