import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { StructurePartenaire, TypeStructure } from '../model/structurePartenaire.model';

@Injectable({
  providedIn: 'root'
})
export class StructurePartenaireService {

  // Private
  public _structure: ReplaySubject<StructurePartenaire> = new ReplaySubject<StructurePartenaire>(1);
  public _structures: ReplaySubject<StructurePartenaire[]> = new ReplaySubject();
  public _typeStructure: ReplaySubject<TypeStructure[]> = new ReplaySubject();

  private _url = API_ROOT.structures;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for structure
   */
  get structure$(): Observable<StructurePartenaire> {
    return this._structure.asObservable();
  }

  set structure(structure: StructurePartenaire) {
    this._structure.next(structure);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get Structures
   */
  getStructures(): Observable<any[]> {
    return this._httpClient
      .get<ResponseModel<StructurePartenaire[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const structures: StructurePartenaire[] = response?.data || [];
          this._structures.next(structures);
          return of(structures);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }


  getTypeStructures(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<StructurePartenaire[]>>(`${API_ROOT.typeStructure_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const typeStructures: TypeStructure[] = response?.data || [];
          this._typeStructure.next(typeStructures);
          return of(typeStructures);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

   /**
     * Create structure
     */
   create(structure: any): Observable<any> {

    return this._structures.pipe(
      take(1),
      switchMap(structures => this._httpClient.post<ResponseModel<StructurePartenaire>>(`${API_ROOT.structure_create}`, structure).pipe(
        switchMap((response) => {
          if (response.success) {
            const newStructure: StructurePartenaire = new StructurePartenaire(response.data!);
            // Update the structures with the new structure

           // En supposant que newStructure est un StructurePartenaire
           this._structures.next([newStructure, ...structures!]);
            this._structure.next(newStructure);

            // Return the new structure
            return of(newStructure);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }
  update(structure: any): Observable<any> {

    return this._structures.pipe(
      take(1),
      switchMap(structures=> this._httpClient.post<ResponseModel<StructurePartenaire>>(`${API_ROOT.structure_create}`, structure).pipe(
        switchMap((response) => {
          if (response.success) {
            const newStructure: StructurePartenaire = new StructurePartenaire(response.data!);

            const index = structures?.findIndex(p => p.id == newStructure.id)!;

            structures[index] = newStructure;

            // Update the transitaires with the new transitaire
            this._structures.next([newStructure, ...structures!]);
            this._structure.next(newStructure);

            // Return the new transitaire
            return of(newStructure);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

}
