import { Component, Input, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, NgIf } from '@angular/common';
import { UserEntity } from 'src/app/model/user.entity';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { StructurePartenaire } from 'src/app/model/structurePartenaire.model';
import { RoleEntity } from 'src/app/model/role.entity';
import { Observable, ReplaySubject, isEmpty } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';
import { StructurePartenaireService } from 'src/app/services/structure-partenaire.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { EventEmitter } from 'stream';

// export let data.idCounter = 50;
@Component({
  selector: 'vex-utilisateur-edit',
  templateUrl: './utilisateur-edit.component.html',
  styleUrls: ['./utilisateur-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatSnackBarModule,

  ]
})
export class UtilisateurEditComponent implements OnInit {

  subject$: ReplaySubject<UserEntity[]> = new ReplaySubject<UserEntity[]>(1);
  rolesubject$: ReplaySubject<RoleEntity[]> = new ReplaySubject<RoleEntity[]>(1);
  structuresubject$: ReplaySubject<StructurePartenaire[]> = new ReplaySubject<StructurePartenaire[]>(1);

  data$: Observable<UserEntity[]> = this.subject$.asObservable();
  roles$: Observable<RoleEntity[]> = this.rolesubject$.asObservable();
  structures$: Observable<StructurePartenaire[]> = this.structuresubject$.asObservable();

  users: UserEntity[] = [];
  roles: RoleEntity[] = [];
  structures: StructurePartenaire[] = [];

  form = this.fb.group({
    id: [0],
    nom: ['', Validators.required],
    prenom: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    mdp: ['', [Validators.required, Validators.minLength(8)]],
    structure: [0, Validators.required],
    role: [0, Validators.required]
  });

  user?: UserEntity;

  get isEdit(): boolean {
    return !!this.data?.id!;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UtilisateurEditComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private _userService: UserService,
    private _roleService: RoleService,
    private _structureService: StructurePartenaireService) {

    this.subject$.next(data.list!);

    this.getRoles().subscribe((roles) => {
      this.rolesubject$.next(roles);
    });

    this.getStructures().subscribe((structures) => {
      console.log(structures)
      this.structuresubject$.next(structures);
    });

    this.data$.subscribe((users) => {
      this.users = users;
      if (this.data.id != null) {
        console.log(this.users)
        const user = this.users.find((c) => c.id === this.data.id);

        console.log(user);

        if (!user) {
          throw new Error('Utilisateurs invalide');
        }

        this.user = new UserEntity(user);
        this.initForm(user);
      }
    });

    this.roles$.subscribe((roles) => {
      this.roles = roles;
    });

    this.structures$.subscribe((structures) => {
      this.structures = structures;
    });


  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getRoles() {
    return this._roleService.getRoles();
  }

  getStructures() {
    return this._structureService.getStructures();
  }

  ngOnInit() {

  }

  initForm(user: UserEntity) {

    console.log(user);

    this.form.value['id'] = user.id;
    this.form.value['nom'] = user.nom;
    this.form.value['prenom'] = user.prenom;
    this.form.value['email'] = user.email;
    this.form.value['role'] = user.role?.id;
    this.form.value['structure'] = user.structure?.id;

    const val = this.form.value;
    this.form.patchValue(val);

    console.log(this.form.value);
    if (this.isEdit) {
      this.form.controls.email.disable();
    }

  }

  save() {
    const form = this.form.getRawValue();

    const savePromise: Promise<any> = new Promise((resolve, reject) => {
      if (this.isEdit) {
        this._userService.updateUserEntity(form).subscribe(
          responce => {
            resolve(responce);
          }
        )
      } else {
        this._userService.create(form).subscribe(
          responce => {
            resolve(responce);
          }
        )
      }
    });

    savePromise.then(values => {
      this.snackbar.open(
        values.message,
        'OK',
        {
          verticalPosition: 'bottom',
          duration: 10000
        },
      );
      this.dialogRef.close(values.data);
    })

  }

}
