import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, map, Observable, of, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';
import { ResponseModel } from '../model/response.model';
import { API_ROOT } from 'src/environments/environment';
import { CodeProduit } from '../model/codeProduit.model';

@Injectable({
  providedIn: 'root'
})
export class CodeProduitService {

  // Private
  private _codeProduit: ReplaySubject<CodeProduit> = new ReplaySubject<CodeProduit>(1);
  private _codeProduits: ReplaySubject<CodeProduit[] | null> = new ReplaySubject();

  private _url = API_ROOT.code_tarif_produit_list;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter & Setter for codeProduit
   */
  get codeProduit$(): Observable<CodeProduit> {
    return this._codeProduit.asObservable();
  }

  set codeProduit(codeProduit: CodeProduit) {
    this._codeProduit.next(codeProduit);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getSearche(code: string, libelle: string): Observable<any> {
    return this._httpClient
      .get<ResponseModel<CodeProduit[]>>(`${API_ROOT.codeProduit_find_by_code_or_libelle}?code=${code}&libelle=${libelle}`)
      .pipe(

        switchMap((response) => {
          const codeProduits: CodeProduit[] = response?.data || [];
          this._codeProduits.next(codeProduits);
          return of(codeProduits);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getCodeTarifProduit(key: number): Observable<any> {
    return this._httpClient
      .post<ResponseModel<CodeProduit>>(`${API_ROOT.codeProduit_read}`, {"id":key} )
      .pipe(
        switchMap((response : any) => {
          const codeProduit = response?.data || [];
          return of(codeProduit);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  getSearcheByKey(key: string): Observable<any> {
    return this._httpClient
      .get<ResponseModel<CodeProduit[]>>(`${API_ROOT.codeProduit_search}?keyword=${key}`)
      .pipe(

        switchMap((response) => {
          const codeProduits: CodeProduit[] = response?.data || [];
          this._codeProduits.next(codeProduits);
          return of(codeProduits);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
     * Get Code Produits
     */
  getCodeProduits(): Observable<any> {
    return this._httpClient
      .get<ResponseModel<CodeProduit[]>>(`${API_ROOT.codeProduit_list}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const codeProduits: CodeProduit[] = response?.data || [];
          this._codeProduits.next(codeProduits);
          return of(codeProduits);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

}
