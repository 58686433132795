import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  catchError,
  of,
  switchMap,
  take
} from 'rxjs';
import { API_ROOT } from 'src/environments/environment';
import { AuthService } from '../core/auth/auth.service';
import { ResponseModel } from '../model/response.model';
import { UserEntity } from '../model/user.entity';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<UserEntity> = new ReplaySubject<UserEntity>(1);
  private _userConnected: ReplaySubject<UserEntity> = new ReplaySubject<UserEntity>(1);
  private _users: ReplaySubject<UserEntity[]> = new ReplaySubject();

  private _url = API_ROOT.users;
  private _urlAttribution = API_ROOT.utilisateur_list_attribution;

  private headers = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user management
   *
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  get user$(): Observable<UserEntity> {
    return this._user.asObservable();
  }

  set user(value: UserEntity) {
    // Store the value
    this._user.next(value);
  }

  get userConnected$(): Observable<UserEntity> {
    return this._userConnected.asObservable();
  }

  set userConnected(value: UserEntity) {
    // Store the value
    this._userConnected.next(this._authService.user);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Get the current logged-in user data
   */
  get(): Observable<UserEntity> {
    return of(JSON.parse(localStorage.getItem('user')!));
  }

  /**
     * Get users
     */
  getUsers(): Observable<any[]> {
    return this._httpClient
      .get<ResponseModel<UserEntity[]>>(`${this._url}`, { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const users = response?.data || [];
          this._users.next(users);
          return of(users);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

    /**
     * Get users
     */
    getUsersAttribution(): Observable<any[]> {
      return this._httpClient
        .get<ResponseModel<UserEntity[]>>(`${this._urlAttribution}`, { 'headers': this.headers })
        .pipe(
          switchMap((response) => {
            const users = response?.data || [];
            this._users.next(users);
            return of(users);
          }),
          catchError((err) => {
            console.log(err);
            return of([]);
          })
        );
    }

  /**
   * Get Users By Structure
   */
  getUsersByStructure(id: number): Observable<any[]> {
    return this._httpClient
      .post<ResponseModel<UserEntity[]>>(API_ROOT.utilisateur_list_structure,
        {
          "id": id
        },
        { 'headers': this.headers })
      .pipe(
        switchMap((response) => {
          const users = response?.data || [];
          this._users.next(users);
          return of(users);
        }),
        catchError((err) => {
          console.log(err);
          return of([]);
        })
      );
  }

  /**
     * Create user
     */
  create(user: any): Observable<any> {

    return this._users.pipe(
      take(1),
      switchMap(users => this._httpClient.post<ResponseModel<UserEntity>>(`${API_ROOT.utilisateur_create}`, user).pipe(
        switchMap((response) => {
          if (response.success) {
            const newUser: UserEntity = new UserEntity(response.data!);
            // Update the users with the new user
            this._users.next([newUser, ...users!]);
            this._user.next(newUser);

            // Return the new user
            return of(response);
          } else {
            return of(null);
          }
        })
      )
      )
    );
  }

  updateUserEntity(user: any): Observable<any> {
    return this._httpClient.post<ResponseModel<UserEntity>>(`${API_ROOT.utilisateur_update}`, user).pipe(
            switchMap((response) => {
                if (response.success) {
                  this._user.next(response.data!);
                  // Return the updated user
                  return of(response);
                }else{
                  return of(null);

                }

            })
        );
  }

}
