<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    Info Numero IFU : {{ data.numeroIfu! }}
  </h2>

  <button class="text-secondary ml-3" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">

  <div [ngClass]="{
    'text-green-600 bg-green-600/10': etatNumeroDemandeur == 'ACTIF',
    'text-red-600 bg-red-600/10': etatNumeroDemandeur != 'ACTIF' }" class="flex-auto mb-4 p-2" >
    <p>
      NOM : {{ nomDemandeur }}
      <br>
      ADRESSE : {{ adresseDemandeur }}
      <br>
      RCCM : {{ rccmDemandeur }}
      <br>
      DIRECTION : {{ directionDemandeur }}
      <br>
      TELEPHONE : {{ telephoneDemandeur }}
      <br>
      EMAAIL : {{ telephoneDemandeur }}
      <br>
      REGIME FISCALE : {{ regimeFiscaleDemandeur }}
      <br>
      ETAT NUMERO  : {{ etatNumeroDemandeur }}
      <br>
      ASSUJETI TVA : {{ assujetiTvaDemandeur }}
      <br>
      SIEGE : {{ siegeDemandeur }}
      <br>
      ENSEIGNE COMMERCIAL : {{ enseigneCommercialDemandeur }}
      <br>
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="primary" mat-raised-button mat-dialog-close>
    ok
  </button>
  <!-- <button mat-button mat-dialog-close type="button">Fermer</button> -->
</mat-dialog-actions>
